<div class="dual-list">

    <div class="listbox">
        <p>{{sourceName}}</p>
        <form *ngIf="filter" class="filter">
            <input class="form-control" name="filterSource" [(ngModel)]="available.picker"
                (ngModelChange)="onFilter(available)">
        </form>

        <div class="record-picker">
            <div [ngStyle]="{'max-height': height, 'min-height': height}" [ngClass]="{over:available.dragOver}">
                <ul (drop)="drop($event, confirmed)" (dragover)="allowDrop($event, available)"
                    (dragleave)="dragLeave()">
                    <li *ngFor="let item of available.sift; let idx=index;"
                        (click)="selectItem(available.pick, item); shiftClick($event, idx, available, item)"
                        [ngClass]="{selected: isItemSelected(available.pick, item)}" draggable="true"
                        (dragstart)="drag($event, item, available)" (dragend)="dragEnd(available)">
                        <label>{{item._name}}</label>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="buttonbox mt-5  ml-2 mr-2">

        <button type="button" class="btn btn-primary btn-block" (click)="moveAll()"
            [disabled]="isAllSelected(available)"><i class="fa fa-arrow-right"></i><i
                class="fa fa-arrow-right"></i></button>

        <button type="button" class="btn btn-primary btn-block" (click)="moveItem(available, confirmed)"
            [disabled]="available.pick.length === 0"><i class="fa fa-arrow-right"></i></button>

        <button type="button" class="btn btn-primary btn-block" (click)="moveItem(confirmed, available)"
            [disabled]="confirmed.pick.length === 0"><i class="fa fa-arrow-left"></i></button>

        <button type="button" class="btn btn-primary btn-block  " (click)="moveUp(confirmed)"
            [disabled]="confirmed.pick.length === 0 || areContinuous(confirmed)"><i class="fa fa-arrow-up"></i></button>

        <button type="button" class="btn btn-primary btn-block" (click)="moveDown(confirmed)"
            [disabled]="confirmed.pick.length === 0 || areContinuous(confirmed)"><i
                class="fa fa-arrow-down"></i></button>

        <button type="button" class="btn btn-primary btn-block" (click)="removeAll()"
            [disabled]="isAllSelected(confirmed)"><i class="fa fa-arrow-left"></i><i
                class="fa fa-arrow-left"></i></button>
    </div>

    <div class="listbox">
        <p>{{targetName}}</p>
        <form *ngIf="filter" class="filter">
            <input class="form-control" name="filterDestination" [(ngModel)]="confirmed.picker"
                (ngModelChange)="onFilter(confirmed)">
        </form>
        <div class="record-picker">
            <div [ngStyle]="{'max-height': height, 'min-height': height}" [ngClass]="{over:confirmed.dragOver}">
                <ul (drop)="drop($event, available)" (dragover)="allowDrop($event, confirmed)"
                    (dragleave)="dragLeave()">
                    <li *ngFor="let item of confirmed.sift; let idx=index;"
                        (click)="selectItem(confirmed.pick, item); shiftClick($event, idx, confirmed, item)"
                        [ngClass]="{selected: isItemSelected(confirmed.pick, item)}" draggable="true"
                        (dragstart)="drag($event, item, confirmed)" (dragend)="dragEnd(confirmed)">
                        <label>{{item._name}}</label>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>