<spinner></spinner>

<div *ngIf="notAllowed">
  <app-error404></app-error404>
</div>
<div *ngIf="!notAllowed">

  <div class="content-heading">
    <div>
      <h3> {{'botAdmin.actions.title' | translate}} </h3>
      <h6 id="subtitle"> {{title | translate}} {{nameToEdit}} </h6>
    </div>
    <div class="ml-auto">
      <a class="btn btn-info col-sm-12 col-md-12 col-lg-12" (mouseup)="redirect($event)"> {{'botAdmin.generic.back' |
        translate}} </a>
    </div>
  </div>
  <!-- Nav Tabs-->
  <ul class="nav nav-tabs" id="entityTab" role="tablist">
    <li class="nav-item">
      <a [ngClass]="{'nav-link':true, 'active': (activeTab == 1)}" id="basicinfo-tab" data-toggle="tab"
        href="#basicinfo" role="tab" aria-controls="basicinfo" aria-selected="true" (click)="changeTabs(1)">
        {{'botAdmin.actionsabm.tab1' | translate}} </a>
    </li>
    <li class="nav-item">
      <a [ngClass]="{'nav-link':true, 'active': (activeTab == 2), 'disabled': (disTab)}" id="type-tab" data-toggle="tab"
        href="#type" role="tab" aria-controls="type" aria-selected="false" (click)="changeTabs(2); switchForm()">
        {{'botAdmin.actionsabm.tab2' | translate}} </a>
    </li>
    <li class="nav-item">
      <a [ngClass]="{'nav-link':true, 'active': (activeTab == 3), 'disabled': (disTab)}" id="actions-tab"
        data-toggle="tab" href="#actions" role="tab" aria-controls="actions" aria-selected="false"
        (click)="changeTabs(3)"> {{'botAdmin.actionsabm.tab3' | translate}} </a>
    </li>
    <li class="nav-item">
      <a [ngClass]="{'nav-link':true, 'active': (activeTab == 4), 'disabled': (disTab)}" id="intentions-tab"
        data-toggle="tab" href="#intentions" role="tab" aria-controls="intentions" aria-selected="false"
        (click)="changeTabs(4)"> {{'botAdmin.actionsabm.tab5' | translate}} </a>
    </li>
    <li class="nav-item">
      <a [ngClass]="{'nav-link':true, 'active': (activeTab == 5), 'disabled': (disTab)}" id="advanced-tab"
        data-toggle="tab" href="#advanced" role="tab" aria-controls="advanced" aria-selected="false"
        (click)="changeTabs(5)"> {{'botAdmin.processABM.title' | translate}} </a>
    </li>
    <li class="nav-item">
      <a [ngClass]="{'nav-link':true, 'active': (activeTab == 6), 'disabled': (disTab)}" id="advanced-tab"
        data-toggle="tab" href="#advanced" role="tab" aria-controls="advanced" aria-selected="false"
        (click)="changeTabs(6)"> {{'botAdmin.actionsabm.tab6' | translate}} </a>
    </li>
  </ul>
  <!-- Tabs div -->
  <div class="tab-content" id="myTabContent">
    <!-- Basic Info Tab -->

    <div class="tab-pane fade show active" id="basicinfo" role="tabpanel" aria-labelledby="basicinfo-tab"
      *ngIf="activeTab == 1">
      <div id="disabledDiv">
      <form [formGroup]="actionsCreateFormGroup">
        <div class="row">
          <div class="form-group col-md-4">
            <label> {{'botAdmin.actionsabm.form1' | translate}} </label>
            <input type="text" formControlName="title" maxlength="100" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.title.errors }" [class.disabled]='readonly'>
            <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
              <div *ngIf="f.title.errors.required"> {{'botAdmin.generic.required' | translate}} </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-1">
            <button type="submit" class="btn btn-info" (click)="create()" [class.disabled]='readonly'>{{nameCreate | translate}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
    <!-- Types Tab -->
    <div class="tab-pane fade show active" id="type" role="tabpanel" aria-labelledby="type-tab" *ngIf="activeTab == 2">

      <div class="control-panel">

        <form role="form" [formGroup]="actionsUpdateFormGroup">
          <!-- Form Type & Restrictions -->
          <div class="row">
            <div class="form-group col-md-8">
              <label> {{'botAdmin.actionsabm.form2' | translate}} </label>
              <md-editor [upload]="doUpload" [preRender]="preRenderFunc" [height]="'200px'" [mode]="mode"
                [options]="markDownEditorOptions" required formControlName="details" [class.disabled]='readonly'>
              </md-editor>
              <div *ngIf="submitted && fr.details.errors" class="invalid-feedback">
                <div *ngIf="fr.details.errors.required"> {{'botAdmin.generic.required' | translate}} </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-4">
              <label> {{'botAdmin.actionsabm.form3' | translate}} </label>

              <select class="form-control" formControlName="actionsType" (click)="switchForm()"
                [ngClass]="{ 'is-invalid': submitted && fr.actionsType.errors }" [class.disabled]='readonly'>
                <option value=""> {{'botAdmin.actionsabm.form3op' | translate}} </option>
                <option *ngFor="let t of types" value={{t}}>{{t}}</option>
              </select>
              <div *ngIf="submitted && fr.actionsType.errors" class="invalid-feedback">
              </div>
            </div>
          </div>
          <div *ngIf="habResultType" class="row">
            <div class="form-group col-md-4">
              <label for="exampleFormControlTextarea1"> {{'botAdmin.actionsabm.form4' | translate}} </label>
              <textarea class="form-control" rows="5" formControlName="actionsResult"
                [ngClass]="{ 'is-invalid': submitted && fr.actionsResult.errors }" ></textarea>
              <div *ngIf="submitted && fr.actionsResult.errors" class="invalid-feedback">
                <div *ngIf="fr.actionsResult.errors.required"> {{'botAdmin.generic.required' | translate}} </div>
                <div *ngIf="fr.actionsResult.errors.pattern"> {{'botAdmin.generic.invalidurl' | translate}} </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div *ngIf="habResultType" class="col-md-2">
              <button type="submit" class="btn btn-info" (click)="update()" [class.disabled]='readonly'> {{'botAdmin.generic.update' | translate}}
             </button>
            </div>
          </div>
        </form>
      </div>
      <!-- *************************************************************** -->
      <div *ngIf="!habResultType && isAnswer" class="control-panel">

        <div class="row">
          <div class=" col-md-2 col-lg-2 col-12 offset-lg-8 offset-md-8 mb-3">

            <button class="btn btn-labeled btn-info w-100" type="button" (click)="addItemToList()" [class.disabled]='readonly'>
              <span class="btn-label"><i class="fa fa-plus"></i>
              </span> Add </button>
            </div>

            <div class="col-md-2 col-lg-2 col-12 mb-3">

            <button type="submit" class="btn btn-info w-100" (click)="update()" id="updatebutton" [class.disabled]='readonly'>
              {{'botAdmin.generic.update' | translate}} </button>
            </div>

        </div>
        <div>

          <div class="row">
            <form [formGroup]="fatherFormGroup" class="col-xs-12 col-sm-12 col-md-12 col-lg-12" [class.disabled]='readonly'>
              <div formArrayName="arrayFormGroup">
                <label> {{'botAdmin.actionsabm.form5' | translate}} </label>
                <div *ngFor="let control of arrayFormGroup.controls; index as i">
                  <div [formGroup]="control">
                    <div class="row">
                      <div class="form-group col-xs-11 col-sm-11 col-md-6 col-lg-6">
                        <input type="text" class="form-control w-100" id="{{'Input-' + i}}"
                          [formControl]="arrayFormGroup.controls[i].controls.response" formControlName="response" (keyup.enter) = "addItemToList()" [class.disabled]='readonly'/>
                      </div>
                      <div class="row">
                        <div class="form-group col-md-1">
                          <button type="button" class="btn btn-info col-xs-12 col-sm-12 col-md-12 col-lg-12 pr-3 "
                            id="closebutton" (click)="removeItemToList(i)" [class.disabled]='readonly'> X </button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>


              <!-- <button type="submit" class="btn btn-dark" (click)="clear()" id="deleteallbutton"> Delete All </button> -->

            </form>
          </div>

        </div>
        <!-- *************************************************************** -->

      </div>
      <div *ngIf="!habResultType && !isAnswer" class="control-panel">
      <button type="submit" class="btn btn-info" (click)="update()" id="updatebutton" [class.disabled]='readonly'>
        {{'botAdmin.generic.update' | translate}} </button>
      </div>

    </div>
    <!-- Actions Tab -->
    <div class="tab-pane fade show active" id="actions" role="tabpanel" aria-labelledby="actions-tab"
      *ngIf="activeTab == 3">
      <div class="row">
        <div class="col-md-8">
          <div class="header-modal"> {{'botAdmin.actionsabm.assoact' | translate}} </div>
          <div class="p-2">


         <app-custom-listbox  [source]="actionsAssociate" [filter]="true" key="actionId" display="actionTitle"
         [(destination)]="actionsAssociateSelected"   height="300px" [class.disabled]='readonly'></app-custom-listbox>


       <!--      <app-custom-listbox [filter]="true" [source]="actionsAssociate" key="actionId" display="actionTitle"
              [(destination)]="actionsAssociateSelected"  [format]="format" height="120px" [class.disabled]='readonly'>
            </app-custom-listbox> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">
          <button type="submit" class="btn btn-info" (click)="updateDualActions()" [class.disabled]='readonly'> {{'botAdmin.generic.update' |
            translate}} </button>
        </div>
      </div>
    </div>


    <!-- Intentions Tab -->
    <div class="tab-pane fade show active" id="intentions" role="tabpanel" aria-labelledby="intentions-tab"
      *ngIf="activeTab == 4">
      <div class="row">
        <div class="col-md-8">
          <div class="header-modal"> {{'botAdmin.actionsabm.assoint' | translate}} </div>
          <div class="p-2">
            <app-custom-listbox  [filter]="true" [source]="intentions" key="id" display="name"
              [(destination)]="selectedIntentions" [format]="format" height="300px" [class.disabled]='readonly'>
            </app-custom-listbox>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">
          <button type="submit" class="btn btn-info" (click)="updateIntentions()" [class.disabled]='readonly'> {{'botAdmin.generic.update' |
            translate}} </button>
        </div>
      </div>
    </div>
    <!-- Process Tab-->
    <div class="tab-pane fade show active" id="intentions" role="tabpanel" aria-labelledby="intentions-tab"
    *ngIf="activeTab == 5">
    <div class="row">
      <div class="col-md-8">
        <div class="header-modal"> {{'botAdmin.processABM.title' | translate}} </div>
        <div class="p-2">
          <app-custom-listbox [filter]="true" [source]="ProcessAssociate" key="sapProcessid" display="name"
            [(destination)]="selectedProcess" [format]="format" height="300px" [class.disabled]='readonly'>
          </app-custom-listbox>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <button type="submit" class="btn btn-info" (click)="updateDualProcess()" [class.disabled]='readonly'> {{'botAdmin.generic.update' |
          translate}} </button>
      </div>
    </div>
  </div>


    <!-- Advanced Tab -->
    <div class="tab-pane fade show active" id="advanced" role="tabpanel" aria-labelledby="advanced-tab"
      *ngIf="activeTab == 6">
      <div class="row justify-content-md-start">
        <div class="form-group col-md-6">
          <div class="card">
            <h4 class="card-header"> {{'botAdmin.generic.changeStatus' | translate}} </h4>
            <hr id="hr1">
            <div class="card-body">
              <h4> {{'botAdmin.generic.statusAv' | translate}} {{statusAction}}</h4>
              <p class="card-text"> {{'botAdmin.actionsabm.changestatusp' | translate}} </p>
              <button type="submit" class="btn btn-success" [disabled]="statusAction" value="true"
                (click)="changeStatus()" id="enable" [class.disabled]='readonly'> {{'botAdmin.generic.enable' | translate}} </button>
              <button type="submit" class="btn btn-danger" [disabled]="!statusAction" value="false"
                (click)="changeStatus()" id="disable" [class.disabled]='readonly'> {{'botAdmin.generic.disable' | translate}} </button>
            </div>
          </div>
        </div>
        <div class="w-100"></div>
        <div class="form-group col-md-6">
          <div class="card">
            <h4 class="card-header"> {{'botAdmin.generic.danger' | translate}} </h4>
            <hr id="hr2">
            <div class="card-body">
              <h4> {{'botAdmin.actionsabm.deleteact' | translate}} </h4>
              <p class="card-text"> {{'botAdmin.actionsabm.deletep' | translate}} </p>
              <button type="submit" class="btn btn-info" (click)="checkActions()" [class.disabled]='readonly'> {{'botAdmin.actionsabm.deleteact' |
                translate}} </button>
              <p class="card-text" id="delete1" *ngIf="!showed">{{deleteFalse}}</p>
              <p class="card-text" id="delete2" *ngIf="showed">{{deleteTrue}}</p>
              <button type="submit" *ngIf="showed" class="btn btn-info" (click)="deleteActions()" [class.disabled]='readonly'>
                {{'botAdmin.generic.confirmdelete' | translate}} </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
