import { DataSource } from '@angular/cdk/table';
import { Action } from '../entities/action';
import { catchError, finalize } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { BotService } from '../services/botService';
import { PaginationFilterActions } from '../entities/paginationFilterActions';
import { sapProcesses } from '../entities/sapProcesses';
import { SapService } from '../services/sapService';
import { PaginationFilterSap } from '../entities/paginationFilterSap';

export class SapProcessDatasource implements DataSource<sapProcesses> {

    private actionSubject = new BehaviorSubject<any>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private sapService: SapService) {}

    connect(collectionViewer: CollectionViewer): Observable<sapProcesses[]> {
        return this.actionSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.actionSubject.complete();
        this.loadingSubject.complete();
    }

    loadProcess(paginationFilter: PaginationFilterSap) {
               this.loadingSubject.next(true);
        this.sapService.GetAllPaginationFilterSap(paginationFilter).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(entity => {
            this.actionSubject.next(entity)
        });
    }    
}