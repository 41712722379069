import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { secretValue } from '../entities/secretValue';

@Injectable()
export class AppConfigService {
    private appConfig;

    private apiServiceBot: string;
    private webChat: string;
    private urlIdentity: string;
    private urlPage: string;
    private clientId: string;
    private scope: string;

    get GetEnterpriseApi(){
        return this.apiServiceBot;
    }
    get GetWebChat(){
        return this.webChat;
    }
    get GetIdentityApi(){
        return this.urlIdentity;
    }
    get GetBackOffice(){
        return this.urlPage;
    }
    get GetClientId(){
        return this.clientId;
    }
    get GetScope(){
        return this.scope;
    }

    constructor (private http: HttpClient) {}

    loadAppConfig() {
        return this.http.get('/assets/app-config.json')
        .toPromise()
        .then(data => {
            this.appConfig = data;

            let jsonContent = {
                "resource_name": this.appConfig.KeyVaultName
            };
            let endpoint: string = this.appConfig.functionUri;
    
            return this.http.post<secretValue>(endpoint,
            jsonContent, {
                headers: new HttpHeaders({
                'Content-Type': 'application/json',
                "Accept": "application/json"
                })
            }).toPromise()
            .then(data => {
                this.apiServiceBot = data.urlServiceBot;
                this.webChat = data.tokenBot;
                this.urlIdentity = data.urlIdentity;
                this.urlPage = data.urlPage;
                this.clientId = data.clientId;
                this.scope = data.scope;
            });
        });
    }
}