import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from './AppConfigService';
import { UserAndRoles } from '../entities/userAndRoles';
import { IdentityRole } from '../entities/identityRole';
import { IdentityUser } from '../entities/identityUser';
import { Guid } from 'guid-typescript';
import { PaginationFilterUser } from '../entities/PaginationFilterUsers';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class UsersAndRolesService {
  
  apiServiceBot: string = this.environment.GetEnterpriseApi;
  pathUsers= '/api/v1/Users/';

  constructor(private http: HttpClient, private environment: AppConfigService, private OAuth: OAuthService) {}


  GetAllPaginationFilterUsers(pageIndex, pageSize, nameValue, emailValue): Observable<IdentityUser[]> {
    var paginationFilter = new PaginationFilterUser();
    paginationFilter.nameValue = nameValue;
    paginationFilter.pageIndex = pageIndex;
    paginationFilter.pageSize = pageSize;
    paginationFilter.emailValue = emailValue;
    let pathMetodo = 'GetAllPaginationFilterUsers';
    return this.http.post<IdentityUser[]>(this.apiServiceBot + this.pathUsers + pathMetodo, paginationFilter,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetCountPaginationUsers(pageIndex, pageSize, nameValue, emailValue): Observable<number> {
    var paginationFilter = new PaginationFilterUser();
    paginationFilter.nameValue = nameValue;
    paginationFilter.pageIndex = pageIndex;
    paginationFilter.pageSize = pageSize;
    paginationFilter.emailValue = emailValue;
    let pathMetodo = 'GetCountPaginationUsers';
    return this.http.post<number>(this.apiServiceBot + this.pathUsers + pathMetodo, paginationFilter,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllUsers(): Observable<IdentityUser[]> {
    let pathMetodo = 'GetAllUsers';
    return this.http.get<IdentityUser[]>(this.apiServiceBot + this.pathUsers + pathMetodo,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  CreateUsers(entity: IdentityUser): Observable<any>{
    let pathMetodo = 'CreateUsers';
    return this.http.post<any>(this.apiServiceBot + this.pathUsers + pathMetodo, entity,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }
  
  UpdateUsers(entity: IdentityUser): Observable<any>{
    let pathMetodo = 'UpdateUsers';
    return this.http.put<any>(this.apiServiceBot + this.pathUsers + pathMetodo, entity,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetUserById(userId: string): Observable<any>{
    let pathMetodo = 'GetUserById/'+userId;
    return this.http.get<any>(this.apiServiceBot + this.pathUsers + pathMetodo,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetAllRoles(): Observable<any>{
    let pathMetodo = 'GetAllRoles';
    return this.http.get<any>(this.apiServiceBot + this.pathUsers + pathMetodo,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetRolesByUserId(userId: string): Observable<any>{
    let pathMetodo = 'GetRolesByUserId/' + userId;
    return this.http.get<any>(this.apiServiceBot + this.pathUsers + pathMetodo,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  AssignRolesToUser(userRoles: UserAndRoles): Observable<any>{
    let pathMetodo = 'AssignRolesToUser';
    return this.http.post<any>(this.apiServiceBot + this.pathUsers + pathMetodo, userRoles,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  UpdateUser(entity: IdentityUser): Observable<any>{
    let pathMetodo = 'UpdateUsers';
    return this.http.put<any>(this.apiServiceBot + this.pathUsers + pathMetodo, entity,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  DeleteUserById(id: string): Observable<any>{
    let pathMetodo = 'DeleteUser/'+id;
    return this.http.delete<any>(this.apiServiceBot + this.pathUsers + pathMetodo,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }
 
  //Métodos de Errores
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
    } else {
    }
    if(error.status == 400){
      return throwError(error.error);
    } else if(error.status == 500){
      return throwError(error.error);
    }else{
      return throwError(
        'Something bad happened, please try again.');
    }
   
  };
}