<div style="text-align: center">
    <i class="icon-bubbles"></i>
    <h3> {{'dashboard.WELCOME' | translate}} </h3>
</div>

<div class="row">
    <div class="col-lg-6 col-sm-12 col-md-12" style="margin-top: 10%;"> 
        <div class="container" style="width: 50%; height: 30%;">
            <dl-date-time-picker
                startView="month"
                maxView="year"
                minView="month"
                [selectFilter]="selectFilter"
                [(ngModel)]="selectedDate"
                (click)="selectDate()"
            >
            </dl-date-time-picker>
        </div>
    </div>
    <div class="col-lg-6 col-sm-12 col-md-12" style="margin-top: 5%;">
        <div style="text-align: center; margin-top: 10%;" *ngIf= "notExistData">No hay información para mostrar</div>
        <div class="chart-container container" style="width: 100%; height: 100%; margin-right:30%;">
            <canvas id="canvas">{{ Linechart }}</canvas> 
        </div>
    </div>
</div>