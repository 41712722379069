import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { BotService } from '../../../../app/services/botService';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThemesService } from 'src/app/core/themes/themes.service';
@Component({
    selector: 'app-intentions',
    templateUrl: './intentions.component.html',
    styleUrls: ['./intentions.component.scss'],
    encapsulation: ViewEncapsulation.None
  })
  export class IntentionsSearchComponent implements OnInit {
    json: null;
    notAllowed: boolean = false;
    readonly: boolean = false;
    placeholder: string = 'botAdmin.generic.selectrecord';
    ngOnInit() {}

    constructor(private themeService: ThemesService, private spinner: NgxSpinnerService, private botService: BotService)
    {
      this.themeService.createStyle();
      this.themeService.setTheme(localStorage.getItem('theme'));
      var userRoles = JSON.parse(localStorage.getItem('roles'));
      if (userRoles.indexOf('OpacAdmin') == -1 && userRoles.indexOf('GlobalAdmin') == -1 && userRoles.indexOf('ReadOnlyUser') == -1)
      this.notAllowed = true;

    if (userRoles.indexOf('ReadOnlyUser') == 0)
      this.readonly = true;
    }

    searchIntentions(texto: string){
        this.botService.GetRecognizerJSON(texto)
        .subscribe((rest: any) => {
            this.json = rest;
        });
    }
  }
