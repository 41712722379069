import { DataSource } from '@angular/cdk/table';
import { ExceptedWord } from '../entities/exceptedWord';
import { catchError, finalize } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { BotService } from '../services/botService';
import { PaginationFilterExceptedWords} from '../entities/paginationFilterExceptedWords';

export class ExceptedWordDataSource implements DataSource<ExceptedWord> {

    private exceptedWordSubject = new BehaviorSubject<ExceptedWord[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private exceptedWordService: BotService) {}

    connect(collectionViewer: CollectionViewer): Observable<ExceptedWord[]> {
        return this.exceptedWordSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.exceptedWordSubject.complete();
        this.loadingSubject.complete();
    }

    loadExceptedWords(paginationFilter: PaginationFilterExceptedWords) {

        this.loadingSubject.next(true);

        this.exceptedWordService.GetAllPaginationFilterExceptedWords(paginationFilter).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(entity => {
            this.exceptedWordSubject.next(entity)
        });
    }    
}