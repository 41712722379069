import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { ExceptedWord } from 'src/app/entities/exceptedWord';
import { BotService } from '../../../services/botService';
import { Observable } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { startWith, delay, tap, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/sharedService';
import { TranslateService } from '@ngx-translate/core';
import { ThemesService } from 'src/app/core/themes/themes.service';
import { property } from 'lodash';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-exceptedword-abm',
  templateUrl: './exceptedword-abm.component.html',
  styleUrls: ['./exceptedword-abm.component.scss'],
  encapsulation: ViewEncapsulation.None
})
  export class ExceptedWordAbmComponent implements OnInit {
    // Entity Variables
    exceptedWordId: number;
    exceptedWordModel: ExceptedWord = new ExceptedWord();
    // Tab Variables
    activeTab: number = 1;
    disTab: boolean = false;
    showed: boolean = false;
    //Form Variables
    title: string = "botAdmin.exceptedwordabm.subtitle1";
    nameCreate: string = "botAdmin.generic.create";
    nametoedit: string = "";
    //statusWelcome: boolean = false;
    description: string;
    typeService: string;
    exceptedWordCreateFormGroup: UntypedFormGroup;
    notAllowed: boolean = false;
    readonly: boolean = false;
    submitted: boolean;
    //Input variables
    visibilityArchive:boolean = false;
    visibilityInput: boolean = true;
    fileWords: FileReader;
    fileContent: string;
    listWords: ExceptedWord[] = [];
    @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;
    fileName: string;
    statusInput: boolean = true;
    msgAbmWordModal: string;
    visibleAbmWordModal: boolean = true;
    urlImageAbmWordModal: string;    
    modalRef: NgbModalRef;

    constructor(private urlService: SharedService, private router: Router, private services: BotService, private toast: ToastrService, private spinner: NgxSpinnerService,
      private formBuilder: UntypedFormBuilder, private translate: TranslateService, private modal: NgbModal)
    {

      if(urlService.model.action == "update")
        this.visibleAbmWordModal = false;

      if(urlService.model.action == "archive") {
        this.visibilityInput = false;
        this.visibilityArchive = true; 
      }

      var userRoles = JSON.parse(localStorage.getItem('roles'));
    
      if (userRoles.indexOf('OpacAdmin') == -1 && userRoles.indexOf('GlobalAdmin') == -1 && userRoles.indexOf('ReadOnlyUser') == -1)
        this.notAllowed = true;

      if (userRoles.indexOf('ReadOnlyUser') == 0) {
        this.readonly = true;
      }
      this.spinner.hide();
    }

    ngOnInit() {
      this.statusInput  = true;
      if(this.urlService.model.id != "0"){
        this.title = "botAdmin.exceptedwordabm.subtitle2";
        this.nameCreate = "botAdmin.generic.update";
        this.disTab = false;
        this.exceptedWordId = this.urlService.model.id;
        this.GetExceptedWordById();
      }
      
      /*
      if(this.urlService.model.id != "0"){
        this.title = "botAdmin.exceptedwordabm.subtitle2";
        this.nameCreate = "botAdmin.generic.update";
        this.disTab = false;
        this.exceptedWordId = this.urlService.model.id;
        this.GetExceptedWordById();
      }
      */
    this.getFormCreate();
    }
    disableInput(){

    }
    GetExceptedWordById(){
      this.services.GetExceptedWordById(this.exceptedWordId).subscribe(x=>{
        this.exceptedWordModel = x;
        this.nametoedit = x.description;
        this.title = "botAdmin.conversationabm.subtitle2";
        this.exceptedWordCreateFormGroup.patchValue({
          description: x.description,
          typeService: x.type
        });
      })
    }

    getFormCreate(){
      this.exceptedWordCreateFormGroup = this.formBuilder.group({
        description: ['', Validators.required],
        typeService: ['', Validators.required],
        fileName: ['']
      });
    }

    get f() { return this.exceptedWordCreateFormGroup.controls; }

    changeTabs(tabN: number) {
      this.activeTab = tabN;
    }

    create(){
      var newDescription = this.exceptedWordCreateFormGroup.get("description").value;
      var newtype = this.exceptedWordCreateFormGroup.get("typeService").value;
      if (newtype == "") {
        this.translate.stream('botAdmin.generic.required').subscribe(res => {
          this.toast.error(res);
        });
        return;
      }
      
      if (newDescription == "" && this.urlService.model.action == "create") {
        this.translate.stream('botAdmin.generic.required').subscribe(res => {
          this.toast.error(res);
        });
        return;
      }

      if(this.urlService.model.action == "archive") {
        if (this.fileWords === undefined) {
          this.translate.stream('botAdmin.generic.required').subscribe(res => {
            this.toast.error(res);
          });
          return;
        }
        newDescription = this.fileWords.result;
        this.urlService.model.action = "create"
      }
      
      newDescription = newDescription.toLowerCase();

      var listText = newDescription.split(",");
      listText = listText.filter(x => x != "");
      listText = new Set(listText);
      listText.forEach(item => {
        if (newtype != "all") {
          var word = new ExceptedWord();
          word.description = item;
          word.type = newtype;
          word.status = true;
          this.listWords.push(word)
        }
        else {
          var word = new ExceptedWord();
          word.description = item;
          word.type = "SEARCH";
          word.status = true;
          this.listWords.push(word)

          word = new ExceptedWord();
          word.description = item;
          word.type = "SPELLCHECK";
          word.status = true;
          this.listWords.push(word)
        }

      });
      if(this.urlService.model.action == "create") {
        this.createExceptedWord(this.listWords);
      }
      else{
        if (listText.length > 1) {
          this.translate.stream('backendmessages.i607').subscribe(res => {
            this.toast.error(res);
          });
        }
        else {
          this.exceptedWordModel.description = newDescription;
          this.exceptedWordModel.type = newtype;
          this.updateExceptedWord(this.exceptedWordModel)
        }
      }
    }

    createExceptedWord(exceptedWords: ExceptedWord[]) {
      var countToAdd = exceptedWords.length;
      this.spinner.show();      
      
      var type = exceptedWords[0].type;
      var newList = [];

      //obtener todas las palabras y controlar que las nuevas no esten repetidas
      this.services.GetAllExceptedWords(type).subscribe(x=>{
        exceptedWords.forEach(element => {
          var insert = true;
          x.forEach(item => {
            if (element.description == item.description)
              insert = false
          });
          if (insert)
            newList.push(element);
        });
        exceptedWords = newList;

        if (exceptedWords.length == 0) {
          this.translate.stream('backendmessages.i609').subscribe(res => {
            this.toast.error(res);
          });
          this.spinner.hide();
          return false;
        }

        this.services.CreateListExceptedWords(exceptedWords).subscribe(x => {
          this.translate.stream('backendmessages.' + x.value.code).subscribe(res => {
            if (x.statusCode === 200) {
              if (exceptedWords.length === countToAdd) {
                this.toast.success(res);
                this.router.navigate(['/management/exceptedword']);
              }
              else {
                this.translate.stream('backendmessages.i610').subscribe(res => {
                  this.toast.success(res);
                });
                this.router.navigate(['/management/exceptedword']);
              }
              
            }
            else {
              this.toast.error(res);
            }
          });
          this.spinner.hide();
        }, error => {
          this.translate.stream('backendmessages.i403').subscribe(res => {
            this.toast.error(res);
          });
          this.spinner.hide();
        });

      }, error => {
        this.translate.stream('backendmessages.i403').subscribe(res => {
          this.toast.error(res);
        });
      });
    }

    updateExceptedWord(exceptedWord: ExceptedWord) {
      this.spinner.show();
      this.services.UpdateExceptedWord(exceptedWord).subscribe(x => {
        this.translate.stream('backendmessages.' + x.value.code).subscribe(res => {
          if (x.statusCode === 200) {
            this.toast.success(res);
            this.router.navigate(['/management/exceptedword']);
          }
          else {
            this.toast.error(res);
          }
          this.nametoedit = exceptedWord.description;
          this.title = "botAdmin.exceptedwordabm.subtitle2";
        });
  
        this.spinner.hide();
      }, error => {
        this.translate.stream('backendmessages.i403').subscribe(res => {
          this.toast.error(res);
        });
        this.spinner.hide();
      });
    }

    ngAfterViewInit() {
  
    }

    redirect(e: any){
      this.urlService.navigateToViewElement('/management/exceptedword', null, null, null, null, null, e.button, null);
    }

    LoadFileTxt(e: File[]) {
      let archive = e[0];
      if (archive){
        if (archive.type == "text/plain") {
          this.fileWords = new FileReader();
          this.fileWords.onload = (e) => {
            this.ChangeInput(archive.name);
          };
          
          this.fileWords.readAsText(archive);
        }
        else {
          this.translate.stream('backendmessages.i608').subscribe(res => {
            this.toast.error(res);
          });
          this.ChangeInput("");
        }
      } else {
        this.translate.stream('backendmessages.i604').subscribe(res => {
          this.toast.error(res);
        });
        this.ChangeInput("");
      }
    }
    
    ChangeInput(text: any)
    {
     this.exceptedWordCreateFormGroup.patchValue({
        fileName: text
      });

    }

    ActivarInputFile() {
      let el: HTMLElement = this.myDiv.nativeElement;
      el.click();
    }

    openModal(content) {
      if (this.urlService.model.action == "create") {
        this.translate.stream('exceptedWordABM.modalAbm.msgAddPerWord').subscribe(res => {
          this.msgAbmWordModal = res;
          this.urlImageAbmWordModal = "../../../../assets/img/addPerWord.png";
        });        
      }
      else {
        this.translate.stream('exceptedWordABM.modalAbm.msgAddPerFile').subscribe(res => {
          this.msgAbmWordModal = res;
          this.urlImageAbmWordModal = "../../../../assets/img/addPerFile.png";
        });
      }
      this.modalRef = this.modal.open(content, { size: 'lg', backdrop: 'static' });
    }
    
    /*
    RemoveRepeatedWords(exceptedWords: ExceptedWord[]) : Observable<any>{
      return newList;
    }
    */
  }
