    export class IdentityUser{
        public lockoutEnd: boolean;
        public twoFactorEnabled:boolean;
        public phoneNumberConfirmed: boolean;
        public phoneNumber: string;
        public concurrencyStamp: string;
        public securityStamp: string;
        public passwordHash: string;
        public emailConfirmed: boolean;
        public normalizedEmail: string;
        public email: string;
        public normalizedUserName: string;
        public userName: string;
        public id: string;
        public lockoutEnabled: boolean;
        public accessFailedCount: number;
        public sectorId: number;
        
        constructor(){
            this.email = "";
            this.userName = "";
        }
    }
      