import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';

//new Imports
import { SpinnerComponent } from './core/spinner/spinner.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthGuard } from './auth/auth.guard';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { OAuthModule } from 'angular-oauth2-oidc';
import { CallbackComponent } from './auth/callback/callback.component';
import { AppConfigService } from './services/AppConfigService';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { TreeModule } from 'angular-tree-component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChartsModule } from 'ng2-charts';
import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { NgxFileDropModule } from 'ngx-file-drop';

// Errors, Panel, LogOut and more
import { Error403Component } from './routes/error403/error403.component';
import { LogoutComponent } from './routes/logout/logout.component';
import { PanelComponent } from './routes/panel/panel.component';
import { Error404Component } from './routes/error404/error404.component';
import { CustomDualComponent} from './layout/custom-dual/custom-dual.component'

//Bot Administration 
import { AssociateComponent } from './routes/botAdministration/associate/associate.component';
import { ActionsComponent } from './routes/botAdministration/actions/actions.component';
import { ActionsAbmComponent } from './routes/botAdministration/actions-abm/actions-abm.component';
import { ConversationsComponent } from './routes/botAdministration/conversations/conversations.component';
import { ConversationsAbmComponent } from './routes/botAdministration/conversations-abm/conversations-abm.component';
import { AssociateAbmComponent } from './routes/botAdministration/associate-abm/associate-abm.component';
import { ProcessAbmComponent } from './routes/botAdministration/process-abm/process-abm.component';
import { ProcessComponent } from './routes/botAdministration/process/process.component';
import { ExceptedWordComponent } from './routes/botAdministration/exceptedword/exceptedword.component';
import { ExceptedWordAbmComponent } from './routes/botAdministration/exceptedword-abm/exceptedword-abm.component';

// Bot Tools
import { ChatBotComponent } from './routes/tools/chat/chat.component';
import { IntentionsSearchComponent } from './routes/tools/intentions/intentions.component';
import { SearchComponent } from './routes/tools/search/search.component';
import { SpellCheckComponent } from './routes/tools/spellcheck/spellcheck.component';

// Bot Configuration
import { UsersComponent } from './routes/configuration/users/users.component';
import { ConfigurationComponent } from './routes/configuration/configuration/configuration.component';
import { UsersAbmComponent } from './routes/configuration/users-abm/users-abm.component';

// Material imports
import { MatPaginatorModule } from '@angular/material/paginator';
import { MAT_DATE_LOCALE } from '@angular/material/core';
//Services
import { UserService } from './services/userService';
import { BotService } from './services/botService';
import { SapService } from './services/sapService';
import { AdministrationService } from './services/administrationService';
import { BrowserModule } from '@angular/platform-browser';
import { UsersAndRolesService } from './services/usersAndRolesService';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { LMarkdownEditorModule } from 'ngx-markdown-editor';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';



import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { CustomDatePipe } from './core/pipes/custom-date.pipe';
import { ReversePipe } from './core/pipes/custom-reverse.pipe';
import { LogsComponent } from './routes/tools/logs/logs.component';

const appInitializerFn = (appConfig: AppConfigService) => {
    return () => {
        return appConfig.loadAppConfig();
    }
};

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        CallbackComponent,
        Error403Component,
        Error404Component,
        LogoutComponent,
        SpinnerComponent,
        PanelComponent,
        ActionsComponent,
        ActionsAbmComponent,
        ProcessComponent,
        ProcessAbmComponent,
        ConversationsComponent,
        ConversationsAbmComponent,
        ChatBotComponent,
        IntentionsSearchComponent,
        SearchComponent,
        SpellCheckComponent,
        ExceptedWordComponent,
        ExceptedWordAbmComponent,
        AssociateComponent,
        CustomDualComponent,
        UsersComponent,
        ConfigurationComponent,
        UsersAbmComponent,
        AssociateAbmComponent,
        LogsComponent,
        CustomDatePipe,
        ReversePipe
   
    ],
    imports: [
        HttpClientModule,
        AngularDualListBoxModule,
        BrowserAnimationsModule, // required for ng2-tag-input
        CoreModule,
        BrowserModule,
        NgxFileDropModule,
        FormsModule,
        LayoutModule,
        LMarkdownEditorModule,
        SharedModule.forRoot(),
        OAuthModule.forRoot(),
        TreeModule.forRoot(),
        RoutesModule,
        NgSelectModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        ToastrModule.forRoot({
            timeOut: 9999,
            positionClass: 'toast-top-right',
            closeButton: true,
            progressAnimation: "decreasing",
            preventDuplicates: true
        }),
        MatPaginatorModule,
        MatSortModule,
        NgbModule,
        ReactiveFormsModule,
        MatTableModule,
        NgxSpinnerModule,
        ChartsModule,
        DlDateTimeDateModule,
        DlDateTimePickerModule,
        NgxJsonViewerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSortModule,
        MatAutocompleteModule,
        BrowserModule,
        AutocompleteLibModule
    ],
    providers: [AuthGuard, OAuthService, { provide: OAuthStorage, useValue: localStorage },
        UserService, BotService, AdministrationService, SapService,
        UsersAndRolesService, AppConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFn,
            multi: true,
            deps: [AppConfigService]
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'en-GB'
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
