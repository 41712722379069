import { TranslateService } from '@ngx-translate/core';

const headingMain = {
    text: 'sidebar.heading.HEADER',
    heading: true
};
const Logout = {
    text: 'menu.logout',
    link: '/logout',
    icon: 'icon-logout',
};
const Panel = {
    text: 'menu.panel',
    link: '/panel',
    icon: 'icon-home',
};


const Tools = {
    text: 'menu.tools.title',
    link: '/tools',
    icon: 'icon-wrench',
    submenu: [
        {
            text: 'menu.tools.intentions',
            link: '/tools/intentions'
        },
        {
            text: 'menu.tools.search',
            link: '/tools/search'
        },
        {
            text: 'menu.tools.spellcheck',
            link: '/tools/spellcheck'
        },
        {
            text: 'menu.tools.chat',
            link: '/tools/chat'
        },
        {
            text: 'menu.tools.logs',
            link: '/tools/logs'
        }
    ]
};

const Configuration = {
    text: 'menu.configuration.title',
    link: '/administration',
    icon: 'icon-settings',
    submenu: [
        {
            text: 'menu.configuration.users',
            link: '/administration/users'
        },
        {
            text: 'menu.configuration.configuration',
            link: '/administration/configuration'
        },
    ]
};
const Management = {
    text: 'menu.management.title',
    link: '/management',
    icon: 'icon-speech',
    submenu: [
        {
            text: 'menu.management.options',
            link: '/management/options'
        },
        {
            text: 'menu.management.actions',
            link: '/management/actions'
        },
        {
            text: 'menu.management.casual_conv',
            link: '/management/conversations'
        },
        {
            text: 'menu.management.process',
            link: '/management/process'
        },
        {
            text: 'exceptedWord.title',
            link: '/management/exceptedword'
        },
        {
            text: 'menu.management.luis',
            elink: 'https://luis.ai',
            target: '_blank'
        }

    ]
};

export const menu = [
    headingMain,
    Panel,
    Management,
    Tools,
    Configuration,
    Logout,
];