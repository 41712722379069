<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
    <!-- START navbar header-->
    <div class="navbar-header">
        <a class="navbar-brand" href="#/">
            <div class="brand-logo hide-from-cel">
                <img class="img-fluid" [src]="logoPath" style="max-height: 48px;" alt="App Logo" />
            </div>
            <div class="brand-logo-collapsed">
                <img class="img-fluid" [src]="logoSmallPath" alt="App Logo" />
            </div>
        </a>
    </div>
    <!-- END navbar header-->
    <!-- START Left navbar-->
    <ul class="navbar-nav mr-auto flex-row">
        <li class="nav-item">
            <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
            <a class="nav-link d-none d-md-block d-lg-block d-xl-block" trigger-resize="" (click)="toggleCollapsedSideabar()" *ngIf="!isCollapsedText()">
                <em class="fas fa-bars"></em>
            </a>
            <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
            <a class="nav-link sidebar-toggle d-md-none" (click)="settings.toggleLayoutSetting('asideToggled'); $event.stopPropagation()">
                <em class="fas fa-bars"></em>
            </a>
        </li>
        <!-- START User avatar toggle-->
        <li class="nav-item d-none d-md-block">
            <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
            <a  class="nav-link" (click)="toggleUserBlock($event)">
                <em class="icon-user"></em>
            </a>
        </li>
        <!-- END User avatar toggle-->
       
    </ul>
    <!-- END Left navbar-->
    <!-- START Right Navbar-->
    <ul class="navbar-nav flex-row">
        <!-- START Offsidebar button-->
        <li class="nav-item">
            <a class="nav-link" (click)="toggleOffsidebar(); $event.stopPropagation()">
                <em class="icon-notebook"></em>
            </a>
        </li>
        <!-- END Offsidebar menu-->
    </ul>
    <!-- END Right Navbar-->
    
    <app-navsearch [visible]="getNavSearchVisible()" (onclose)="setNavSearchVisible(false)"></app-navsearch>

    
</nav>
<!-- END Top Navbar-->
