<spinner></spinner>
<div *ngIf="notAllowed">
  <app-error404></app-error404>
</div>
<div *ngIf="!notAllowed">

  <div class="content-heading">
      <div>
          <h3> {{'botAdmin.actions.title' | translate}} </h3>
          <h6 id="subtitle" > {{'botAdmin.actions.subtitle' | translate}} </h6>
      </div>


      <div class="ml-auto">
        <a class="mr-1 btn btn-labeled btn-info mb-2 col-sm-12 col-md-12 col-lg-12 " href=""
         (mouseup)=" redirect(0, $event)" [class.disabled]='readonly' >
            <span class="btn-label"><i class="fa fa-plus"></i>
            </span> {{'botAdmin.generic.new' | translate}} </a>
      </div>
 

  </div>
  <div class="row">
      <div class="col-md-auto">
          <div class="input-group mb-3">
              <div class="input-group-prepend">
              <span class="input-group-text" name="labelSize" > {{'botAdmin.generic.searchtitle' | translate}} </span>
              </div>
              <input type="text" (keyup.enter)="GetActions()" [(ngModel)]="titleValue" class="form-control" id="basic-url" >
          </div>
      </div>
      <div class="col-md-auto">
          <div class="input-group mb-3">
              <div class="input-group-prepend" >
                <label class="input-group-text" name="labelSize" for="inputGroupSelect01"> {{'botAdmin.generic.status' | translate}} </label>
              </div>
              <select class="custom-select"  [(ngModel)]="statusValue" id="inputGroupSelect01">
                <option value="all"> {{'botAdmin.generic.statusOp1' | translate}} </option>
                <option value="true"> {{'botAdmin.generic.statusOp2' | translate}} </option>
                <option value="false"> {{'botAdmin.generic.statusOp3' | translate}} </option>
              </select>
          </div>
      </div>
      <div class="col-md-auto">
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" for="inputGroupSelect01" name="labelSize" > {{'botAdmin.actions.type' | translate}} </label>
              </div>
              <select class="custom-select"  [(ngModel)]="typeValue" id="inputGroupSelect01">
                <option value="all"> {{'botAdmin.generic.statusOp1' | translate}} </option>
                <option value="ANSWER">Answer</option>
                <option value="LINK" >Link</option>
                <option value="IMAGE" >Image</option>
                <option value="VIDEO" >Video</option>
              </select>
          </div>
      </div>
      <div class="col-md-auto">
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" for="inputGroupSelect01" name="labelSize" > {{'botAdmin.generic.result' | translate}} </label>
              </div>
              <select class="custom-select" [(ngModel)]="pageSize" id="inputGroupSelect01" (click)="changePageSize()" >
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
          </div>
      </div>
      <div class="col-md-auto">
        <div class="input-group mb-3">
        <button class="mr-1 btn btn-labeled btn-info mb-2" type="button" id="search2"
        (click)="GetActions()">
            <i class="fa fa-search" id="search"></i>
            </button>
        </div>
      </div>
    
  </div>
  <hr id="hr1">
  <div id="tableContainer">
      <div class="row">
          <div class="table-responsive">
            <div class="card-body">

              <div>

                <mat-table #table [dataSource]="dataSource" >

                  <ng-container matColumnDef="actionTitle">
                    <mat-header-cell *matHeaderCellDef > {{'botAdmin.actions.table1' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.actionTitle}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="actionType">
                    <mat-header-cell *matHeaderCellDef > {{'botAdmin.actions.table2' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="type"> {{row.actionType}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="isActived">
                    <mat-header-cell *matHeaderCellDef > {{'botAdmin.actions.table3' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="status"> {{!row.isActived ? "Disable" : "Enable"}} </mat-cell>
                  </ng-container>
        
                  <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef> {{'botAdmin.actions.table4' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="actions">
                        <a (mouseup)="redirect(row.actionId, $event)" href="" class="btn btn-info" > {{'botAdmin.generic.enter' | translate}} </a> 
                        </mat-cell>
                  </ng-container>
        
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"> </mat-row>

                </mat-table>
                <div *ngIf="noData | async" class="no-records">
                  {{'botAdmin.generic.notfound' | translate}} 
                </div>
                <mat-paginator [length]="actionsLenght" [pageSize]="pageSize" [hidePageSize]="true">  </mat-paginator>
              </div>
            </div>
          </div>
      </div>
  </div>
</div>