import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Router, ActivatedRoute } from '@angular/router';
import { UserDataSource } from 'src/app/dataSources/userDataSource';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { UsersAndRolesService } from 'src/app/services/usersAndRolesService';
import { NgxSpinnerService } from 'ngx-spinner';
import { startWith, delay, tap, map } from 'rxjs/operators';
import { SharedService } from 'src/app/sharedService';
import { ThemesService } from 'src/app/core/themes/themes.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UsersComponent implements OnInit {

  //Table variables
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  dataSource: UserDataSource;
  displayedColumns: string[] = ['userName', 'email', 'actions'];
  noData : Observable<boolean>;
  collectionviewer: CollectionViewer;

  // Paginator variables
  nameValue: string = "";
  emailValue: string = "";
  pageSize: number = 15;
  usersLenght: number = 0;
  sizeOptions = [15, 25, 50];
  notAllowed: boolean = false;

  constructor(private themeService: ThemesService, private urlService: SharedService, private userService: UsersAndRolesService, private spinner: NgxSpinnerService,
    private router: Router, private route: ActivatedRoute) {
      this.themeService.createStyle();
      this.themeService.setTheme(localStorage.getItem('theme'));
      var userRoles = JSON.parse(localStorage.getItem('roles'));
      if (userRoles.indexOf('GlobalAdmin') == -1  && userRoles.indexOf('SecurityAdmin') == -1)
        this.notAllowed = true;
      this.spinner.hide();
     }

  ngOnInit() {
    this.GetUsers();
  }

  ngAfterViewInit() {
    this.paginator.page
        .pipe(
          startWith(null),
          delay(0),
          tap(() => this.loadPage())
        ).subscribe();
  }

  GetUsers() {
    this.dataSource = new UserDataSource(this.userService);
    this.dataSource.loadUsers(0, this.pageSize, this.nameValue, this.emailValue);
    this.noData = this.dataSource.connect(this.collectionviewer).pipe(map(data => data.length === 0));
    this.userService.GetCountPaginationUsers(0, this.pageSize, this.nameValue, this.emailValue).subscribe(rest =>{
      this.usersLenght = rest;
    });
  }

  loadPage() { 
    this.dataSource.loadUsers(
      this.paginator.pageIndex, this.pageSize, this.nameValue, this.emailValue
      );
  }

  changePageSize(){
    this.paginator.pageSize = this.pageSize;
    this.paginator.pageIndex = 0;
    this.loadPage();
  }

  redirect(id: string,e: any){
    this.urlService.navigateToViewElement('/administration/users/crud', null, id, null, null, null, e.button, null);
  }

}
