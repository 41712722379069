import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { BotService } from '../../../services/botService';
import { ExceptedWordDataSource } from '../../../dataSources/exceptedWordDataSource'; //ward
import { Observable } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { startWith, delay, tap, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from 'src/app/sharedService';
import { ThemesService } from 'src/app/core/themes/themes.service';
import { PaginationFilterExceptedWords } from 'src/app/entities/paginationFilterExceptedWords'; //ward
import { property } from 'lodash';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ExceptedWord } from 'src/app/entities/exceptedWord';
import { debug } from 'console';

@Component({
    selector: 'app-exceptedword',
    templateUrl: './exceptedword.component.html',
    styleUrls: ['./exceptedword.component.scss'],
    encapsulation: ViewEncapsulation.None
  })
  export class ExceptedWordComponent implements OnInit {
    //Table variables
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    dataSource: ExceptedWordDataSource;
    displayedColumns: string[] = ['description', 'type', 'exceptedWordId'];
    noData: Observable<boolean>;
    collectionviewer: CollectionViewer;

    // Paginator variables
    descriptionValue: string;
    statusValue: string = "all";
    typeValue: string = "all";
    pageSize: number = 15;
    exceptedWordLenght: number = 0;
    totalSize: number;
    sizeOptions = [15, 25, 50];
    notAllowed: boolean = false;
    readonly: boolean = false;
    idWordToDelete: number;
    descriptionWordToDelete: string="";
    // model
    modalRef: NgbModalRef;
    typeValueModal: string = "all";
    downloadtxt: string;
    constructor(private themeService: ThemesService, private urlService: SharedService, private router: Router, private exceptedWordService: BotService, private spinner: NgxSpinnerService,
      private modal: NgbModal, private services: BotService, private translate: TranslateService, private toast: ToastrService)
    {
      this.themeService.createStyle();
      this.themeService.setTheme(localStorage.getItem('theme'));
      var userRoles = JSON.parse(localStorage.getItem('roles'));
      if (userRoles.indexOf('OpacAdmin') == -1 && userRoles.indexOf('GlobalAdmin') == -1 && userRoles.indexOf('ReadOnlyUser') == -1)
        this.notAllowed = true;

      if (userRoles.indexOf('ReadOnlyUser') == 0) {
        this.readonly = true;

      }
      this.spinner.hide();
    }
  
    ngOnInit() {
      this.GetExceptedWords()
    }

    ngAfterViewInit() {
      this.paginator.page
        .pipe(
          startWith(null),
          delay(0),
          tap(() => this.loadPage())
        ).subscribe();
      
      this.GetWordTypes();
      
    }

    redirect(id: any, e: any, action: any) {
      this.urlService.navigateToViewElement('/management/exceptedword/crud', null, id, null, action, null, e.button, null);
    }

    GetExceptedWords() {
      var paginationFilter = new PaginationFilterExceptedWords();
      paginationFilter.pageIndex = 0;
      paginationFilter.pageSize = this.pageSize;
      paginationFilter.description = this.descriptionValue;
      paginationFilter.typeExceptedWord = this.typeValue;
      this.dataSource = new ExceptedWordDataSource(this.exceptedWordService);
      this.dataSource.loadExceptedWords(paginationFilter);
      this.noData = this.dataSource.connect(this.collectionviewer).pipe(map(data => data.length === 0));
      this.exceptedWordService.getAllPaginationExceptedWordsCount(paginationFilter).subscribe(rest => {
        this.exceptedWordLenght = rest;
      });
    }
  
    loadPage() {
      var paginationFilter = new PaginationFilterExceptedWords();
      paginationFilter.pageIndex = this.paginator.pageIndex;
      paginationFilter.pageSize = this.pageSize;
      paginationFilter.description = this.descriptionValue;
      paginationFilter.typeExceptedWord = this.typeValue;
      this.dataSource.loadExceptedWords(paginationFilter);
    }
  
    changePageSize() {
      this.paginator.pageSize = this.pageSize;
      this.paginator.pageIndex = 0;
      this.loadPage();
    }
  
    openModal(id: any, description: any, content) {
      this.idWordToDelete = id;
      this.descriptionWordToDelete = description;
      this.modalRef = this.modal.open(content, { size: 'md', backdrop: 'static' });
    }

    openModalDownload(content) {
      this.modalRef = this.modal.open(content, { size: 'md', backdrop: 'static' });
    }

    deleteExceptedWord() {
      this.modal.dismissAll();
      this.spinner.show();
      this.services.DeleteExceptedWord(this.idWordToDelete).subscribe(x => {
        this.translate.stream('backendmessages.' + x.value.code).subscribe(res => {
          if (x.statusCode === 200) {
            this.toast.success(res);
            this.GetExceptedWords();
          }
          else {
            this.toast.error(res);
          }
        });
        this.spinner.hide();
      }, error => {
        this.translate.stream('backendmessages.i403').subscribe(res => {
          this.toast.error(res);
        });
        this.spinner.hide();
      });
    }

    DownLoadExceptedWords() {
      this.spinner.show();
      this.services.GetAllExceptedWords(this.typeValueModal).subscribe(x => {
        const fileName = this.typeValueModal + ".txt";
       ;
        var content = "";
        x.forEach(element => {
          content = content + element.description + ",";
        });
        content = content.substring(0, content.length - 1);

        this.ManageTxtFile(content, fileName);
        this.spinner.hide();
      }, error => {
        this.translate.stream('backendmessages.i403').subscribe(res => {
          this.toast.error(res);
        });
        this.spinner.hide();
      });
      this.modal.dismissAll();
    }

    ManageTxtFile(response: any, fileName: string): void {
      const file = new window.Blob([response], { type: 'text/plain' });

      const downloadAncher = document.createElement("a");
      downloadAncher.style.display = "none";

      const fileURL = URL.createObjectURL(file);
        downloadAncher.href = fileURL;
        downloadAncher.download = fileName;
        downloadAncher.click();
    }

    GetWordTypes() {
      this.services.GetWordTypes().subscribe(types => {
   
      }), error => {
        this.translate.stream('backendmessages.i403').subscribe(res => {
          this.toast.error(res);
        });
      };
    }

  }
