<spinner></spinner>
<div *ngIf="notAllowed">
  <app-error404></app-error404>
</div>
<div *ngIf="!notAllowed">
  <div class="content-heading">
      <div>
          <h3> {{'botAdmin.conversation.title' | translate}} </h3>
          <h6 id="subtitle" > {{'botAdmin.conversation.subtitle' | translate}} </h6>
      </div>
      <div class="ml-auto">
        <a class="mr-1 btn btn-labeled btn-info mb-2 col-sm-12 col-md-12 col-lg-12"
        (mouseup)="redirect(0, $event)" [class.disabled]="readonly">
            <span class="btn-label"><i class="fa fa-plus"></i>
            </span> {{'botAdmin.generic.new' | translate}} </a>
      </div>
  </div>
  <div class="row">
      <div class="col-md-auto">
          <div class="input-group mb-3">
              <div class="input-group-prepend">
              <span class="input-group-text" name="labelSize" > {{'botAdmin.conversation.answer' | translate}} </span>
              </div>
              <input type="text" (keyup.enter)="GetConversations()" [(ngModel)]="answerValue" class="form-control" id="basic-url" >
          </div>
      </div>
      <div class="col-md-auto">
          <div class="input-group mb-3">
              <div class="input-group-prepend" >
                <label class="input-group-text" name="labelSize" for="inputGroupSelect01"> {{'botAdmin.conversation.welcome' | translate}} </label>
              </div>
              <select class="custom-select" [(ngModel)]="isWelcomeValue" id="inputGroupSelect01">
                <option value="all"> {{'botAdmin.conversation.welcome1' | translate}} </option>
                <option value="true"> {{'botAdmin.conversation.welcome2' | translate}} </option>
                <option value="false"> {{'botAdmin.conversation.welcome3' | translate}} </option>
              </select>
          </div>
      </div>
      <div class="col-md-auto">
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" for="inputGroupSelect01" name="labelSize" > {{'botAdmin.conversation.intentions' | translate}} </label>
              </div>
              <select class="custom-select"  [(ngModel)]="typeValue" id="inputGroupSelect01">
                <option value="all"> {{'botAdmin.conversation.welcome1' | translate}} </option>
                <option *ngFor="let t of intentions" value={{t.category}}>{{t.category}}</option>
              </select>
          </div>
      </div>
      <div class="col-md-auto">
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" for="inputGroupSelect01" name="labelSize" > {{'botAdmin.generic.result' | translate}} </label>
              </div>
              <select class="custom-select" [(ngModel)]="pageSize" (click)="changePageSize()" id="inputGroupSelect01">
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
          </div>
      </div>
      <div class="col-md-auto">
        <div class="input-group mb-3">
        <button class="mr-1 btn btn-labeled btn-info mb-2 col-sm-12 col-md-12 col-lg-12" type="button" (click)="GetConversations()" id="search2">
            <i class="fa fa-search" id="search"></i>
            </button>
        </div>
      </div>
  </div>
  <hr id="hr1">
  <div id="tableContainer">
      <div class="row">
          <div class="table-responsive">
            <div class="card-body">

              <div>

                <mat-table #table [dataSource]="dataSource">

                  <ng-container matColumnDef="resultAnswer">
                    <mat-header-cell *matHeaderCellDef> {{'botAdmin.conversation.answer' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.resultAnswer}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="isWelcome">
                    <mat-header-cell *matHeaderCellDef> {{'botAdmin.conversation.welcome' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="isWelcome"> {{row.isWelcome ? "Enable" : "Disable"}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="intension">
                    <mat-header-cell *matHeaderCellDef> {{'botAdmin.conversation.intentions' | translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="intension"> {{row.intension}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef> {{'botAdmin.conversation.actions' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="actions">
                      <a class="btn btn-info"  (mouseup)="redirect(row.answerConversationId, $event)"> {{'botAdmin.generic.enter' | translate}} </a> </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"> </mat-row>

                </mat-table>
                <div *ngIf="noData | async" class="no-records">
                  {{'botAdmin.generic.notfound' | translate}}
                </div>
                <mat-paginator [length]="conversationsLenght" [pageSize]="pageSize" [hidePageSize]="true">  </mat-paginator>

              </div>


            </div>
          </div>
      </div>
  </div>
</div>
