import { Component, OnInit } from '@angular/core';
import { OAuthService } from "angular-oauth2-oidc";
import { Router, ActivatedRouteSnapshot, ActivatedRoute } from "@angular/router";
import { ProfileUser } from '../../../app/entities/profileUser';
import { UserService } from '../../../app/services/userService';
import { AdministrationService } from '../../../app/services/administrationService';

@Component({
    template: ''
})

export class CallbackComponent implements OnInit {

    profileUser: ProfileUser;
    hab: boolean;

    constructor(private oauthService: OAuthService,
                private router: Router,
                private next: ActivatedRoute,
                private serviceAdministration: AdministrationService,
                private serviceLogin: UserService) {
                    
                }

    ngOnInit() {
        
        this.hab = false;
        this.oauthService.loadDiscoveryDocumentAndTryLogin().then(_ => {
            
            if (!this.oauthService.hasValidIdToken() || !this.oauthService.hasValidAccessToken()) {
                this.oauthService.initImplicitFlow();
            } else {
                    this.serviceLogin.getUserInfoFromEndpoint(this.oauthService.userinfoEndpoint, this.oauthService.getAccessToken()).subscribe(rest => {
                    this.profileUser = new ProfileUser();
                    this.profileUser = rest;
                    this.serviceAdministration.validateUser(this.profileUser.preferred_username, this.profileUser.email)
                    .subscribe(x=>{
                       if(x)
                       {
                            localStorage.setItem('userDni', this.profileUser.name);
                            localStorage.setItem('userMail', this.profileUser.email);
                            localStorage.setItem('userId', this.profileUser.sub);
        
                            this.serviceAdministration.getRolesByUser(this.profileUser.preferred_username)
                                .subscribe(x=>{
                                    localStorage.setItem('roles', JSON.stringify(x));
                                })
                            this.router.navigate(['/panel']);
                       }else{
                        localStorage.clear();
                            this.router.navigate(['/error']);
                       }
                    })
                 
                });
            }
        });
    }
}
