import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ThemesService } from './core/themes/themes.service';

@Injectable({
    providedIn: 'root'
})

export class SharedService implements OnDestroy {
  model: viewElement;

  constructor(public router: Router, public themeService: ThemesService) {
    const str = localStorage.getItem('cache-view');
    if ( str ) {
      this.model = JSON.parse(str);
    }
  }

  setValue(model: viewElement){
    localStorage.setItem('cache-view', JSON.stringify(model));
  }

  async navigateToViewElement(url: any, idParent: any, id: any, showed: any, action: any, tab: any, e: any = 0, filters: any) {
    const element: viewElement = { idParent, id, showed, action, tab };
    this.model = element;
    localStorage.setItem('cache-view', JSON.stringify(element));
    switch (e) {
      case 0:
        this.router.navigate([url]);
        break;
      case 1:
        window.open(url,'_blank');
        break;
    }
  }

  ngOnDestroy(){
    this.model = null;
  }
}
class viewElement {
    idParent: any;
    id: any;
    showed: any;
    action: any;
    tab: any;
}