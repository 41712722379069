<spinner></spinner>
<div *ngIf="notAllowed">
  <app-error404></app-error404>
</div>
<div *ngIf="!notAllowed">
  <div class="content-heading">
    <div>
      <h3> {{'confguration.configuration.title' | translate}} </h3>
      <h6 id="subtitle" > {{'confguration.configuration.subtitle' | translate}} </h6>
    </div>
  </div>

  <div id="tableContainer" >
    <div class="row">
        <div class="table-responsive">
          <div class="card-body">

            <div>

              <mat-table #table [dataSource]="dataSource">

                <ng-container matColumnDef="tipo">
                  <mat-header-cell *matHeaderCellDef> {{'confguration.configuration.type' | translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.configurationName}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="description">
                  <mat-header-cell *matHeaderCellDef> {{'confguration.configuration.description' | translate}} </mat-header-cell>
                  <ng-container *ngIf="this.translate.currentLang == 'English'">
                  <mat-cell  *matCellDef="let row" data-label="description"> {{row.descriptionEnglish}} </mat-cell>
                  </ng-container>
                  <ng-container *ngIf="this.translate.currentLang == 'Español'">
                    <mat-cell  *matCellDef="let row" data-label="description"> {{row.descriptionSpanish}} </mat-cell>
                    </ng-container>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef> {{'confguration.user.actionsfilter' | translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="actions">
                    <button class="btn btn-info" type="button" (click)="openModal(actionModal, row)"> {{'botAdmin.generic.enter' | translate}} </button> </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"> </mat-row>
              </mat-table>
            </div>
          </div>
        </div>
    </div>
  </div>

  <!-- Template alta accion -->
  <div class="container">
      <ng-template #actionModal let-c="close" let-d="dismiss">
          <div class="modal-header">
              <h4 class="modal-title">{{configuracionModal}}</h4>
              <button type="button" class="close" aria-label="Close" (click)="d('')">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <form role="form" [formGroup]="actionsFormGroup">
              <div class="modal-body">
                  <div class="form-row">
                      <div class="form-group col-md-12">
                          <label> {{'confguration.configuration.type' | translate}} </label>
                          <input type="text" placeholder="Título de acción" formControlName="tipo"
                              class="form-control" disabled />
                      </div>
                  </div>
                  <div class="form-row" *ngIf="!isBoolean && f.tipo.value != 'chatorder'
                    && f.tipo.value != 'chatphotosize' && f.tipo.value != 'language'
                    && f.tipo.value != 'inactivityExpiration' && !isLogo && !isTakeActionsSearch && !isSearchScore && !chatbotCloseMsg && !welcomeCard && !isProactiveNotification">
                      <div class="form-group col-md-12">
                          <label> {{'confguration.configuration.value' | translate}} </label>
                          <input type="text" placeholder="Título de acción" formControlName="valor"
                          class="form-control" />
                      </div>
                  </div>
                  <div class="form-row" *ngIf="welcomeCard">
                      <div class="form-group col-md-12">
                          <label> {{'confguration.configuration.value' | translate}} </label>
                          <input type="text" placeholder="Título de acción" formControlName="valor"
                          class="form-control" />
                          <div class="row">
                            <div class="col-12">
                              <span id="legendBtnExport" style="color: red">
                                {{'confguration.configuration.legendWelcomeText' | translate}}
                              </span>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="form-row" *ngIf="(isBoolean && !isTakeActionsSearch && !isSearchScore) || (requiredIndividualVote) || (requiredFinalVote)" >
                    <div class="form-group col-md-12">
                        <label> {{'confguration.configuration.value' | translate}} </label>
                        <select class="form-control" formControlName="valor" >
                          <option  disabled value=null> {{'botAdmin.generic.selectop' | translate}} </option>
                          <option value="true"> True </option>
                          <option value="false"> False </option>
                        </select>
                    </div>
                  </div>
                  <div class="form-row" *ngIf="isSearchScore" >
                    <div class="form-group col-md-12">
                      <label> {{'confguration.configuration.value' | translate}} </label>
                      <input type="number" pattern="[-+]?[0-9]*\.?[0-9]*" placeholder="Puntaje para la búsqueda" formControlName="valor"
                      class="form-control" />
                    </div>
                  </div>
                  <div class="form-row" *ngIf="isTakeActionsSearch" >
                    <div class="form-group col-md-12">
                      <label> {{'confguration.configuration.value' | translate}} </label>
                      <input type="number" pattern="[0-9]" placeholder="Cantidad máxima de registros" formControlName="valor"
                      class="form-control" />
                    </div>
                  </div>
                  <div class="form-row" *ngIf="chatbotCloseMsg" >
                    <div class="form-group col-md-12">
                      <label> {{'confguration.configuration.value' | translate}} </label>
                      <input type="text" placeholder="Mensaje de despedida del chatbot" formControlName="valor"
                      class="form-control" />
                    </div>
                  </div>
                  <div class="form-row" *ngIf="f.tipo.value == 'chatorder'" >
                    <div class="form-group col-md-12">
                        <label> {{'confguration.configuration.value' | translate}} </label>
                        <select class="form-control" formControlName="valor" >
                          <option  disabled value=null> {{'botAdmin.generic.selectop' | translate}} </option>
                          <option value="acciones"> Acciones </option>
                          <option value="opciones"> Opciones </option>
                        </select>
                    </div>
                  </div>
                  <div class="form-row" *ngIf="f.tipo.value == 'inactivityExpiration'" >
                    <div class="form-group col-md-12">
                        <label> {{'confguration.configuration.value' | translate}} </label>
                        <select class="form-control" formControlName="valor" >
                          <option  disabled value=null> {{'botAdmin.generic.selectop' | translate}} </option>
                          <option value="0">No</option>
                          <option value="15">15 min</option>
                          <option value="30">30 min</option>
                          <option value="60">1 h</option>
                        </select>
                    </div>
                  </div>

                  <div class="form-row" *ngIf="f.tipo.value == 'chatphotosize'" >
                    <div class="form-group col-md-12">
                        <label> {{'confguration.configuration.value' | translate}} </label>
                        <select class="form-control" formControlName="valor" >
                          <option disabled value=null> {{'botAdmin.generic.selectop' | translate}} </option>
                          <option value="1"> 1 </option>
                          <option value="2"> 2 </option>
                          <option value="3"> 3 </option>
                          <option value="4"> 4 </option>
                        </select>
                    </div>
                  </div>
                  <div class="form-row" *ngIf="f.tipo.value == 'language'" >
                    <div class="form-group col-md-12">
                        <label> {{'confguration.configuration.value' | translate}} </label>
                        <select class="form-control" formControlName="valor" >
                          <option  disabled value=null> {{'botAdmin.generic.selectop' | translate}} </option>
                          <option value="English"> English </option>
                          <option value="Español"> Español </option>
                        </select>
                    </div>
                  </div>
                  <div class="form-row" *ngIf="isLogo">
                      <div class="form-group col-md-6">
                          <label> {{'confguration.configuration.value' | translate}} </label>
                            <div>
                                <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)"
                                (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
                                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                                      <span>Drop files here</span>
                                    </ng-template>
                                </ngx-file-drop>
                                <div class="upload-table">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                            </tr>
                                        </thead>
                                        <tbody class="upload-name-style">
                                            <tr *ngFor="let item of files; let i=index">
                                                <td><strong>{{ item.relativePath }}</strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                      </div>
                      <div class="form-group col-md-6">
                        <button style="margin-top: 8%; margin-right: 0%;" type="button" class="btn btn-info" (click)="removeImage()">Remove File</button>
                      </div>
                  </div>
                  <div class="form-row" *ngIf="isProactiveNotification" >
                    <div class="form-group col-md-12">
                        <label> {{'confguration.configuration.value' | translate}} </label>
                        <input type="text" placeholder="Tiempo de espera para notificación proactiva" formControlName="valor"
                        class="form-control" />
                        <small style="color: red" *ngIf="this.actionsFormGroup.get('valor')?.value < 60 && this.actionsFormGroup.get('valor').touched">
                          El tiempo debe ser mayor o igual a 60 segundos.
                        </small>
                    </div>
                  </div>

              </div>
              <div class="modal-footer">
                  <button type="button" class="btn boton-cancelar" (click)="d('Close click')"> {{'confguration.configuration.cancel' | translate}} </button>
                  <button type="submit" class="btn boton-confirmar" (click)="save();"
                  [disabled]="isProactiveNotification && this.actionsFormGroup.get('valor')?.value < 60 && this.actionsFormGroup.get('valor').touched"
                  > {{'confguration.configuration.save' | translate}} </button>
              </div>
          </form>
      </ng-template>
  </div>
</div>
