<spinner></spinner>
<div *ngIf="notAllowed">
  <app-error404></app-error404>
</div>
<div *ngIf="!notAllowed">
  <div class="content-heading">
      <div>
          <h3> {{'confguration.user.user' | translate}} </h3>
          <h6 id="subtitle"> {{title | translate}} {{userName}} </h6>
      </div>
      <div class="ml-auto">
        <a class="btn btn-info col-sm-12 col-md-12 col-lg-12" 
        (mouseup)="redirect($event)"> {{'botAdmin.generic.back' | translate}} </a>
      </div>
  </div>
  <!-- Nav Tabs-->
  <ul class="nav nav-tabs" id="entityTab" role="tablist">
    <li class="nav-item">
      <a [ngClass]="{'nav-link':true, 'active': (activeTab == 1)}" id="basicinfo-tab" data-toggle="tab"
        href="#basicinfo" role="tab" aria-controls="basicinfo" aria-selected="true"
        (click)="changeTabs(1)">{{'confguration.user.usettab1' | translate}}</a>
    </li>
    <li class="nav-item">
      <a [ngClass]="{'nav-link':true, 'active': (activeTab == 2), 'disabled': (disTab)}" id="actions-tab"
        data-toggle="tab" href="#actions" role="tab" aria-controls="actions" aria-selected="true"
        (click)="changeTabs(2)">{{'confguration.user.usettab2' | translate}}</a>
    </li>
    <li class="nav-item">
      <a [ngClass]="{'nav-link':true, 'active': (activeTab == 3), 'disabled': (disTab)}" id="advanced-tab"
        data-toggle="tab" href="#advanced" role="tab" aria-controls="advanced" aria-selected="false"
        (click)="changeTabs(3)">{{'confguration.user.usettab3' | translate}}</a>
    </li>
  </ul>
  <!-- Tabs div -->
  <div class="tab-content" id="myTabContent">
    <!-- Basic Info Tab -->
    <div class="tab-pane fade show active" id="basicinfo" role="tabpanel" aria-labelledby="basicinfo-tab"
      *ngIf="activeTab == 1">
      <form [formGroup]="userCreateFormGroup">
        <div class="row">
          <div class="form-group col-md-4">
            <label for="user"> {{'confguration.user.usernameform' | translate}} </label>
            <input id="idInputUser" (change)="btnCreateONOFF()" type="text" class="form-control" maxlength="60" formControlName="user"
              [ngClass]="{ 'is-invalid': submitted && f.user.errors }">
            <div *ngIf="submitted && f.user.errors" class="invalid-feedback">
              <div *ngIf="f.user.errors.required"> {{'botAdmin.generic.required' | translate}} </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4">
            <label for="email"> {{'confguration.user.emailform' | translate}} </label>
            <input id="idInputEmail" (change)="btnCreateONOFF()" type="email" class="form-control" maxlength="60" formControlName="email"
              pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required"> {{'botAdmin.generic.required' | translate}} </div>
              <div *ngIf="f.email.errors?.pattern"> {{'confguration.user.emailerror' | translate}} </div>
            </div>
          </div>
        </div>
        <div class="row col-lg-4">

        <div>
          <label for="password"> {{'confguration.user.passwordchangeform' | translate}} </label>
        </div>
        <div class="ml-auto" >
          <button  class="btn btn-secondary col-sm-12 col-md-12 col-lg-12" (click)="generatePassword()">
              {{'confguration.user.generatePass' | translate}}
           </button>
        </div>
      </div>
        <!-- <div class="ml-auto">
          <a class="btn btn-primary col-sm-12 col-md-12 col-lg-12" (mouseup)="redirect($event)">
            {{'botAdmin.generic.back' | translate}} </a>
        </div>
        <div class="col-md-auto">
          <button class="mr-1 btn btn-labeled btn-secondary" type="button"  (click)="generatePassword()">
            <span class="btn-label"><i class="fa fa-arrow-left"></i>
       </span>Generar Contraseña</button> -->
        <!-- <div class="input-group mb-3">
          <button class="mr-1 btn btn-labeled btn-info mb-2" type="button" id="search2"
          (click)="generatePassword()">
              <i class="fa fa-search" id="search">Generar contraseña</i>
              </button>
          </div> -->
      <!-- </div> -->
        <div class="row">
          <div class="form-group col-md-4">
            <label for="password"> {{'confguration.user.passwordform' | translate}} </label>
            <div class="input-group">

              <input id="idInputPWD" (change)="btnCreateONOFF()"  [type]="fieldTextType ? 'text' : 'password'" class="form-control" (mousedown)="toggleFieldTextType()" formControlName="password"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
              <div class="input-group-append">
                <span class="input-group-text" (click)="toggleFieldTextType()">
                  <i class="fa" [ngClass]="{
                  'fa-eye-slash': !fieldTextType,
                  'fa-eye': fieldTextType
                }" ></i>
                </span>
              </div>
            </div>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required"> {{'botAdmin.generic.required' | translate}} </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4">
            <label for="repeatpassword"> {{'confguration.user.passwordrepform' | translate}} </label>
            <div class="input-group">

              <input id="idInputRptPWD" (change)="btnCreateONOFF()" [type]="repeatFieldTextType ? 'text' : 'password'" class="form-control"
                formControlName="repeatpassword" (mousedown)="toggleRepeatFieldTextType()" [ngClass]="{ 'is-invalid': submitted && f.repeatpassword.errors }">
              <div class="input-group-append">
                <span class="input-group-text" (click)="toggleRepeatFieldTextType()">
                  <i class="fa" [ngClass]="{
                    'fa-eye-slash': !repeatFieldTextType,
                    'fa-eye': repeatFieldTextType
                  }" ></i>
                </span>
              </div>
            </div>
            <div *ngIf="submitted && f.repeatpassword.errors" class="invalid-feedback">
              <div *ngIf="f.repeatpassword.errors.required"> {{'botAdmin.generic.required' | translate}} </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-1">
            <button id="btnCreateUser" disabled type="submit" class="btn btn-info" (click)="create()"> {{createform | translate}} </button>
          </div>
        </div>
      </form>
    </div>
    <!-- Actions Tab -->
    <div class="tab-pane fade show active" id="actions" role="tabpanel" aria-labelledby="actions-tab"
      *ngIf="activeTab == 2">
      <div class="row">
        <div class="col-md-8">
          <div class="header-modal"> {{'confguration.user.rolesform' | translate}} </div>
          <div class="p-2">
            <app-custom-listbox [source]="userRoles" key="id" display="normalizedName" [(destination)]="userRolesSelected"
              [format]="format" height="300px">
            </app-custom-listbox>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-1">
          <button type="submit" class="btn btn-info" (click)="updateRoles()"> {{'botAdmin.generic.update' | translate}} </button>
        </div>
      </div>
    </div>
    <!-- Advanced Tab -->
    <div class="tab-pane fade show active" id="advanced" role="tabpanel" aria-labelledby="advanced-tab"
      *ngIf="activeTab == 3">
      <div class="row justify-content-md-start">
        <div class="form-group col-md-6">
          <div class="card">
            <h4 class="card-header"> {{'botAdmin.generic.danger' | translate}} </h4>
            <hr id="hr2">
            <div class="card-body">
              <h4> {{'confguration.user.deleteuser' | translate}} </h4>
              <p class="card-text"> {{'confguration.user.deleteuser' | translate}} </p>
              <button type="submit" class="btn btn-info" (click)="checkUser()"> {{'confguration.user.deleteuser' | translate}} </button>
              <p class="card-text" *ngIf="showed" id="delete1"> {{'confguration.user.deleteuserp' | translate}} </p>
              <button type="submit" *ngIf="showed" class="btn btn-info" (click)="deleteUser()"> {{'botAdmin.generic.confirmdelete' | translate}} </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>