export class Colors
{
    public colors: string[];

    constructor()
    {
    }

    getColors(){
            return ["#CCFF33","#CCFF99","#CCFFCC","#CCFFFF","#FFFFCC","#FFFF99","#FFFF66",
            "#FFFF33","#FFFF00","#CCCC00","#CCCC33","#CCCC99","#CCCCCC","#CCCCFF","#FFCCFF",
            "#FFCCCC","#FFCC99","#FFCC66","#FFCC33","#FFCC00","#CC9900","#CC9933","#CC9966","#CC9999",
            "#CC99CC","#CC99FF","#FF99FF","#FF99CC","#FF9999","#FF9966","#FF9933","#FF9900"];
    }
}