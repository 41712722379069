import { Component, ViewEncapsulation, } from '@angular/core';
import { BotService } from '../../../app/services/botService';
import {Chart } from 'chart.js';
import { NoneIntentionsList } from '../../../app/entities/noneIntentionsList';
import { DateButton } from 'angular-bootstrap-datetimepicker';
import { Colors } from '../../../app/entities/colors';
import { AppConfigService } from '../../services/AppConfigService';
import { ThemesService } from 'src/app/core/themes/themes.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PanelComponent{
  result: NoneIntentionsList;
  selectedDate: Date = new Date() ;
  data: any[];  
  Linechart: any = []; 
  months: number [];
  notExistData: boolean;
  colorsChart: Colors = new Colors();
  constructor(private themeService: ThemesService, private botService: BotService) {
    this.themeService.createStyle();
    this.themeService.setTheme(localStorage.getItem('theme'));
  }

  selectFilter(dateButton: DateButton, viewName: string): boolean {
    return dateButton.value <= (new Date()).getTime();
  }

  selectDate(){
    this.getIntentions();
  }

  getIntentions()
  {
      this.botService.getNoneIntentionsByMonthAndYear(this.selectedDate.getMonth() + 1,this.selectedDate.getFullYear()).subscribe(rest => {
        this.result = rest;
        this.notExistData = false;
        if(rest.intentions.length > 0 )
        {
          this.Linechart = new Chart('canvas', {  
            type: 'pie',  
            data: { 
              labels: this.result.names,  
              datasets: [  
                {  
                  data:  this.result.percents, 
                  borderColor: '#3cb371',
                  backgroundColor: this.colorsChart.getColors(),
                  fill: true  
                }  
              ]  
            },  
            options: {  
              legend: {  
                display: true  
              },  
              scales: {  
                xAxes: [{  
                  display: false  
                }],  
                yAxes: [{  
                  display: false  
                }],  
              }  
            }  
        });
        }
        else
        {
          if(this.Linechart.data != undefined )
            this.Linechart.destroy();
          this.notExistData = true;
        }
      });    
  }
  
  ngOnInit() {
    this.notExistData = false;
    this.selectDate();
  }
  
}
