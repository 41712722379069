export class PaginationFilterExceptedWords {
    pageIndex: number;
    pageSize : number;
    exceptedWordId : number;
    description: string;
    typeExceptedWord: string;
    status : string;
    constructor() {
        this.pageIndex = 1;
        this.pageSize = 1;
        this.exceptedWordId = 0;
        this.description = "";
        this.typeExceptedWord = "all";
        this.status = "all";
    }
}