import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { BotService } from '../../../services/botService';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThemesService } from 'src/app/core/themes/themes.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    encapsulation: ViewEncapsulation.None
  })
  export class SearchComponent implements OnInit {
    placeholder: string = 'botAdmin.generic.selectrecord';
    notAllowed: boolean = false;
    readonly: boolean = false;
    ngOnInit() {}
    constructor(private themeService: ThemesService, private spinner: NgxSpinnerService, private botService: BotService, private translate: TranslateService, private toast: ToastrService)
    {
      this.themeService.createStyle();
      this.themeService.setTheme(localStorage.getItem('theme'));
      var userRoles = JSON.parse(localStorage.getItem('roles'));
      if (userRoles.indexOf('OpacAdmin') == -1 && userRoles.indexOf('GlobalAdmin') == -1 && userRoles.indexOf('ReadOnlyUser') == -1)
      this.notAllowed = true;
  
    if (userRoles.indexOf('ReadOnlyUser') == 0) 
      this.readonly = true;
    }
    data = [];
  
    search(texto: string){
        this.botService.GetAllSearchByText(texto)
        .subscribe((rest: any) => {
          if (rest.length > 0)
            this.data = rest;
          else {
            this.translate.stream('backendmessages.i800').subscribe(res => {
              this.toast.info(res);
            });
          }
        });
    }
  }