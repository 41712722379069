import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from '../../environments/environment';

 export const authConfig: AuthConfig = {
     
     // Url of the Identity Provider
     //(Overwritten in app-config.json)
     issuer: "",

     requireHttps: false,
     sessionChecksEnabled: true,

     // URL of the SPA to redirect the user to after login
     //(Overwritten in app-config.json)
     redirectUri: "",

     //(Overwritten in app-config.json)
     postLogoutRedirectUri: "",
     
     useSilentRefresh: true,
     timeoutFactor: 0.2,
     // URL of the SPA to redirect the user after silent refresh
     silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

     // The SPA's id. The SPA is registerd with this id at the auth-server
     //(Overwritten in app-config.json)
     clientId: "",
     // set the scope for the permissions the client should request
     // The first three are defined by OIDC. The 4th is a usecase-specific one
     //(Overwritten in app-config.json)
     scope: ""
     
 }




