export class Option{
    public optionId: number;
    public optionTitle:string;
    public isActived :boolean;
    public optionContent:string;
    public parentId:number;
    public isShowed:boolean;

    constructor() {
        this.isShowed = false;
    }
}