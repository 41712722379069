import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Intention } from '../../../entities/intention';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { startWith, delay, tap, map } from 'rxjs/operators';
import { ConversationDataSource } from 'src/app/dataSources/conversationDatasource';
import { BotService } from 'src/app/services/botService';
import { Observable } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { SharedService } from 'src/app/sharedService';
import { ConversationEntity } from 'src/app/entities/conversationEntity';
import { ThemesService } from 'src/app/core/themes/themes.service';

@Component({
  selector: 'app-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConversationsComponent implements OnInit {
  //Table variables
  columns = [];
  tableLimit = 15;
  tableShow: boolean = false;
  rowsFilter: any[] = [];
  expanded: any = {};
  timeout: any;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  dataSource: ConversationDataSource;
  displayedColumns: string[] = ['resultAnswer', 'isWelcome', 'intension', 'actions'];
  totalSize: number;
  sizeOptions = [15, 25, 50];
  temp: ConversationEntity[];
  answerValue: string;
  isWelcomeValue: string= "all";
  typeValue: string = "all";
  noData : Observable<boolean>;
  conversationsLenght: number = 0;
  collectionviewer: CollectionViewer;
  pageSize: number = 15;
  intentions: Intention[];
  notAllowed: boolean = false;
  readonly: boolean = false;
  constructor(private themeService: ThemesService, private urlService: SharedService, private router: Router, private spinner: NgxSpinnerService, private conversationService: BotService) {
    this.themeService.createStyle();
    this.themeService.setTheme(localStorage.getItem('theme'));
    var userRoles = JSON.parse(localStorage.getItem('roles'));
    if (userRoles.indexOf('OpacAdmin') == -1 && userRoles.indexOf('GlobalAdmin') == -1 && userRoles.indexOf('ReadOnlyUser') == -1)
    this.notAllowed = true;

  if (userRoles.indexOf('ReadOnlyUser') == 0)
    this.readonly = true;

    this.spinner.hide();
  }

  ngOnInit() {
    this.getIntentions();
    this.GetConversations();
  }

  getIntentions(){

    this.conversationService.GetRecognizer()
    .subscribe((rest: any) => {
      this.intentions = rest.assets.intents.filter(x => x.category != 'None');
    });
  }
  ngAfterViewInit() {
    this.paginator.page
        .pipe(
          startWith(null),
          delay(0),
          tap(() => this.loadPage())
        ).subscribe();
  }

  GetConversations(){
    this.dataSource = new ConversationDataSource(this.conversationService);
    this.dataSource.loadConversation(0, this.pageSize, this.answerValue, this.isWelcomeValue, this.typeValue);
    this.noData = this.dataSource.connect(this.collectionviewer).pipe(map(data => data.length === 0));
    this.conversationService.getAllPaginationConversationCount(0, this.pageSize, this.answerValue, this.isWelcomeValue, this.typeValue).subscribe(rest =>{
      this.conversationsLenght = rest;
    });
  }
  loadPage() {
    this.dataSource.loadConversation(
      this.paginator.pageIndex, this.pageSize, this.answerValue, this.isWelcomeValue, this.typeValue
      );
  }

  changePageSize(){
    this.paginator.pageSize = this.pageSize;
    this.paginator.pageIndex = 0;
    this.loadPage();
  }

  redirect(id: any,e: any) {
    this.urlService.navigateToViewElement('/management/conversations/crud', null, id, null, null, null, e.button, null);
  }
}


