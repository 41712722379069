import { Component, OnInit } from '@angular/core';
import { ThemesService } from 'src/app/core/themes/themes.service';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss']
})
export class Error404Component implements OnInit {

  constructor(private themeService: ThemesService, ) { 
    this.themeService.createStyle();
    this.themeService.setTheme(localStorage.getItem('theme'));
  }

  ngOnInit() {
  }

}
