<div class="item user-block" *ngIf="userBlockIsVisible()">
    <!-- User picture-->
    <div class="user-block-picture">
        <div class="user-block-status">
            <button *ngIf="user != null" type="button"
            
                class="btn btn-info btn-circle btn-xl"></button>
                
        </div>
    </div>
    <!-- Name and Job-->
    <div class="user-block-info">
        <span class="user-block-name"> {{user.mail}}</span>
    </div>
</div>