import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Option } from '../entities/option';
import { Action } from '../entities/action';
import { OptionsTree } from '../entities/optionsTree';
import { AppConfigService } from '../services/AppConfigService';
import { ActionsAndAction } from '../entities/actionsAndAction';
import { IntentionsAndAction } from '../entities/intentionsAndAction';
import { ActionsAndOption } from '../entities/actionsAndOption';
import { Configuration } from '../entities/configuration';
import { OptionsAndOptions } from '../entities/optionsAndOptions';
import { PaginationFilterActions } from '../entities/paginationFilterActions';
import { PaginationFilterConversation } from '../entities/paginationFilterConversation';
import { NoneIntentionsList } from '../entities/noneIntentionsList';
import { OAuthService } from 'angular-oauth2-oidc';
import { ConversationEntity } from '../entities/conversationEntity';
import { UserConfig } from '../entities/userConfig';
import { PaginationFilterLogs } from '../entities/paginationFilterLogs';
import { Log } from '../entities/log';
import { ExceptedWord } from '../entities/exceptedWord';
import { PaginationFilterExceptedWords } from '../entities/paginationFilterExceptedWords';

@Injectable()
export class BotService {

  apiServiceBot: string = this.environment.GetEnterpriseApi;
  pathController = '/api/v1/Administration/';
  pathController2 = '/api/v2/Administration/';
  pathControllerConversation = '/api/v1/Conversation/';
  pathControllerOptions = '/api/v1/Options/';
  pathControllerExceptedWord = '/api/v1/ExceptedWord/';
  
  constructor(private http: HttpClient, private environment: AppConfigService, private OAuth: OAuthService) { }

  getTreeOptions(): Observable<OptionsTree>{
    let pathMethod = 'GetTreeOptions';
    return this.http.get<OptionsTree>(this.apiServiceBot + this.pathController2 + pathMethod, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
    .pipe(
        catchError(this.handleError)
      );
  }

  GetActionById(id: number): Observable<any>{
    let pathMetodo = 'GetActionById/'+id;
    return this.http.get<any>(this.apiServiceBot + this.pathController + pathMetodo,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetConversationById(id: number): Observable<any>{
    let pathMetodo = 'GetConversationById/'+id;
    return this.http.get<any>(this.apiServiceBot + this.pathController + pathMetodo,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }
  GetOptionById(id: number): Observable<any>{
    let pathMetodo = 'GetOptionById/'+id;
    return this.http.get<any>(this.apiServiceBot + this.pathController + pathMetodo,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }
  getAllOptions(): Observable<Option[]> {
    let pathMethod = 'GetAllOptions';
    return this.http.get<Option[]>(this.apiServiceBot + this.pathController + pathMethod, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
       .pipe(
        catchError(this.handleError)
      );
  }

  VerifyDeleteActionById(id: number): Observable<any>{
    let pathMetodo = 'VerifyDeleteActionById/'+id;
    return this.http.get<any>(this.apiServiceBot + this.pathController + pathMetodo,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  VerifyDeleteOptionById(id: number): Observable<any>{
    let pathMetodo = 'VerifyDeleteOptionById/'+id;
    return this.http.get<any>(this.apiServiceBot + this.pathController + pathMetodo,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllCasualConversations(): Observable<ConversationEntity[]>{
    let pathMethod = 'GetAllCasualConversations';
    return this.http.get<ConversationEntity[]>(this.apiServiceBot + this.pathController + pathMethod, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
    .pipe(
      catchError(this.handleError)
    );
  }

  deleteOptionById(id:number): Observable<any> {
    let pathMethod = this.apiServiceBot + this.pathController + 'DeleteOptionById/'+ id;
    return this.http.delete<any>(pathMethod, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  createOption(opcion: Option): Observable<any> {
    let pathMethod = this.apiServiceBot + this.pathController + 'CreateOption';
    return this.http.post<any>(pathMethod,
      opcion, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  createCasualConversation(conversacion: ConversationEntity): Observable<number> {
    let pathMethod = this.apiServiceBot + this.pathController + 'CreateCasualConversation';
    return this.http.post<number>(pathMethod,
       conversacion, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteCasualConversationById(id: number): Observable<any> {
    let pathMetodo = 'DeleteCasualConversationById/';
    return this.http.delete<any>(this.apiServiceBot + this.pathController + pathMetodo + id, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  };


  VerifyDeleteConversationById(id: number): Observable<any>{
    let pathMetodo = 'VerifyDeleteConversationById/'+id;
    return this.http.get<any>(this.apiServiceBot + this.pathController + pathMetodo,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }


  GetRecognizer(): Observable<any> {
    let pathMetodo = this.apiServiceBot + this.pathController + 'GetRecognizer';
    return this.http.get<any>(pathMetodo, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetRecognizerJSON(texto: any): Observable<any> {
    let pathMetodo = this.apiServiceBot + this.pathController + "GetRecognizerJson?text={text}";
      texto = encodeURIComponent(texto)
   pathMetodo = pathMetodo.replace('{text}', texto);
    return this.http.get<any>(pathMetodo, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }
  GetAllSearchByText(texto: any): Observable<any> {
    let pathMetodo = this.apiServiceBot + this.pathController + 'GetAllSearchByText?text={text}';
    texto = encodeURIComponent(texto)
    pathMetodo = pathMetodo.replace('{text}', texto);
    return this.http.get<any>(pathMetodo, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }


  validateTextSpellCheck(texto: string): Observable<string> {
    let pathMetodo = this.apiServiceBot + this.pathController + 'ValidateTextSpellcheck?text={text}';
    texto = encodeURIComponent(texto)
   pathMetodo = pathMetodo.replace('{text}',texto);
    return this.http.get<string>(pathMetodo, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }
  EditOptionsToOptions(OptionAndOption: OptionsAndOptions) : Observable<boolean> {
    let pathMethod = this.apiServiceBot + this.pathController + 'EditOptionsToOptions';
    return this.http.put<boolean>(pathMethod, OptionAndOption, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  addActionsToAction(accionesYAccion: ActionsAndAction): Observable<boolean> {
    let pathMetodo = this.apiServiceBot + this.pathController + 'AddActionsToAction';
    return this.http.post<boolean>(pathMetodo, accionesYAccion, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }



  addActionsToOption(accionesYOpcion: ActionsAndOption): Observable<any> {
    let pathMetodo = this.apiServiceBot + this.pathController + 'AddActionsToOption';
    return this.http.post<any>(pathMetodo, accionesYOpcion, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  addIntentionsByActionId(intencionesYAccion: IntentionsAndAction): Observable<any> {
    let pathMetodo = this.apiServiceBot + this.pathController + 'AddIntentionsByActionId';
    return this.http.post<any>(pathMetodo, intencionesYAccion, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteOptionsByActionId(idAccion: number): Observable<boolean> {
    let pathMetodo = this.apiServiceBot + this.pathController + 'DeleteOptionsByActionId';
    return this.http.post<boolean>(pathMetodo, idAccion,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllActions(): Observable<Action[]> {
    let pathMetodo = 'GetAllActions';
    return this.http.get<Action[]>(this.apiServiceBot + this.pathController + pathMetodo, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }


  saveAction(action:Action): Observable<any> {

    let pathMetodo = 'CreateAction';
    return this.http.post<any>(this.apiServiceBot + this.pathController + pathMetodo,action, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  UpdateAction(action: Action): Observable<any>{
    let pathMetodo = 'UpdateAction';
    return this.http.put<any>(this.apiServiceBot + this.pathController + pathMetodo, action,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }


  CreateConversation(conversation:ConversationEntity): Observable<any> {
    let pathMetodo = 'CreateConversation';
    return this.http.post<any>(this.apiServiceBot + this.pathController + pathMetodo,conversation, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  UpdateConversation(conversation: ConversationEntity): Observable<any>{
    let pathMetodo = 'UpdateConversation';
    return this.http.put<any>(this.apiServiceBot + this.pathController + pathMetodo, conversation,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }


  UpdateOption(option: Option): Observable<any>{
    let pathMetodo = 'UpdateOption';
    return this.http.put<any>(this.apiServiceBot + this.pathController + pathMetodo, option,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }



  GetAllPaginationFilterActions(paginationFilter: PaginationFilterActions): Observable<Action[]> {
    let pathMetodo = 'GetAllPaginationFilterActions';
    return this.http.post<Action[]>(this.apiServiceBot + this.pathController + pathMetodo, paginationFilter , {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllPaginationActionsCount(paginationFilter: PaginationFilterActions): Observable<number> {
    let pathMetodo = 'GetCountPaginationActions';
    return this.http.post<number>(this.apiServiceBot + this.pathController + pathMetodo, paginationFilter,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteActionById(id: number): Observable<any> {
    let pathMetodo = 'DeleteActionById/';
    return this.http.delete<any>(this.apiServiceBot + this.pathController + pathMetodo + id, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  };
  createAction(accion: Action): Observable<boolean> {
    let pathMetodo = this.apiServiceBot + this.pathController + 'CreateAction';
    return this.http.post<boolean>(pathMetodo,
      accion, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  getActionRelationshipByActionId(accionId: number): Observable<Action[]> {
    let pathMetodo = 'GetActionRelationshipByActionId/';
    return this.http.get<Action[]>(this.apiServiceBot + this.pathController + pathMetodo + accionId, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  getActionOptionsByOptionId(opcionId: number): Observable<Action[]> {
    let pathMetodo = 'GetActionOptionsByOptionId/';
    return this.http.get<Action[]>(this.apiServiceBot + this.pathController + pathMetodo + opcionId, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  getActionOptionsByActionId(accionId: number): Observable<Option[]> {
    let pathMetodo = 'GetActionOptionsByActionId/';
    return this.http.get<Option[]>(this.apiServiceBot + this.pathController + pathMetodo + accionId, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  getIntentionsByActionId(accionId: number): Observable<string[]> {
    let pathMetodo = 'GetIntentionsByActionId/';
    return this.http.get<string[]>(this.apiServiceBot + this.pathController + pathMetodo + accionId, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  /*Services Excepte Word*/

  GetAllExceptedWords(type: string): Observable<ExceptedWord[]> {
    let pathMetodo = 'GetAllExceptedWords?type=' + type;
    return this.http.get<any>(this.apiServiceBot + this.pathControllerExceptedWord + pathMetodo,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetAllPaginationFilterExceptedWords(paginationFilter: PaginationFilterExceptedWords): Observable<ExceptedWord[]> {
    let pathMetodo = 'GetAllPaginationFilterExceptedWords';
    return this.http.post<ExceptedWord[]>(this.apiServiceBot + this.pathControllerExceptedWord + pathMetodo, paginationFilter , {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllPaginationExceptedWordsCount(paginationFilter: PaginationFilterExceptedWords): Observable<number> {
    let pathMetodo = 'GetCountPaginationExceptedWords';
    return this.http.post<number>(this.apiServiceBot + this.pathControllerExceptedWord + pathMetodo, paginationFilter,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetExceptedWordById(id: number): Observable<any>{
    let pathMetodo = 'GetExceptedWordById?id='+id;
    return this.http.get<any>(this.apiServiceBot + this.pathControllerExceptedWord + pathMetodo,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  CreateExceptedWord(word: ExceptedWord): Observable<any>{



    let pathMetodo = 'AddExceptedWords';
    return this.http.post<any>(this.apiServiceBot + this.pathControllerExceptedWord + pathMetodo, word,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  CreateListExceptedWords(words: ExceptedWord[]): Observable<any>{



    let pathMetodo = 'AddExceptedWords';
    return this.http.post<any>(this.apiServiceBot + this.pathControllerExceptedWord + pathMetodo, words,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  UpdateExceptedWord(word: ExceptedWord): Observable<any>{
    let pathMetodo = 'UpdateExceptedWord';
    return this.http.put<any>(this.apiServiceBot + this.pathControllerExceptedWord + pathMetodo, word,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  DeleteExceptedWord(id:number): Observable<any>{
    let pathMetodo = 'DeleteExceptedWord/' + id.toString();
    return this.http.delete<any>(this.apiServiceBot + this.pathControllerExceptedWord + pathMetodo, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "Accept": "application/json",
        'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
      })
    })
    .pipe(
      catchError(this.handleError)
    );
  }

  GetWordTypes(): Observable<string[]>{
    let pathMetodo = 'GetWordTypes';
    return this.http.get<any>(this.apiServiceBot + this.pathControllerExceptedWord + pathMetodo, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "Accept": "application/json",
        'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
      })
    })
    .pipe(
      catchError(this.handleError)
    );
  }

  /* Logs Methods */

  getAllLogs(conversationId: string): Observable<Log[]>{
    let pathMetodo = 'GetAllLogs/' + conversationId;
    return this.http.get<Log[]>(this.apiServiceBot + this.pathController + pathMetodo, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetAllLogsToExportToCSV(userID: string, fromDate: string, toDate: string): Observable<Log[]>{
    let pathMetodo = 'GetAllLogsToExportToCSV/' + userID + '/?FromDate=' + encodeURIComponent(fromDate)+ '&ToDate=' + encodeURIComponent(toDate);
    return this.http.get<Log[]>(this.apiServiceBot + this.pathController + pathMetodo, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetAllUserLogsToExportToCSV(fromDate: string, toDate: string): Observable<Log[]>{
    let pathMetodo = 'GetAllUserLogsToExportToCSV/?FromDate=' + encodeURIComponent(fromDate)+ '&ToDate=' + encodeURIComponent(toDate);
    return this.http.get<Log[]>(this.apiServiceBot + this.pathController + pathMetodo, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetAllUserLogs(): Observable<Log[]>{
    let pathMetodo = 'GetAllUserLogs';
    return this.http.get<Log[]>(this.apiServiceBot + this.pathController + pathMetodo , {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })

  })
      .pipe(
        catchError(this.handleError)
      );
  }


  getDatesConversation(userID: string, fromDate: string, toDate: string ): Observable<Log[]>{
    let pathMetodo = 'GetDatesConversation/' + userID + '/?FromDate=' + encodeURIComponent(fromDate)+ '&ToDate=' + encodeURIComponent(toDate);
    return this.http.get<Log[]>(this.apiServiceBot + this.pathController + pathMetodo , {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })

  })
      .pipe(
        catchError(this.handleError)
      );
  }


  getAllLogsAccess(): Observable<Log[]>{
    let pathMetodo = 'GetAllLogsAccess';
    return this.http.get<any>(this.apiServiceBot + this.pathController + pathMetodo, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  }, )
      .pipe(
        catchError(this.handleError)
      );
  }

  GetAllPaginationFilterLogs(paginationFilter: PaginationFilterLogs): Observable<Log[]> {
    let pathMetodo = 'GetAllPaginationFilterLogs';
    return this.http.post<Log[]>(this.apiServiceBot + this.pathController + pathMetodo, paginationFilter , {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllPaginationLogsCount(paginationFilter: PaginationFilterLogs): Observable<number> {
    let pathMetodo = 'GetCountPaginationLogs';
    return this.http.post<number>(this.apiServiceBot + this.pathController + pathMetodo, paginationFilter,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  /*****************************CONFIGURACIÓN****************************/
  getAllConfiguration(): Observable<Configuration[]> {
      let pathMetodo = 'GetAllConfiguration';
      return this.http.get<Configuration[]>(this.apiServiceBot + this.pathControllerConversation + pathMetodo,  {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          "Accept": "application/json",
          'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
        })
      })
      .pipe(
        catchError(this.handleError)
      );
  }

  get_All_Configuration(api_endpoint: string): Observable<Configuration[]>{
    let pathMetodo = 'GetAllConfiguration';
    return this.http.get<Configuration[]>(api_endpoint + this.pathControllerConversation + pathMetodo,  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "Accept": "application/json",
        'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
      })
    })
    .pipe(
      catchError(this.handleError)
    );
  }

  editConfiguration(configuracion: Configuration): Observable<boolean> {
    let pathMetodo = 'EditConfiguration/';
    return this.http.put<boolean>(this.apiServiceBot + this.pathControllerConversation + pathMetodo,
      configuracion,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetAllPaginationFilterConversation(pageIndex, pageSize,answerValue, welcomeValue, typeValue): Observable<ConversationEntity[]> {
    var paginationFilter = new PaginationFilterConversation();
    paginationFilter.answer = answerValue;
    paginationFilter.pageIndex = pageIndex;
    paginationFilter.pageSize = pageSize;
    paginationFilter.isWelcome = welcomeValue;
    paginationFilter.typeIntentions = typeValue;
    let pathMetodo = 'GetAllPaginationFilterConversation';
    return this.http.post<ConversationEntity[]>(this.apiServiceBot + this.pathControllerConversation + pathMetodo, paginationFilter , {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllPaginationConversationCount(pageIndex, pageSize, answerValue, welcomeValue, typeValue): Observable<number> {
    var paginationFilter = new PaginationFilterConversation();
    paginationFilter.answer = answerValue;
    paginationFilter.pageIndex = pageIndex;
    paginationFilter.pageSize = pageSize;
    paginationFilter.isWelcome = welcomeValue;
    paginationFilter.typeIntentions = typeValue;
    let pathMetodo = 'GetCountPaginationConversation';
    return this.http.post<number>(this.apiServiceBot + this.pathControllerConversation + pathMetodo, paginationFilter,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  getNoneIntentionsByMonthAndYear(month: number, year: number) {
    let pathMethod = this.apiServiceBot + this.pathController + 'GetAllIntentionsNoneByMonthAndYear/' + month +"/"+ year;
    return this.http.get<NoneIntentionsList>(pathMethod, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  get_NoneIntentionsByMonthAndYear(month: number, year: number, api_endpoint: string) {
    let pathMethod = api_endpoint + this.pathController + 'GetAllIntentionsNoneByMonthAndYear/' + month +"/"+ year;
    return this.http.get<NoneIntentionsList>(pathMethod, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }




  //Get for language
  GetUserConfigByUser(id: string){
    let pathMetodo = 'GetUserConfigByUser/'+id;
    return this.http.get<any>(this.apiServiceBot + this.pathControllerConversation + pathMetodo,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

    //Get for language
    Get_UserConfigByUser(id: string, api_endpoint: string){
      let pathMetodo = 'GetUserConfigByUser/'+id;
      return this.http.get<any>(api_endpoint + this.pathControllerConversation + pathMetodo,  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "Accept": "application/json",
        'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
      })
    })
        .pipe(
          catchError(this.handleError)
        );
    }

  SetUserConfigByUser(userConfig: UserConfig): Observable<any>{
    let pathMetodo = 'SetUserConfigByUser';
    return this.http.post(this.apiServiceBot + this.pathControllerConversation + pathMetodo, userConfig,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  //Métodos de Errores
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      //console.error('Ocurrió un error:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `código ${error.status}, ` +
      //   `mensaje: ${error.error}`);
    }
    // return an observable with a user-facing error message
    if(error.status == 400){
      return throwError(error.error);
    } else if(error.status == 500){
      return throwError(error.error);
    }else{
      return throwError(
        'Something bad happened, please try again.');
    }

  };
}
