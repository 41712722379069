import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { BotService } from '../../../../app/services/botService';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThemesService } from 'src/app/core/themes/themes.service';

@Component({
    selector: 'app-spellcheck',
    templateUrl: './spellcheck.component.html',
    encapsulation: ViewEncapsulation.None
  })
  export class SpellCheckComponent implements OnInit {
    json: Object;
    notAllowed: boolean = false;
    readonly: boolean = false;
    placeholder: string = 'botAdmin.generic.selecttext';
    hasData: boolean = false;
    ngOnInit() {
        json:['No ingreso texto'];
    }

    constructor(private themeService: ThemesService, private spinner: NgxSpinnerService, private botService: BotService) 
    {
      this.themeService.createStyle();
      this.themeService.setTheme(localStorage.getItem('theme'));
      var userRoles = JSON.parse(localStorage.getItem('roles'));
      if (userRoles.indexOf('OpacAdmin') == -1 && userRoles.indexOf('GlobalAdmin') == -1 && userRoles.indexOf('ReadOnlyUser') == -1)
      this.notAllowed = true;
  
    if (userRoles.indexOf('ReadOnlyUser') == 0) 
      this.readonly = true;
    }

    validateText(texto: string){
        this.botService.validateTextSpellCheck(texto)
        .subscribe(rest => {
            this.json = rest;
            this.hasData = true;
        });
    }
  }

