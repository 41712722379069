<spinner></spinner>
<div *ngIf="notAllowed">
  <app-error404></app-error404>
</div>
<div *ngIf="!notAllowed">
<div class="content-heading">
    <div>
        <h3> {{'spellcheck.title' | translate}} </h3>
        <h6 id="subtitle" > {{'spellcheck.subtitle' | translate}} </h6>
    </div>
</div>

<div class="row">
  <div class="col-md-4" style="padding-right: 0%;" >
    <div class="input-group mb-3">
      <input #texto type='text' class="form-control" placeholder="{{placeholder | translate}}" (keyup.enter)="validateText(texto.value)"/>
    </div>
  </div>

  <div class="col-md-auto">
    <div class="input-group mb-3">
    <button class="mr-1 btn btn-labeled btn-info mb-2" type="button" id="search2"
    (click)="validateText(texto.value)">
        <i class="fa fa-search" id="search"></i>
        </button>
    </div>
  </div>  
</div>

<body>
    <div class="container" *ngIf="hasData">
      <p style="font-size: 220%; color: orangered;">{{json}}</p>
    </div>
</body>
</div>
