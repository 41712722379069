import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppConfigService } from '../../../services/AppConfigService';
import { Configuration } from '../../../../app/entities/configuration';
import { BotService } from '../../../../app/services/botService';
import { ThemesService } from 'src/app/core/themes/themes.service';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';

//declare var BotChat;

declare global {
  interface Window {
    WebChat: any;
  }
}

window.WebChat = window.WebChat || {};
let styleOptions = {
  hideSendBox: false,
  hideUploadButton: false
};
const store = window.WebChat.createStore(
  {},
  ({ dispatch }) => next => action => {
      var elmnt = document.getElementById("content");
      //elmnt.scrollIntoView({block: "end"});
    
      return next(action);
  }
);
@Component({
  selector: 'app-bot',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatBotComponent implements OnInit {

  notAllowed: boolean = false;
  readonly: boolean = false;
  @ViewChild("botWindow") botWindowElement: ElementRef;
  
  btnTitle: string = "Reiniciar Conversación";

  constructor(private themeService: ThemesService, private OAuth: OAuthService, private environment: AppConfigService, private configurationService: BotService, private translate: TranslateService) { 
    this.themeService.createStyle();
    this.themeService.setTheme(localStorage.getItem('theme'));
    var userRoles = JSON.parse(localStorage.getItem('roles'));
    if (userRoles.indexOf('OpacAdmin') == -1 && userRoles.indexOf('GlobalAdmin') == -1 && userRoles.indexOf('ReadOnlyUser') == -1)
    this.notAllowed = true;

  if (userRoles.indexOf('ReadOnlyUser') == 0) 
    this.readonly = true;
    this.translate.stream('botAdmin.actionsabm.namecreate2').subscribe(res => {
    });
  }

  ngOnInit() { 
    //this.getDirectLineToken();
    //this.deleteClassName();
  }
  /*
  setFocus(): void {
    console.log("¡setteando focus a elemento!");
    document.getElementById('bot').focus();
  }
  */

  ngAfterViewInit(){
    this.getChatbot();
    //this.setFocus();
  }

  reloadChat(){
    window.location.reload();
  }

  getChatbot(){
    const directLine = window.WebChat.createDirectLine({
      token: this.environment.GetWebChat,
      webSocket: false
    });
    this.translate.stream('chat.restart').subscribe(res => {
     
    });

    directLine
        .postActivity({
        from: { id: "USER_ID", name: "USER_NAME", token: this.OAuth.getAccessToken()  },
        name: "requestWelcomeDialog",
        type: "event",
        value: "token"
      })
      .subscribe(rest=>{
        });
      window.WebChat.renderWebChat(
        {
          directLine: directLine,
          store,
          userID: "USER_ID",
          styleOptions: { hideUploadButton: true},
        },
        document.getElementById('bot')
      );
    this.configurationService.getAllConfiguration()
      .subscribe((rest: Configuration[]) => {
        var inputMessageChat = rest.filter(x => x.configurationId == "inputmessagechat");
        if (inputMessageChat[0].configurationValue == "false") {
          styleOptions.hideSendBox = true;        
          styleOptions.hideUploadButton = true;
        }
        else {
          styleOptions.hideSendBox = false;
          styleOptions.hideUploadButton = false;
        }
        window.WebChat.renderWebChat(
            {
              directLine: directLine,
              store,
              userID: "USER_ID",
              styleOptions: { hideUploadButton: true},
              
            },
            document.getElementById('bot')
          );
      }, () => { });
  }
  
}