import { Component, OnInit } from '@angular/core';
import { ConversationEntity } from '../../../entities/conversationEntity';
import { Router, ActivatedRoute } from '@angular/router';
import { Intention } from 'src/app/entities/intention';
import { BotService } from 'src/app/services/botService';
import { NgxSpinnerService } from 'ngx-spinner';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/sharedService';

@Component({
  selector: 'app-conversations-abm',
  templateUrl: './conversations-abm.component.html',
  styleUrls: ['./conversations-abm.component.scss']
})
export class ConversationsAbmComponent implements OnInit {

  // Entity Variables
  answerConversationId: number;
  conversationModel: ConversationEntity;
  //Form Variables
  title: string = "botAdmin.conversationabm.subtitle1";
  nametoedit: string = "";
  types: string[] = ["String", "Int", "Double", "DateTime", "Binary", "Regular Expression"];
  items: string[] = ["letters", "digits", "alphanumeric", "whitespace"];
  value: string[] = ["letters"];
  isString: boolean = false;
  isNumber: boolean = false;
  isDate: boolean = false;
  isRegEx: boolean = false;
  // Tab Variables
  activeTab: number = 1;
  disTab: boolean = true;
  showed: boolean = false;
  intentions: Intention[];
  selectedIntention: Intention;
  selectedIntentions: Intention[];
  intentionsList: any;
  deleteTrue: string;
  deleteFalse: string;
  statusConversation: boolean = false;
  statusWelcome: boolean = false;
  answer: string;
  conversationCreateFormGroup: UntypedFormGroup;
  conversationUpdateFormGroup: UntypedFormGroup;
  submitted: boolean;
  nameCreate: string = "botAdmin.generic.create";
  notAllowed: boolean = false;
  readonly: boolean = false;
  constructor(public urlService: SharedService, private router:Router, activatedRoute: ActivatedRoute, private services: BotService, private toast: ToastrService,
    private formBuilder: UntypedFormBuilder, private spinner: NgxSpinnerService, private translate: TranslateService) {
    var userRoles = JSON.parse(localStorage.getItem('roles'));
    if (userRoles.indexOf('OpacAdmin') == -1 && userRoles.indexOf('GlobalAdmin') == -1 && userRoles.indexOf('ReadOnlyUser') == -1)
    this.notAllowed = true;

  if (userRoles.indexOf('ReadOnlyUser') == 0)
    this.readonly = true;
    this.conversationModel = new ConversationEntity();
    this.conversationModel.isWelcome = false;

  }

  ngOnInit() {
      if(this.urlService.model.id != "0"){
        this.title = "botAdmin.conversationabm.subtitle2";
        this.nameCreate = "botAdmin.generic.update";
        this.disTab = false;
        this.answerConversationId = this.urlService.model.id;
        this.GetConversationById();
      }
    this.getFormCreate();
    this.getFormUpdate();
    this.getAllIntentions();
  }

  GetConversationById(){
    this.services.GetConversationById(this.answerConversationId).subscribe(x=>{
      this.nametoedit = x.resultAnswer;
      this.title = "botAdmin.conversationabm.subtitle2";
      this.statusWelcome = x.isWelcome;
      this.conversationCreateFormGroup.patchValue({
        answer: x.resultAnswer
      });
      this.conversationUpdateFormGroup.patchValue({
        intention: x.intension
      })
    })
  }

  getFormUpdate(){
    this.conversationUpdateFormGroup = this.formBuilder.group({
      intention: ['', Validators.required]
    });

  }

  getAllIntentions(){
    this.services.GetRecognizer()
    .subscribe((rest: any) => {
      this.intentions = rest.assets.intents.filter(x => x.category != 'None');
    });
  }

  getFormCreate(){
    this.conversationCreateFormGroup = this.formBuilder.group({
      answer: ['', Validators.required]
    });
  }

  get f() { return this.conversationCreateFormGroup.controls; }
  get fe() { return this.conversationUpdateFormGroup.controls; }

  mapToConversation(form: UntypedFormGroup): ConversationEntity {
    var conversation: ConversationEntity = {} as ConversationEntity;
    conversation.resultAnswer = form.get('answer').value;
    return conversation;
  }

  createModelConversation(): ConversationEntity{
    let conversation = this.mapToConversation(this.conversationCreateFormGroup);
    conversation.answerConversationId = this.answerConversationId;
    conversation.isWelcome = this.statusWelcome;
    conversation.intension = this.conversationUpdateFormGroup.get('intention').value;
    return conversation;
  }

  create(){
  this.spinner.show();
  let conversation = this.mapToConversation(this.conversationCreateFormGroup);
  this.submitted = true;

  if (this.conversationCreateFormGroup.invalid) {
    this.spinner.hide();
    return;
  }

  if(this.answerConversationId === undefined){
    this.createConversation(conversation);
    }else{
    let conversationUpdate = this.createModelConversation();
    this.updateConversation(conversationUpdate);
    this.spinner.hide();
    }
    this.submitted = false;
  }

  updateConversation(conversationUpdate: ConversationEntity){
    this.services.UpdateConversation(conversationUpdate).subscribe(x=>{
       this.spinner.hide();
       this.nametoedit = conversationUpdate.resultAnswer;
       this.title = "botAdmin.conversationabm.subtitle2";
       this.translate.stream('backendmessages.'+x.code).subscribe( res => {
        this.toast.success(res);
      });
     }, error=>{
      this.translate.stream('backendmessages.'+error.code).subscribe( res => {
        this.toast.error(res);
      });
       this.spinner.hide();
     })
  }

  createConversation(conversation: ConversationEntity){
    this.services.CreateConversation(conversation).subscribe(x=>{
      this.answerConversationId = x.value.id;
      this.urlService.model.id = x.value.id;
      this.urlService.setValue(this.urlService.model);
      this.nameCreate = "botAdmin.generic.update";
      this.nametoedit = conversation.resultAnswer;
       this.title = "botAdmin.conversationabm.subtitle2";
       this.disTab = false;
       this.translate.stream('backendmessages.'+x.value.code).subscribe( res => {
        this.toast.success(res);
      });
       this.spinner.hide();
       this.changeTabs(2);
     }, error=>{
      this.translate.stream('backendmessages.'+error.value.code).subscribe( res => {
        this.toast.error(res);
      });
       this.spinner.hide();
     })
  }


  update(){
    this.submitted = true;
    this.spinner.show();
    if (this.conversationUpdateFormGroup.invalid) {
      this.spinner.hide();
      return;
    }
    var conversation = this.createModelConversation();
    this.updateConversation(conversation);
    this.disTab = false;
    this.submitted = false;
  }

  UpdateConversation(conversation: ConversationEntity){
    this.services.UpdateConversation(conversation).subscribe(x=>{
      this.spinner.hide();
      this.translate.stream('backendmessages.'+x.code).subscribe( res => {
        this.toast.success(res);
        this.spinner.hide();
      });
     }, error=>{
      this.translate.stream('backendmessages.'+error.code).subscribe( res => {
        this.toast.error(res);
        this.spinner.hide();
      });
       this.spinner.hide();
     })
  }
  changeWelcome(){
    this.conversationUpdateFormGroup.get('intention').clearValidators();
    this.conversationUpdateFormGroup.get('intention').updateValueAndValidity();
    this.spinner.show();
    if(!this.statusWelcome){
      this.statusWelcome = true;
      this.conversationUpdateFormGroup.get('intention').setValue('');
    }
    else{
      this.statusWelcome = false;
      this.conversationUpdateFormGroup.get('intention').setValue('');
      this.conversationUpdateFormGroup.get('intention').setValidators([Validators.required]);
      this.conversationUpdateFormGroup.get('intention').updateValueAndValidity();
    }
    this.spinner.hide();
  }

  checkConversation(){
    this.spinner.show();
    this.verifyDelete();
  }

  verifyDelete(){
    this.services.VerifyDeleteConversationById(this.answerConversationId).subscribe(x=>{
      this.showed = true;
      this.translate.stream('backendmessages.'+x.code).subscribe( res => {
        this.deleteTrue = res + x.message;
      });
      this.spinner.hide();
    }, error=>{
      this.showed = false;
      this.translate.stream('backendmessages.'+error.code).subscribe( res => {
        this.deleteFalse = res + error.message;
      });
      this.spinner.hide();
    })
  }

  deleteConversation(){
    this.spinner.show();
     this.delete();
  }

  delete(){
    this.services.deleteCasualConversationById(this.answerConversationId).subscribe(x=>{
      this.translate.stream('backendmessages.'+x.value.code).subscribe( res => {
        this.toast.success(res);
      });
       this.spinner.hide();
       this.redirect(0);
     }, error=>{
       this.spinner.hide();
       this.translate.stream('backendmessages.'+error.value.code).subscribe( res => {
        this.toast.error(res);
      });
     });
  }
  changeTabs(tabN: number){
    this.activeTab = tabN;
  }

  redirect(e: any){
    this.urlService.navigateToViewElement('/management/conversations', null, null, null, null, null, e.button, null);
  }
}
