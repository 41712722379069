import { Component, OnInit } from '@angular/core';
import { Action } from '../../../entities/action';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { BotService } from '../../../services/botService';
import { ActionsAndAction } from '../../../entities/actionsAndAction';
import { IntentionsAndAction } from '../../../entities/intentionsAndAction';
import { Configuration } from '../../../entities/configuration';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationAux } from 'src/app/entities/configurationAux';
import { ThemesService } from 'src/app/core/themes/themes.service';
import * as _ from 'lodash';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {

  imageError: any = null;
  cardImageBase64: string = null;
  isImageSaved: boolean = false;
  displayedColumns: string[] = ['tipo', 'description', 'actions'];
  dataSource: MatTableDataSource<Configuration>;
  public tabActive: number = 1;
  actionsFormGroup: UntypedFormGroup;
  actionsRelFormGroup: UntypedFormGroup;
  intencionesRelFormGroup: UntypedFormGroup;
  modalRef: NgbModalRef;
  submitted = false;
  mailInput: string;
  nameInput: string;
  configuration: Configuration[];
  configurationModel: Configuration;
  modalConfiguration: string;
  reverse: boolean = false;
  tipos: any[];
  isLogo: boolean = false;
  key: string = 'Accion';
  usuerCurrentPage: number = 1;
  statusName: string;
  temp = [];
  columns = [];
  tableOffSet: number = 0;
  tableLimit = 25;
  rowsFilter: any[];

  intentions: string[];
  intentionsSelected: string[];

  actionsAssociate: Action[];
  actionsAssociateSelected: Action[];

  intentionsAndAction: IntentionsAndAction;
  actionsAndAction: ActionsAndAction;
  notAllowed: boolean = false;
  readonly: boolean = false;
  isBoolean: boolean = false;
  isProactiveNotification: boolean = false;

  isTakeActionsSearch: boolean;
  isSearchScore: boolean = false;
  requiredIndividualVote: boolean = false;
  requiredFinalVote: boolean = false;
  chatbotCloseMsg: boolean = false;
  welcomeCard: boolean = false;

  public files: NgxFileDropEntry[] = [];




  constructor(private themeService: ThemesService, private botService: BotService, private modal: NgbModal, private toast: ToastrService,
    private spinner: NgxSpinnerService, private formBuilder: UntypedFormBuilder, private translate: TranslateService) {
      this.themeService.createStyle();
      this.themeService.setTheme(localStorage.getItem('theme'));
      var userRoles = JSON.parse(localStorage.getItem('roles'));
      if (userRoles.indexOf('OpacAdmin') == -1 && userRoles.indexOf('GlobalAdmin') == -1 )
      this.notAllowed = true;


      this.columns = [
        { prop: 'configurationName', name: 'Tipo' },
        { prop: 'configurationValue', name: 'Valor' },
      ];
  }

  ngOnInit() {
    this.statusName = 'Activado';
    this.actionsFormGroup = this.formBuilder.group({
      id: [''],
      tipo: [''],
      valor: ['']
    });
    this.getAllConfiguration();
  }

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  getAllConfiguration(): void {
    this.rowsFilter = [];
    this.configuration = [];
    this.temp = [];
    this.spinner.show();
    this.botService.getAllConfiguration()
      .subscribe((rest: Configuration[]) => {
        this.configuration = rest;
        rest.forEach(conf => {
          let tempitem = {
            configurationId: conf.configurationId,
            configurationName: conf.configurationName,
            configurationValue: conf.configurationValue,
            descriptionSpanish : conf.descriptionSpanish,
            descriptionEnglish :  conf.descriptionEnglish
          }
        this.temp.push(tempitem);
        });
        this.temp = this.temp.filter(conf => conf.configurationId != "chathelperextanswer").sort(x=>x.configurationId)

        this.rowsFilter = rest;
        this.spinner.hide();
        this.dataSource = new MatTableDataSource(this.temp);
    }, () => { this.spinner.hide(); });
  }

  get f() { return this.actionsFormGroup.controls; }

  save() {
    this.spinner.show();

    this.submitted = true;
    this.editConfiguration(this.actionsFormGroup);
  }

  editConfiguration(actionForm: UntypedFormGroup) {
    let configuration = this.mapToAction(actionForm);
     this.botService.editConfiguration(configuration)
       .subscribe((rest: boolean) => {
         if (rest) {
          this.translate.stream('confguration.configuration.success').subscribe( res => {
            this.toast.success(res);
          });
          this.spinner.hide();
          this.getAllConfiguration();
          this.modalRef.close();
        } else {
          this.translate.stream('confguration.configuration.error').subscribe( res => {
            this.toast.error(res);
          });
          this.spinner.hide();
     }
       }, () => { this.spinner.hide(); });
  }


  mapToAction(form: UntypedFormGroup): Configuration {
    var configuration: Configuration = this.temp.find(x => x.configurationId == form.get("id").value);
    configuration.configurationId = form.get('id').value;
    if(this.isLogo)
      configuration.configurationValue = this.cardImageBase64;
    else
      configuration.configurationValue = form.get('valor').value;

    return configuration;
  }

  openModal(content, configuration: Configuration) {
    this.actionsFormGroup.reset();
    this.modalConfiguration = "Modificar " + configuration.configurationId
    this.submitted = false;
    if (configuration) {
      var config = this.temp.find(x => x.configurationId == configuration.configurationId);
      if(config.configurationId == 'Logo Grande' || config.configurationId == 'Logo Chico'){
        this.isLogo = true;
      }else{
        this.isLogo = false
      }
      if(config.configurationValue == 'true' || config.configurationValue == 'false')
        this.isBoolean = true;
      else
        this.isBoolean = false;

      if(config.configurationId == 'chatname')
        this.welcomeCard = true;
      else
        this.welcomeCard = false;

      if(config.configurationId == 'takeActionsSearch')
        this.isTakeActionsSearch = true;
      else
        this.isTakeActionsSearch = false;

      if(config.configurationId == 'searchscore')
        this.isSearchScore = true;
      else
        this.isSearchScore = false;

      if(config.configurationId == 'requiredIndividualVote')
        this.requiredIndividualVote = true;
      else
        this.requiredIndividualVote = false;

      if(config.configurationId == 'requiredFinalVote')
        this.requiredFinalVote = true;
      else
        this.requiredFinalVote = false;

      if(config.configurationId == 'closeMsg')
        this.chatbotCloseMsg = true;
      else
        this.chatbotCloseMsg = false;

      if(config.configurationId == 'TimeoutToSendProactiveNotification')
        this.isProactiveNotification = true;
      else
        this.isProactiveNotification = false;

      this.actionsFormGroup = this.formBuilder.group({
        id: [config.configurationId],
        tipo: [config.configurationName],
        valor: [config.configurationValue],
      });
      this.configurationModel = new Configuration();
      Object.assign(this.configurationModel, config);
    }
    this.modalRef = this.modal.open(content, { size: 'lg', backdrop: 'static' });
  }


  fileChangeEvent(fileInput: any) {
    this.imageError = null;
    if (fileInput) {
        // Size Filter Bytes
        const max_size = 20971520;
        const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
        const max_height = 15200;
        const max_width = 25600;

        if (fileInput.size > max_size) {
            this.imageError =
                'Maximum size allowed is ' + max_size / 1000 + 'Mb';

            return false;
        }

        if (!_.includes(allowed_types, fileInput.type)) {
            this.imageError = 'Only Images are allowed ( JPG | PNG )';
            return false;
        }
        const reader = new FileReader();
        reader.onload = (e: any) => {
            const image = new Image();
            image.src = e.target.result;
            image.onload = rs => {
                const img_height = rs.currentTarget['height'];
                const img_width = rs.currentTarget['width'];
                if (img_height > max_height && img_width > max_width) {
                    this.imageError =
                        'Maximum dimentions allowed ' +
                        max_height +
                        '*' +
                        max_width +
                        'px';
                    return false;
                } else {
                    const imgBase64Path = e.target.result;
                    this.cardImageBase64 = imgBase64Path;
                    this.isImageSaved = true;
                }
            };
        };

        reader.readAsDataURL(fileInput);
    }
  }
  removeImage() {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
    this.files = [];
}


  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.fileChangeEvent(file);
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  public fileOver(event){
    (event);
  }

  public fileLeave(event){
  }
}
