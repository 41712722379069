
<div *ngIf="notAllowed">
  <app-error404></app-error404>
</div>
<div *ngIf="!notAllowed">

  <div class="content-heading">
      <div>
          <h3> {{'botAdmin.process.title' | translate}} </h3>
          <h6 id="subtitle" > {{'botAdmin.process.subtitle' | translate}} </h6>
      </div>


  </div>
  <div class="row">
      <div class="col-md-auto">
          <div class="input-group mb-3">
              <div class="input-group-prepend">
              <span class="input-group-text" name="labelSize" > {{'botAdmin.generic.searchtitle' | translate}} </span>
              </div>
              <input type="text" (keyup.enter)="GetProcess()" [(ngModel)]="titleValue" class="form-control" id="basic-url" >
          </div>
      </div>
      <div class="col-md-auto">
          <div class="input-group mb-3">
              <div class="input-group-prepend" >
                <label class="input-group-text" name="labelSize" for="inputGroupSelect01"> {{'botAdmin.generic.status' | translate}} </label>
              </div>
              <select class="custom-select"  [(ngModel)]="statusValue" id="inputGroupSelect01">
                <option value="all"> {{'botAdmin.generic.statusOp1' | translate}} </option>
                <option value="true"> {{'botAdmin.generic.statusOp2' | translate}} </option>
                <option value="false"> {{'botAdmin.generic.statusOp3' | translate}} </option>
              </select>
          </div>
      </div>
       <div class="col-md-auto">
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" for="inputGroupSelect01" name="labelSize" > {{'botAdmin.generic.result' | translate}} </label>
              </div>
              <select class="custom-select" [(ngModel)]="pageSize" id="inputGroupSelect01" (click)="changePageSize()" >
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
          </div>
      </div>
      <div class="col-md-auto">
        <div class="input-group mb-3">
        <button class="mr-1 btn btn-labeled btn-info mb-2" type="button" id="search2"
        (click)="GetProcess()">
            <i class="fa fa-search" id="search"></i>
            </button>
        </div>
      </div>
    
  </div>
  <hr id="hr1">
  <div id="tableContainer">
      <div class="row">
          <div class="table-responsive">
            <div class="card-body">

              <div>

                <mat-table #table [dataSource]="dataSource" >

                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef > {{'botAdmin.process.name' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef > {{'botAdmin.process.area' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="type"> {{row.type}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef > {{'botAdmin.process.description' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="description"> {{row.description}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef > {{'botAdmin.process.status' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="status"> {{!row.status ? "Disable" : "Enable"}} </mat-cell>
                  </ng-container>
        
                  <ng-container matColumnDef="sapProcessid">
                    <mat-header-cell *matHeaderCellDef> {{'botAdmin.process.title' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="sapProcessid">
                        <a (mouseup)="redirect(row.sapProcessid, $event)" href="" class="btn btn-info" >  {{'botAdmin.generic.enter' | translate}} </a> 
                        </mat-cell>
                  </ng-container>
        
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"> </mat-row>

                </mat-table>
                <div *ngIf="noData | async" class="no-records">
                  {{'botAdmin.generic.notfound' | translate}} 
                </div>
                <mat-paginator [length]="processLenght" [pageSize]="pageSize" [hidePageSize]="true">  </mat-paginator>
              </div>
            </div>
          </div>
      </div>
  </div>
</div>