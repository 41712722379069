<spinner></spinner>

<div *ngIf="notAllowed">
  <app-error404></app-error404>
</div>
<div *ngIf="!notAllowed">

  <div class="content-heading">
    <div>
      <h3> {{'botAdmin.processABM.title' | translate}} </h3>
      <h6 id="subtitle"> {{'botAdmin.processABM.edit' | translate}} {{nameToEdit}} </h6>
    </div>
    <div class="ml-auto">
      <a class="btn btn-info col-sm-12 col-md-12 col-lg-12" (mouseup)="redirect($event)"> {{'botAdmin.generic.back' |
        translate}} </a>
    </div>
  </div>
  <!-- Nav Tabs-->
  <ul class="nav nav-tabs" id="entityTab" role="tablist">
    <li class="nav-item">
      <a [ngClass]="{'nav-link':true, 'active': (activeTab == 1)}" id="basicinfo-tab" data-toggle="tab"
        href="#basicinfo" role="tab" aria-controls="basicinfo" aria-selected="true" (click)="changeTabs(1)">
        {{'botAdmin.processABM.tab1' | translate}} </a>
    </li>
    <li class="nav-item">
      <a [ngClass]="{'nav-link':true, 'active': (activeTab == 2), 'disabled': (disTab)}" id="type-tab" data-toggle="tab"
        href="#type" role="tab" aria-controls="type" aria-selected="false" (click)="changeTabs(2)">
        {{'botAdmin.processABM.tab2' | translate}} </a>
    </li>

    <li class="nav-item">
      <a [ngClass]="{'nav-link':true, 'active': (activeTab == 4), 'disabled': (disTab)}" id="intentions-tab"
        data-toggle="tab" href="#intentions" role="tab" aria-controls="intentions" aria-selected="false"
        (click)="changeTabs(4)"> {{'botAdmin.processABM.tab4' | translate}} </a>
    </li>
    <li class="nav-item">
      <a [ngClass]="{'nav-link':true, 'active': (activeTab == 5), 'disabled': (disTab)}" id="intentions-tab"
        data-toggle="tab" href="#actions" role="tab" aria-controls="errors" aria-selected="false"
        (click)="changeTabs(5)"> {{'botAdmin.processABM.tab3' | translate}} </a>
    </li>
    <li class="nav-item">
      <a [ngClass]="{'nav-link':true, 'active': (activeTab == 6), 'disabled': (disTab)}" id="advanced-tab"
        data-toggle="tab" href="#advanced" role="tab" aria-controls="advanced" aria-selected="false"
        (click)="changeTabs(6)"> {{'botAdmin.processABM.tab5' | translate}} </a>
    </li>
  </ul>
  <!-- Tabs div -->
  <div class="tab-content" id="myTabContent">
    <!-- Basic Info Tab -->

    <div class="tab-pane fade show active" id="basicinfo" role="tabpanel" aria-labelledby="basicinfo-tab"
      *ngIf="activeTab == 1">
      <div id="disabledDiv">
        <form [formGroup]="processCreateFormGroup">
          <div class="row">
            <div class="form-group col-md-4">
              <label> {{'botAdmin.actionsabm.form1' | translate}} </label>
              <input type="text" formControlName="title" maxlength="60" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.title.errors }" [class.disabled]='readonly'>
              <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                <div *ngIf="f.title.errors.required"> {{'botAdmin.generic.required' | translate}} </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-1">
              <button type="submit" class="btn btn-info" (click)="updateName()" [class.disabled]='readonly'>{{nameCreate
                |
                translate}}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- Types Tab -->
    <div class="tab-pane fade show active" id="type" role="tabpanel" aria-labelledby="type-tab" *ngIf="activeTab == 2">
      <div class="control-panel">
        <div class="row">

          <form [formGroup]="fatherFormGroup" class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            [class.disabled]='readonly'>

            <div formArrayName="arrayFormGroup">

              <div class="float-right"> <a class="btn btn-info" (click)="openModal(actionModal)"><i
                    class="fas fa-info-circle"></i></a> </div>
              <div *ngFor="let control of arrayFormGroup.controls; index as i">

                <div [formGroup]="control">

                  <div class="" *ngIf="responseQuantity != null && i > 1 ">
                    <div class="form-group col-xs-11 col-sm-11 col-md-6 col-lg-6">
                      <div class="row">
                        <label> {{'botAdmin.processABM.step' | translate}}
                          {{arrayFormGroup.controls[i].controls.idProcessStep.value }} -</label>
                        <div *ngIf="arrayFormGroup.controls[i].controls.alternativeText.value != null" class="ml-1">
                          <label> {{'botAdmin.processABM.alternative' | translate}}
                            {{arrayFormGroup.controls[i].controls.alternativeText.value }} </label>
                        </div>
                        <div class="ml-1" *ngIf="arrayFormGroup.controls[i].controls.parameters.value > 0">
                          <label>- {{'botAdmin.processABM.parameters' | translate}}
                            {{arrayFormGroup.controls[i].controls.parameters.value}}</label>
                        </div>
                      </div>
                      <div class="">
                        <div> {{'botAdmin.processABM.response' | translate}}</div>

                        <div >
                          <div>
                            <md-editor [upload]="doUpload" [preRender]="preRenderFunc" [height]="'200px'" [mode]="mode"
                              [options]="markDownEditorOptions" required formControlName="response"
                              [formControl]="arrayFormGroup.controls[i].controls.response" [class.disabled]='readonly'>
                            </md-editor>
                            <control-messages [control]="fatherFormGroup.get('response')"></control-messages>
                          </div>
                          <div *ngIf="arrayFormGroup.controls[i].controls.paramDescription.value ">
                            <details>
                              <summary>{{'botAdmin.processABM.parametershelp' |
                                translate}}</summary>
                              <main [innerHtml]="arrayFormGroup.controls[i].controls.paramDescription.value "></main>
                            </details>
                          </div>
                        </div>

                      </div>

                    </div>

                  </div>

                  <div class="" *ngIf="responseQuantity == null && i > 0  ">
                    <div class="form-group col-xs-11 col-sm-11 col-md-6 col-lg-6">
                      <div class="row">
                        <label> {{'botAdmin.processABM.step' | translate}}
                          {{arrayFormGroup.controls[i].controls.idProcessStep.value }} -</label>
                        <div *ngIf="arrayFormGroup.controls[i].controls.alternativeText.value != null" class="ml-1">
                          <label> {{'botAdmin.processABM.alternative' | translate}}
                            {{arrayFormGroup.controls[i].controls.alternativeText.value }} </label>
                        </div>
                        <div class="ml-1" *ngIf="arrayFormGroup.controls[i].controls.parameters.value > 0">
                          <label>- {{'botAdmin.processABM.parameters' |
                            translate}}{{arrayFormGroup.controls[i].controls.parameters.value}}</label>
                        </div>
                      </div>
                      <div class="">
                        <div> {{'botAdmin.processABM.response' | translate}}</div>
                        <div >
                          <div>
                            <md-editor [upload]="doUpload" [preRender]="preRenderFunc" [height]="'200px'" [mode]="mode"
                              [options]="markDownEditorOptions" required formControlName="response"
                              [formControl]="arrayFormGroup.controls[i].controls.response" [class.disabled]='readonly'>
                            </md-editor>
                            <control-messages [control]="fatherFormGroup.get('response')"></control-messages>
                          </div>
                          <div *ngIf="arrayFormGroup.controls[i].controls.paramDescription.value ">
                            <details>
                              <summary>{{'botAdmin.processABM.parametershelp' |
                                translate}}</summary>
                              <main [innerHtml]="arrayFormGroup.controls[i].controls.paramDescription.value"></main>
                            </details>
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>


                  <div class="row" *ngIf="responseQuantity != null && i == 1 ">
                    <div class="form-group col-xs-11 col-sm-11 col-md-6 col-lg-6">
                      <label> {{'botAdmin.processABM.MultipleResponse' | translate}}</label>
                      <select class="form-control" (change)="OnOptionChange()"
                        [formControl]="arrayFormGroup.controls[i].controls.hasmultiple">
                        <option value="true">{{'botAdmin.processABM.yes' | translate}}</option>
                        <option value="false">{{'botAdmin.processABM.no' | translate}}</option>
                      </select>
                    </div>

                    <div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6" [hidden]="!isInputShow">
                      <label>{{'botAdmin.processABM.ResponseQuantity' | translate}}</label>
                      <input type="number" min="1" class="form-control w-100"
                        [attr.id]="'Paso ' + arrayFormGroup.controls[i].controls.idProcessStep.value"
                        [formControl]="arrayFormGroup.controls[i].controls.response" formControlName="response"
                        [class.disabled]='readonly' />
                      <div *ngIf="numberError">
                        <label style="color: red;">{{'botAdmin.processABM.quantitya' | translate}}</label>
                      </div>
                    </div>
                  </div>
                </div>



              </div>
              <div> <button type="submit" class="btn btn-info" (click)="updateSteps()"
                  [class.disabled]='readonly'>{{nameCreate |
                  translate}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Intentions Tab -->
    <div class="tab-pane fade show active" id="intentions" role="tabpanel" aria-labelledby="intentions-tab"
      *ngIf="activeTab == 4">
      <div class="row">
        <div class="col-md-8">
          <div class="header-modal"> {{'botAdmin.actionsabm.assoint' | translate}} </div>
          <div class="p-2">
            <app-custom-listbox [filter]="true" [source]="intentions" key="id" display="name"
              [(destination)]="selectedIntentions" [format]="format" height="300px" [class.disabled]='readonly'>
            </app-custom-listbox>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">
          <button type="submit" class="btn btn-info" (click)="updateIntentions()" [class.disabled]='readonly'>
            {{'botAdmin.generic.update' |
            translate}} </button>
        </div>
      </div>
    </div>
    <!-- Actions tab -->

    <div class="tab-pane fade show active" id="actions" role="tabpanel" aria-labelledby="actions-tab"
      *ngIf="activeTab == 5">
      <div class="row">
        <div class="col-md-8">
          <div class="header-modal">{{'botAdmin.optionabm.assoact' | translate}}</div>
          <div class="p-2">
            <app-custom-listbox [filter]="true" [source]="actionsAssociate" key="actionId" display="actionTitle"
              [(destination)]="selectedActions" [format]="format" height="300px" [class.disabled]="readonly">
            </app-custom-listbox>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-1">
          <button type="submit" class="btn btn-info" (click)="updateActions()"
            [class.disabled]="readonly">{{'botAdmin.generic.update' | translate}}</button>
        </div>
      </div>
    </div>


    <!-- Advanced Tab -->
    <div class="tab-pane fade show active" id="advanced" role="tabpanel" aria-labelledby="advanced-tab"
      *ngIf="activeTab == 6">
      <div class="row justify-content-md-start">
        <div class="form-group col-md-6">
          <div class="card">
            <h4 class="card-header"> {{'botAdmin.generic.changeStatus' | translate}} </h4>
            <hr id="hr1">
            <div class="card-body">
              <h4> {{'botAdmin.generic.statusAv' | translate}} {{statusProcess}}</h4>
              <p class="card-text"> {{'botAdmin.processABM.statusdesc' | translate}} </p>
              <button type="submit" class="btn btn-success" [disabled]="statusProcess" value="true"
                (click)="changeStatus()" id="enable" [class.disabled]='readonly'> {{'botAdmin.generic.enable' |
                translate}} </button>
              <button type="submit" class="btn btn-danger" [disabled]="!statusProcess" value="false"
                (click)="changeStatus()" id="disable" [class.disabled]='readonly'> {{'botAdmin.generic.disable' |
                translate}} </button>
            </div>
            <div class="card-body">
              <form [formGroup]="EndpointFormGroup" class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                [class.disabled]='readonly'>

                <div class="row">
                  <h4> {{'botAdmin.processABM.endpoint' | translate}} </h4>
                </div>
                <div class="row">
                  <p> {{'botAdmin.processABM.endpointdesc' | translate}} </p>
                </div>
                <div class="row mb-3">
                  <input type="text" formControlName="endpoint" class="form-control" />
                </div>
                <div class="row">
                  <button type="submit" class="btn btn-info" (click)="updateEndpoint()" [class.disabled]='readonly'>
                    {{'botAdmin.generic.update' |
                    translate}} </button>

                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="w-100"></div>
      </div>
    </div>
  </div>
  <div class="container">
    <ng-template #actionModal let-c="close" let-d="dismiss">
      <div class="modal-header">
        <h4 class="modal-title">{{'modal.title' | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <p>{{'modal.msg' | translate}}</p>
        <p>{{'modal.example' | translate}}</p>
        <img style="width: 80%;" src="../../../../assets/img/paramsExample.png">
      </div>

      <!--     <div class="modal-footer">
     
                <button type="submit" class="btn boton-confirmar" (click)="save();"> {{'confguration.configuration.save' | translate}} </button>
            </div> -->

    </ng-template>
  </div>
</div>