import { LayoutComponent } from '../layout/layout.component';
import { CallbackComponent } from '../auth/callback/callback.component';
import { AuthGuard } from '../auth/auth.guard';
import { PanelComponent } from './panel/panel.component';
import { Error403Component } from './error403/error403.component';
import { LogoutComponent } from './logout/logout.component';
import { ActionsComponent } from './botAdministration/actions/actions.component';
import { ActionsAbmComponent } from './botAdministration/actions-abm/actions-abm.component';
import { ConversationsComponent } from './botAdministration/conversations/conversations.component';
import { ConversationsAbmComponent } from './botAdministration/conversations-abm/conversations-abm.component';
import { ChatBotComponent } from './tools/chat/chat.component';
import { IntentionsSearchComponent } from './tools/intentions/intentions.component';
import { SpellCheckComponent } from './tools/spellcheck/spellcheck.component';
import { AssociateComponent } from './botAdministration/associate/associate.component';
import { UsersComponent } from './configuration/users/users.component';
import { ConfigurationComponent } from './configuration/configuration/configuration.component';
import { UsersAbmComponent } from './configuration/users-abm/users-abm.component';
import { AssociateAbmComponent } from './botAdministration/associate-abm/associate-abm.component';
import { SearchComponent } from './tools/search/search.component';
import { LogsComponent } from './tools/logs/logs.component';
import { ProcessComponent} from  './botAdministration/process/process.component';
import { ProcessAbmComponent} from './botAdministration/process-abm/process-abm.component';
import { ExceptedWordComponent } from './botAdministration/exceptedword/exceptedword.component';
import { ExceptedWordAbmComponent } from './botAdministration/exceptedword-abm/exceptedword-abm.component';
import { Routes } from "@angular/router";

export const routes: Routes = [

    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'panel', pathMatch: 'full' },
            { path: 'panel', canActivate: [AuthGuard], component: PanelComponent },
            { path: 'administration/users', canActivate: [AuthGuard], component: UsersComponent },
            { path: 'administration/users/crud', canActivate: [AuthGuard], component: UsersAbmComponent },
            { path: 'administration/configuration', canActivate: [AuthGuard], component: ConfigurationComponent },
            { path: 'management/options', canActivate: [AuthGuard], component: AssociateComponent },
            { path: 'management/options/crud', canActivate: [AuthGuard], component: AssociateAbmComponent },
            { path: 'management/conversations', canActivate: [AuthGuard], component: ConversationsComponent },
            { path: 'management/conversations/crud', canActivate: [AuthGuard], component: ConversationsAbmComponent },
            { path: 'management/actions/crud', canActivate: [AuthGuard], component: ActionsAbmComponent },
            { path: 'management/actions', canActivate: [AuthGuard], component: ActionsComponent },
            { path: 'management/process', canActivate: [AuthGuard], component: ProcessComponent },
            { path: 'management/process/crud', canActivate: [AuthGuard], component: ProcessAbmComponent },
            { path: 'tools/intentions', canActivate: [AuthGuard], component: IntentionsSearchComponent },
            { path: 'tools/search', canActivate: [AuthGuard], component: SearchComponent },
            { path: 'tools/spellcheck', canActivate: [AuthGuard], component: SpellCheckComponent },
            { path: 'management/exceptedword', canActivate: [AuthGuard], component: ExceptedWordComponent },
            { path: 'management/exceptedword/crud', canActivate: [AuthGuard], component: ExceptedWordAbmComponent },
            { path: 'tools/chat', canActivate: [AuthGuard], component: ChatBotComponent },
            { path: 'tools/logs', canActivate: [AuthGuard], component: LogsComponent },
        ]
    },
    { path: 'callback', component: CallbackComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'error', component: Error403Component },
    // Not found
    { path: '**', redirectTo: 'home' }

];
