import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Action } from '../../../entities/action';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Option } from 'src/app/entities/option';
import { ToastrService } from 'ngx-toastr';
import { BotService } from 'src/app/services/botService';
import { ActionsAndOption } from 'src/app/entities/actionsAndOption';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/sharedService';
import { ThemesService } from 'src/app/core/themes/themes.service';
import { SapService } from 'src/app/services/sapService';
import { sapProcesses } from 'src/app/entities/sapProcesses';
import { optionAndProcess } from 'src/app/entities/optionAndProcess';

@Component({
  selector: 'app-associate-abm',
  templateUrl: './associate-abm.component.html',
  styleUrls: ['./associate-abm.component.scss']
})
export class AssociateAbmComponent implements OnInit {

  // Tab Variables
  activeTab: number = 1;
  nametoedit: string = "";
  disTab: boolean = true;
  //Form Variables
  title: string = "botAdmin.optionabm.subtitle1";
  optionId: number = 0;
  // Dual List Variables
  actionsAssociate: Action[];
  optionCreateFormGroup: UntypedFormGroup;
  nameCreate: string = "botAdmin.generic.create";
  processAssociate : sapProcesses[];
  processSelected: sapProcesses[];
  processAndOption: optionAndProcess;
  actionsAssociateSelected: Action[];
  format = { all: 'All', none: 'None', add: 'Add', remove: 'Remove' };
  submitted: boolean;
  statusOption: boolean = false;
  parentId: any;
  actionsAndOption: ActionsAndOption;
  showed: boolean = false;
  deleteTrue: string;
  deleteFalse: string;
  isShowed: boolean;
  isParent: boolean;
  isParentBasic: boolean = false;
  isParentConfig: boolean;
  isParentAdvanced: boolean;
  notAllowed: boolean = false;
  readonly : boolean = false;

  constructor(private themeService: ThemesService, public urlService: SharedService, private router:Router, private optionService: BotService, private sapservice: SapService,activatedRoute: ActivatedRoute,
    private toast: ToastrService,private spinner: NgxSpinnerService, private formBuilder: UntypedFormBuilder, private translate: TranslateService) {
    this.themeService.createStyle();
    this.themeService.setTheme(localStorage.getItem('theme'));
    var userRoles = JSON.parse(localStorage.getItem('roles'));
    if (userRoles.indexOf('OpacAdmin') == -1 && userRoles.indexOf('GlobalAdmin') == -1 && userRoles.indexOf('ReadOnlyUser') == -1)
    this.notAllowed = true;

  if (userRoles.indexOf('ReadOnlyUser') == 0)
    this.readonly = true;

   }

  ngOnInit() {
    if(this.urlService.model.id == 0 && this.urlService.model.idParent == 0 && this.urlService.model.action == "new"){
      this.isParentConfig = false;
    }
    else if(this.urlService.model.id == 0 && this.urlService.model.idParent == 0 && this.urlService.model.action == "edit"){
      this.title = "botAdmin.optionabm.subtitle2";
      this.isParentBasic = true;
      this.isParentAdvanced = true;
      this.isParentConfig = false;
      this.disTab = false;
      this.getProcessByOptionId()
      this.getActionOptionsByOptionId();
      this.changeTabs(2);
    }
    else if(this.urlService.model.id != 0 &&  this.urlService.model.idParent != 0){
      this.optionId = this.urlService.model.id;
      this.parentId = this.urlService.model.idParent;
      this.urlService.model.showed == "true"? this.isShowed = true : this.isShowed = false;
      this.nameCreate = "botAdmin.generic.update";
      this.getProcessByOptionId()
      this.getActionOptionsByOptionId();
      this.getOptionById();
      this.isParentAdvanced = false;
      this.isParentConfig = false;
      this.disTab = false;
    }
    else if(this.urlService.model.id != 0 &&  this.urlService.model.idParent == 0){
      this.optionId = this.urlService.model.id;
      this.parentId = this.urlService.model.idParent;
      this.urlService.model.showed == "true"? this.isShowed = true : this.isShowed = false;
      this.nameCreate = "botAdmin.generic.update";
      this.getProcessByOptionId()
      this.getActionOptionsByOptionId();
      this.getOptionById();
      this.disTab = false;
    }
    else{
      this.isShowed = this.urlService.model.showed;
      this.parentId = this.urlService.model.idParent;
    }
    this.actionsAssociateSelected = [];
    this.processSelected = [];
    this.getFormCreate();
    this.actionsDualList();
    this.processDualList();
  }

  get f() { return this.optionCreateFormGroup.controls; }

  getOptionById(){
    this.optionService.GetOptionById(this.optionId).subscribe(x=>{
      this.nametoedit = x.optionTitle;
      this.nameCreate = "botAdmin.generic.update";
      this.title = "botAdmin.optionabm.subtitle2";
      this.optionCreateFormGroup.patchValue({
        name: x.optionTitle,
        details: x.optionContent
      });
      this.statusOption = x.isActived;
    });
  }

  getActionOptionsByOptionId(){
    this.optionService.getActionOptionsByOptionId(this.optionId)
    .subscribe((rest: Action[]) => {
      this.actionsAssociateSelected = rest;
    }, () => { });
  }

  getProcessByOptionId(){
    this.sapservice.getProcessByOptionId(this.optionId)
    .subscribe((rest: sapProcesses[]) => {
      this.processSelected = rest;
    }, () => { });
  }

  getFormCreate(){
    this.optionCreateFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      details: ['', Validators.required],
    });
  }

  mapToOption(form: UntypedFormGroup): Option {
    var option: Option = {} as Option;
    option.optionTitle = form.get('name').value;
    option.optionContent = form.get('details').value;
    option.parentId = this.parentId;
    option.optionId = this.optionId;
    return option;
  }

  create(){
    this.spinner.show();
    let option = this.mapToOption(this.optionCreateFormGroup);
    option.isActived = true;
    this.submitted = true;
    if (this.optionCreateFormGroup.invalid) {
      this.spinner.hide();
      return;
    }
    if(this.optionId === 0){
      this.createOption(option);
    }else{
      let optionUpdate = this.mapToOption(this.optionCreateFormGroup);
      this.updateOption(optionUpdate, false);
      this.title = "botAdmin.optionabm.subtitle2";
      this.nametoedit = optionUpdate.optionTitle;
    }
    this.submitted = false;
  }

  createOption(option:Option){
    this.optionService.createOption(option).subscribe(x=>{
       this.optionId = x.id;
       this.urlService.model.id = x.model;
       this.urlService.setValue(this.urlService.model);
       this.nameCreate = "botAdmin.generic.update";
       this.nametoedit = option.optionTitle;
       this.title = "botAdmin.optionabm.subtitle2";
       this.disTab = false;
       this.translate.stream('backendmessages.'+x.code).subscribe( res => {
        this.toast.success(res);
      });
       this.spinner.hide();
       this.changeTabs(2);
     }, error=>{
      this.translate.stream('backendmessages.'+error.code).subscribe( res => {
        this.spinner.hide();
        this.toast.error(res);
      });
     })
  }

  updateOption(optionUpdate: Option, changedStatus: boolean){
    this.optionService.UpdateOption(optionUpdate).subscribe(x=>{
      this.spinner.hide();
      this.translate.stream('backendmessages.'+x.code).subscribe( res => {
        this.toast.success(res);
      });
    }, error=>{
      this.translate.stream('backendmessages.'+error.code).subscribe( res => {
        this.spinner.hide();
        this.toast.error(res);
      });
      if(changedStatus)
        this.statusOption = !this.statusOption;
      this.spinner.hide();
    })
  }

  assignActionsToOption() {
    var option = new Option();
    option.parentId = this.parentId;
    option.optionId = this.optionId;
    option.isShowed =  this.isShowed;
    this.addActionsToOption(this.actionsAssociateSelected, option);
  }



  addActionsToOption(optionActions: Action[], option: Option) {
    this.actionsAndOption = {} as ActionsAndOption;
    this.actionsAndOption.actionsList = optionActions;
    this.actionsAndOption.option = option;
    this.add(this.actionsAndOption);
  }

  add(actionsAndOption: ActionsAndOption){
    this.spinner.show();
    this.optionService.addActionsToOption(actionsAndOption).subscribe((rest: boolean) => {
      this.spinner.hide();
      this.translate.stream('backendmessages.i203').subscribe( res => {
        this.toast.success(res);
      });
    }, error => {
      this.spinner.hide();
      this.translate.stream('backendmessages.i403').subscribe( res => {
        this.toast.error(res);
      });
    });
  }

  assignProcessToOption() {
    var option = new Option();
    option.parentId = this.parentId;
    option.optionId = this.optionId;
    option.isShowed =  this.isShowed;
    this.addProcessToOption(this.processSelected, option);
  }

  addProcessToOption(optionProcess: sapProcesses[], option: Option) {
    this.processAndOption = {} as optionAndProcess;
    this.processAndOption.processList = optionProcess;
    this.processAndOption.option = option;
    this.addProcess(this.processAndOption);
  }

  addProcess(optionAndProcess: optionAndProcess){
    this.spinner.show();
    this.sapservice.AddProcessToOption(optionAndProcess).subscribe((rest: boolean) => {
      this.spinner.hide();
      this.translate.stream('backendmessages.i203').subscribe( res => {
        this.toast.success(res);
      });
    }, error => {
      this.spinner.hide();
      this.translate.stream('backendmessages.i403').subscribe( res => {
        this.toast.error(res);
      });
    });
  }

  updateProcess(){
    this.assignProcessToOption();
  }

  update(){
    this.assignActionsToOption();
  }

  checkOption(){
    this.spinner.show();
    this.VerifyDeleteOptionById();
  }

  VerifyDeleteOptionById(){
    this.optionService.VerifyDeleteOptionById(this.optionId).subscribe(x=>{
      this.showed = true;
      this.translate.stream('backendmessages.' + x.code).subscribe( res => {
        this.deleteTrue = res + x.message;
      });
      this.spinner.hide();
    }, error=>{
      this.showed = false;
      this.translate.stream('backendmessages.' + error.code).subscribe( res => {
        this.deleteFalse = res + error.message;
      });
      this.spinner.hide();
    })
  }

  deleteOption(){
    this.spinner.show();
    this.deleteOptionById();
  }

  deleteOptionById(){
    this.optionService.deleteOptionById(this.optionId).subscribe(x=>{
      this.translate.stream('backendmessages.'+x.value.code).subscribe( res => {
        this.toast.success(res);
      });
      this.spinner.hide();
      this.redirect(0);
    }, error=>{
      this.spinner.hide();
      this.translate.stream('backendmessages.'+error.value.code).subscribe( res => {
        this.toast.error(res);
      });
    });
  }

  changeTabs(tabN: number){
    this.activeTab = tabN;
  }

  actionsDualList(){
    this.optionService.getAllActions()
    .subscribe((rest: Action[]) => {
        this.actionsAssociate = rest.filter(x => x.isActived);
    });
  }

  processDualList(){

    this.sapservice.GetAllProcess()
    .subscribe((rest: sapProcesses[]) => {
      this.processAssociate = rest.filter(x => x.status);
  });
  }

  redirect(e: any){
    this.urlService.navigateToViewElement('/management/options', null, null, null, null, null, e.button, null);
  }
}
