import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MenuService } from '../core/menu/menu.service';
import { SharedModule } from '../shared/shared.module';
import { menu } from './menu';
import { routes } from './routes';
import { Error404Component } from './error404/error404.component';
import { ProcessComponent } from './botAdministration/process/process.component';
import { ProcessAbmComponent } from './botAdministration/process-abm/process-abm.component';
import { ExceptedWordAbmComponent } from './botAdministration/exceptedword-abm/exceptedword-abm.component';


@NgModule({
    imports: [
        SharedModule,
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
    ],
    exports: [
        RouterModule
    ]
  //  declarations: [ProcessComponent, ProcessAbmComponent],
})

export class RoutesModule {
    menut = [];
    constructor(public menuService: MenuService) {
        menuService.addMenu(menu);
    }
}