import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IdentityUser } from '../../../entities/identityUser';
import { UserAndRoles } from '../../../entities/userAndRoles';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UsersAndRolesService } from 'src/app/services/usersAndRolesService';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { IdentityRole } from '../../../entities/identityRole';
import { SharedService } from 'src/app/sharedService';
import { ThemesService } from 'src/app/core/themes/themes.service';

@Component({
  selector: 'app-users-abm',
  templateUrl: './users-abm.component.html',
  styleUrls: ['./users-abm.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UsersAbmComponent implements OnInit {

  // Entity Variables
  Id: string;
  userModel: IdentityUser;
  userRoleModel: UserAndRoles;
  mail: string;
  // Tab Variables
  activeTab: number = 1;
  disTab: boolean = true;
  showed: boolean = false;
  title: string = "confguration.user.new";
  userName: string = "";
  createform: string = "botAdmin.generic.create";
  // Dual List Variables
  userRoles: IdentityRole[];
  userRolesSelected: IdentityRole[];
  format = { all: 'All', none: 'None', add: 'Add', remove: 'Remove' };
  intentions: string[];
  selectedIntentions: string[];
  //Forms variables
  userCreateFormGroup: UntypedFormGroup;
  userRolesFormGroup: UntypedFormGroup;
  submitted: boolean = false;
  notAllowed: boolean = false;
  fieldTextType: boolean;
  repeatFieldTextType: boolean;
  passwordLength = 10;
  includeLetters = true;
  includeNumbers = true;
  includeSymbols = true;
  constructor(public urlService: SharedService, private formBuilder: UntypedFormBuilder, private router:Router, private activatedRoute: ActivatedRoute,
    private toast: ToastrService, private spinner: NgxSpinnerService, private userService: UsersAndRolesService, 
    private themeService: ThemesService, private translate: TranslateService) {
    this.themeService.createStyle();
    this.themeService.setTheme(localStorage.getItem('theme'));
    var userRoles = JSON.parse(localStorage.getItem('roles'));
    if (userRoles.indexOf('GlobalAdmin') == -1 && userRoles.indexOf('SecurityAdmin') == -1)
      this.notAllowed = true;
    this.userModel = new IdentityUser();
    this.Id = this.urlService.model.id;
    if(this.Id != ''){
      this.GetUserById(this.Id);
      this.getRolesByUser();
    }
   }

  ngOnInit() {
    this.getFormCreate();
    this.userRolesSelected = [];
    this.rolesDualList();
  }

  btnCreateONOFF() {
    var pwd = <HTMLInputElement> document.getElementById("idInputPWD");
    var pwdRpt = <HTMLInputElement> document.getElementById("idInputRptPWD");
    var btnCreate = <HTMLButtonElement> document.getElementById("btnCreateUser");
    var user = <HTMLInputElement> document.getElementById("idInputUser");
    var email = <HTMLInputElement> document.getElementById("idInputEmail");
    if(pwd.value != '' && pwdRpt.value != '' && user.value != '' && email.value != ''){
      btnCreate.disabled = false;
    }else{
      btnCreate.disabled = true;
    }
  }

  GetUserById(id: string){
    this.userService.GetUserById(id).subscribe(res => {
      this.userModel = res;
      this.disTab = false;
      this.title = "confguration.user.edit";
      this.createform = "botAdmin.generic.update";
      this.userName = this.userModel.userName;
      this.userCreateFormGroup = this.formBuilder.group({
        user: [this.userModel.userName, Validators.required],
        password: [""],
        repeatpassword: [""],
        email: [this.userModel.email, Validators.required],
      });
    }, error => {
      this.translate.stream('backendmessages.i403').subscribe( res => {
        this.toast.error(res);
      });
    });
  }

  get f() { return this.userCreateFormGroup.controls; }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleRepeatFieldTextType() {
    this.repeatFieldTextType = !this.repeatFieldTextType;
  }
  getFormCreate(){
    this.userCreateFormGroup = this.formBuilder.group({
      user: [null, Validators.required],
      password: [null, Validators.required],
      repeatpassword: [null, Validators.required],
      email: [null, Validators.required],
    });
  }

  changeTabs(tabN: number){
    this.activeTab = tabN;
  }

  redirect(e: any){
    this.urlService.navigateToViewElement('/administration/users', null, null, null, null, null, e.button, null);
  }

  generatePassword() {
    const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz'
    const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const numbers = '0123456789'
    const symbols = '!@#$%^&*()_-,.'

    let availableCharacters = ''

    if (this.includeLetters) {
      availableCharacters += lowerCaseLetters
      availableCharacters += upperCaseLetters
    }

    if (this.includeNumbers) {
      availableCharacters += numbers
    }

    if (this.includeSymbols) {
      availableCharacters += symbols
    }

    availableCharacters.split('')
    const generatedPassword = []

    for (let i = 0; i < this.passwordLength; i += 1) {
      const max = availableCharacters.length
      const ran = Math.random()
      const idx = Math.floor(ran * (max + 1))

      generatedPassword.push(availableCharacters[idx])

    }
    this.userCreateFormGroup.controls["password"].setValue(generatedPassword.join(''));
    this.userCreateFormGroup.controls["repeatpassword"].setValue(generatedPassword.join(''));
    this.btnCreateONOFF();
  }

  mapToUser(){
    this.userModel.email = this.userCreateFormGroup.controls.email.value;
    this.userModel.userName = this.userCreateFormGroup.controls.user.value;
    if(this.userCreateFormGroup.controls.password.value == this.userCreateFormGroup.controls.repeatpassword.value){
      this.userModel.passwordHash = this.userCreateFormGroup.controls.password.value;
      if(this.userModel.passwordHash.length < 10 && this.userModel.passwordHash != ""){
        this.toast.error('error', 'Password most have at least 10 characters.');
        this.userCreateFormGroup.setErrors({ 'invalid': true });
      }
    } else {
      this.toast.error('error', 'Password and reset password most be equal.');
      this.userCreateFormGroup.setErrors({ 'invalid': true });
    }
    if(this.Id == ''){
      this.userModel.id = '';
    }
  }
  create(){ 
    this.spinner.show();
    this.mapToUser();
    this.submitted = true;
    if (this.userCreateFormGroup.invalid) {
      this.spinner.hide();
      return;
    }
    if(this.Id == ''){
      this.CreateUser();
    } else {
      this.UpdateUser();
    }
    this.submitted = false;
  }

  CreateUser(){
    this.userService.CreateUsers(this.userModel).subscribe(res => {
      this.Id = res.id;
      this.urlService.model.id = res.id;
      this.urlService.setValue(this.urlService.model);
      this.submitted = false;
      this.disTab = false;
      this.translate.stream('backendmessages.'+res.code).subscribe( res => {
        this.toast.success(res);
      });
      this.spinner.hide();
      this.GetUserById(this.Id);
      this.getRolesByUser();
    }, error => {
      this.translate.stream('backendmessages.'+error.code).subscribe( res => {
        this.toast.error(res);
      });
      this.spinner.hide();
    });
  }

  UpdateUser(){
    this.userService.UpdateUsers(this.userModel).subscribe(res => {
      this.Id = res.id;
      this.submitted = false;
      this.disTab = false;
      this.translate.stream('backendmessages.'+res.code).subscribe( res => {
        this.toast.success(res);
      });
      this.spinner.hide();
      this.GetUserById(this.Id);
      this.getRolesByUser();
    }, error => {
      this.translate.stream('backendmessages.'+error.code).subscribe( res => {
        this.toast.error(res);
      });
      this.spinner.hide();
    });
  }

  rolesDualList(){
    this.userService.GetAllRoles()
    .subscribe((rest: IdentityRole[]) => {
        this.userRoles = rest;
    });
  }

  getRolesByUser(){
    this.userService.GetRolesByUserId(this.Id)
    .subscribe((rest: IdentityRole[]) => {
      this.userRolesSelected = rest;
    });
  }

  updateRoles() {
    this.spinner.show();
    var userRoles = new UserAndRoles();
    userRoles.user = this.userModel;
    userRoles.rolesList = this.userRolesSelected;
    this.userService.AssignRolesToUser(userRoles).subscribe(rest =>{
      this.translate.stream('backendmessages.'+rest.code).subscribe( res => {
        this.toast.success(res);
      });
      this.spinner.hide();
    }, error =>{
      this.translate.stream('backendmessages.'+error.code).subscribe( res => {
        this.toast.error(res);
      });
      this.spinner.hide();
    })
  }

  checkUser(){
    this.showed = true;
  }
  deleteUser(){
    this.spinner.show();
    this.userService.DeleteUserById(this.Id).subscribe(res => {
      this.spinner.hide();
      this.translate.stream('backendmessages.'+res.code).subscribe( res => {
        this.toast.success(res);
      });
      this.redirect(0);
    }, error => {
      this.translate.stream('backendmessages.'+error.code).subscribe( res => {
        this.toast.error(res);
      });
      this.spinner.hide();
    })
  }

}