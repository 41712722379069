import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { BotService } from '../../../../app/services/botService';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { IActionMapping, TREE_ACTIONS, KEYS, TreeComponent } from 'angular-tree-component';
import { OptionsTree } from '../../../../app/entities/optionsTree';
import { Option } from '../../../../app/entities/option';
import { OptionsAndOptions } from '../../../../app/entities/optionsAndOptions';
import { SharedService } from 'src/app/sharedService';
import { ThemesService } from 'src/app/core/themes/themes.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

const actionMapping: IActionMapping = {

  mouse: {
    contextMenu: (tree, node, $event) => {
      $event.preventDefault();
    },
    dblClick: TREE_ACTIONS.TOGGLE_EXPANDED,
    click: (tree, node, $event) => {
      $event.shiftKey
        ? TREE_ACTIONS.TOGGLE_ACTIVE_MULTI(tree, node, $event)
        : TREE_ACTIONS.TOGGLE_ACTIVE(tree, node, $event);
    }
  },
  keys: {
    [KEYS.ENTER]: (tree, node, $event) => alert(`This is ${node.data.name}`)
  }
};

@Component({
  selector: 'app-associate',
  templateUrl: './associate.component.html',
  styleUrls: ['./associate.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AssociateComponent implements OnInit {

  @ViewChild('tree', {static: true}) tree: TreeComponent;
  optionsAndOptions: OptionsAndOptions;
  nodes: OptionsTree[];
  node: any;
  nodoSelected: any;
  options: Option[];
  public loadingCombo: boolean;
  // Custom options for treeview
  public customTemplateStringOptions = {
    isExpandedField: 'expanded',
    idField: 'id',
    actionMapping,
    loadingComponent: '',
    allowDrag: true
  };
  notAllowed: boolean = false;
  readonly: boolean = false; 

  constructor(private themeService: ThemesService, private urlService: SharedService, private router:Router, private route: ActivatedRoute, private toast: ToastrService,
    private botService: BotService, private spinner: NgxSpinnerService, private translate: TranslateService) {
    this.themeService.createStyle();
    this.themeService.setTheme(localStorage.getItem('theme'));
    var userRoles = JSON.parse(localStorage.getItem('roles'));
    this.spinner.show();
    if (userRoles.indexOf('OpacAdmin') == -1 && userRoles.indexOf('GlobalAdmin') == -1 && userRoles.indexOf('ReadOnlyUser') == -1)
    this.notAllowed = true;

  if (userRoles.indexOf('ReadOnlyUser') == 0) {
    this.readonly = true;

  }
   }

  ngOnInit() {
    this.loadingCombo = false;
    this.nodes = [];
    this.node = [];
    this.getTree();
  }

  onMoveNode($event) {
    this.spinner.show();
    this.optionsAndOptions = {} as OptionsAndOptions;
    this.optionsAndOptions.childrenId = $event.node.id;
    this.optionsAndOptions.newParentId = $event.to.parent.id;
    this.optionsAndOptions.optionAndOptionIndexId = $event.to.index;
    this.optionsAndOptions.parentId = $event.from.parent.id;
    
    this.botService.getActionOptionsByOptionId($event.to.parent.id).subscribe((rest: any) => {
        this.spinner.hide();
        this.botService.EditOptionsToOptions(this.optionsAndOptions).subscribe((rest: boolean) => {
          this.spinner.hide();
          this.translate.stream('backendmessages.i203').subscribe( backendmessages => {
            this.toast.success(backendmessages);
          });
        }, () => { });
    }, error => {
      this.translate.stream('backendmessages.i900').subscribe( backendmessages => {
        this.toast.error(backendmessages);
      });
      this.ngOnInit();
    });
  }

  getTree() {
    this.nodes = [];
    this.node = [];
    this.tree.treeModel.nodes = this.nodes;
    this.getTreeOptions();
  }

  getTreeOptions(){
    this.botService.getTreeOptions()
    .subscribe((rest: OptionsTree) => {
      if (rest.name != null) {
        this.node.push(rest);
        this.nodes = this.node;
        this.tree.treeModel.nodes = this.nodes;
        this.tree.treeModel.update();
        const someNode = this.tree.treeModel.getNodeById(rest.id);
        someNode.expand();
        this.spinner.hide();
      }
    }, error=>{        this.spinner.hide();    });
  }

  redirect(id: number,parentId: number,isShowed: boolean, action: string,e: any) {
    this.urlService.navigateToViewElement('/management/options/crud', parentId, id, isShowed, action, null, e.button, null);
  }

}