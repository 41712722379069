<spinner></spinner>
<div *ngIf="notAllowed">
  <app-error404></app-error404>
</div>
<div *ngIf="!notAllowed">

  <div class="content">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-5">
        <h3> {{'exceptedWord.title' | translate}} </h3>
        <h6 id="subtitle" > {{'exceptedWord.subtitle' | translate}} </h6>
      </div>

      <div class="col-12 col-md-12 col-lg-7">
        <div class="d-lg-flex justify-content-end">
          
          <a class="mr-1 btn btn-labeled btn-info mb-2" href=""
          (mouseup)=" redirect(0, $event, 'create')" [class.disabled]='readonly' >
            <span class="btn-label"><i class="fa fa-plus"></i>
            </span> {{'exceptedWordABM.newWord' | translate}} </a>

          <a class="mr-1 btn btn-labeled btn-info mb-2" href=""
          (mouseup)=" redirect(0, $event,'archive')" [class.disabled]='readonly' >
            <span class="btn-label"><i class="fas fa-upload"></i>
            </span>{{'exceptedWordABM.archive' | translate}}</a>

          <a class="mr-1 btn btn-labeled btn-info mb-2" [routerLink]=""
          (mouseup)="openModalDownload(downloadModal)" [class.disabled]='readonly' >
            <span class="btn-label"><i class="fas fa-download"></i>
            </span> {{'exceptedWordABM.download' | translate}} </a>
        </div>
        
      </div>
    </div>
  </div>

  <hr id="hr1">
  
  <div class="row">
      <div class="col-md-auto">
          <div class="input-group mb-3">
              <div class="input-group-prepend">
              <span class="input-group-text" name="labelSize" > {{'exceptedWordABM.searchText' | translate}} </span>
              </div>
              <input type="text" (keyup.enter)="GetExceptedWords()" [(ngModel)]="descriptionValue" class="form-control" id="basic-url" >
          </div>
      </div>
      <div class="col-md-auto">
          <div class="input-group mb-3">
              <div class="input-group-prepend" >
                <label class="input-group-text" name="labelSize" for="inputGroupSelect01"> {{'exceptedWordABM.service' | translate}} </label>
              </div>
              <select class="custom-select"  [(ngModel)]="typeValue" id="inputGroupSelect01">
                <option value="all"> {{'exceptedWordABM.serviceType.all' | translate}} </option>
                <option value="SEARCH"> SEARCH </option>
                <option value="SPELLCHECK"> SPELLCHECK </option>
              </select>
          </div>
      </div>

      <div class="col-md-auto">
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" for="inputGroupSelect01" name="labelSize" > {{'botAdmin.generic.result' | translate}} </label>
              </div>
              <select class="custom-select" [(ngModel)]="pageSize" id="inputGroupSelect01" (click)="changePageSize()" >
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
          </div>
      </div>
      <div class="col-md-auto">
        <div class="input-group mb-3">
          <button class="mr-1 btn btn-labeled btn-info mb-2" type="button" id="search2" (click)="GetExceptedWords()">
              <i class="fa fa-search" id="search"></i>
          </button>
        </div>
      </div>
    
  </div>
  <hr id="hr1">
  <div id="tableContainer">
      <div class="row">
          <div class="table-responsive">
            <div class="card-body">
              <div>
                <mat-table #table [dataSource]="dataSource">

                  <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef > {{'exceptedWordABM.table1' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.description}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef > {{'exceptedWordABM.table2' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="type"> {{row.type}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="exceptedWordId">
                    <mat-header-cell *matHeaderCellDef> {{'exceptedWordABM.table3' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="actions">
                      <button (mouseup)="redirect(row.exceptedWordId, $event, 'update')" class="mr-1 btn btn-labeled btn-info" type="button" title="{{'exceptedWordABM.update' | translate}}" id="search2">
                        <i class="fas fa-pencil-alt" id="search"></i>
                      </button>
                      <button (mouseup)="openModal(row.exceptedWordId, row.description ,actionModal)" class="mr-1 btn btn-labeled btn-info" type="button" title="{{'exceptedWordABM.delete' | translate}}" id="search2">
                        <i class="fas fa-trash-alt" id="search"></i>
                      </button>
                    </mat-cell>
                  </ng-container>
        
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"> </mat-row>

                </mat-table>
                <div *ngIf="noData | async" class="no-records">
                  {{'botAdmin.generic.notfound' | translate}}
                </div>
                <mat-paginator [length]="exceptedWordLenght" [pageSize]="pageSize" [hidePageSize]="true">  </mat-paginator>
              </div>
            </div>
          </div>
      </div>
  </div>

  <div class="container">
    <ng-template #actionModal let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">{{'botAdmin.modalWordExcepted.title' | translate}} <b>{{descriptionWordToDelete}}</b></h4>
            <button type="button" class="close" aria-label="Close" (click)="d('')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
      
        <div class="modal-body">
          <p>{{'botAdmin.modalWordExcepted.msg' | translate}}</p>
        </div>
    
        <div class="modal-footer">
          <input type="hidden" [(ngModel)]="idWordToDelete">
          <button type="submit" class="btn btn-info boton-confirmar" (click)="deleteExceptedWord();"> {{'botAdmin.modalWordExcepted.button' | translate}} </button>
        </div>
    
    </ng-template>

    <ng-template #downloadModal let-c="close" let-d="dismiss">
      <div class="modal-header">
          <h4 class="modal-title">{{'botAdmin.modalWordDownload.title' | translate}} <b>{{descriptionWordToDelete}}</b></h4>
          <button type="button" class="close" aria-label="Close" (click)="d('')">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
    
      <div class="modal-body">
        <div class="col-md-auto">
          <div class="input-group mb-3">
              <div class="input-group-prepend" >
                <label class="input-group-text" name="labelSize" for="inputGroupSelect01"> {{'exceptedWordABM.service' | translate}} </label>
              </div>
              <select class="custom-select"  [(ngModel)]="typeValueModal" >
                <option value="all"> {{'exceptedWordABM.serviceType.all' | translate}} </option>
                <option value="SEARCH"> SEARCH </option>
                <option value="SPELLCHECK"> SPELLCHECK </option>
              </select>
          </div>
        </div>
      </div>
  
      <div class="modal-footer">
        <button type="button" class="btn btn-info boton-confirmar" (click)="DownLoadExceptedWords();"> {{'botAdmin.modalWordDownload.button' | translate}} </button>
      </div>
  
    </ng-template>

  </div>

</div>
