import { DataSource } from '@angular/cdk/table';
import { IdentityUser } from '../entities/identityUser';
import { catchError, finalize } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { UsersAndRolesService } from '../services/usersAndRolesService';

export class UserDataSource implements DataSource<IdentityUser> {

    private entitySubject = new BehaviorSubject<IdentityUser[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private usersService: UsersAndRolesService) {}

    connect(collectionViewer: CollectionViewer): Observable<IdentityUser[]> {
        return this.entitySubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.entitySubject.complete();
        this.loadingSubject.complete();
    }

    loadUsers(pageIndex, pageSize, nameValue, emailValue) {

        this.loadingSubject.next(true);

        this.usersService.GetAllPaginationFilterUsers(pageIndex, pageSize, nameValue, emailValue).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(entity => this.entitySubject.next(entity));
    }    
}