import { CollectionViewer } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { delay, map, startWith, tap } from 'rxjs/operators';
import { ThemesService } from 'src/app/core/themes/themes.service';
import { LogDataSource } from 'src/app/dataSources/logsDataSource';
import { PaginationFilterLogs } from 'src/app/entities/paginationFilterLogs';
import { BotService } from 'src/app/services/botService';
import { SharedService } from 'src/app/sharedService';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocomplete } from '@angular/material/autocomplete'
import { Log } from 'src/app/entities/log';

import { ColorsService } from '../../../shared/colors/colors.service';
import { FormControl } from '@angular/forms';
import { CustomDatePipe } from '../../../core/pipes/custom-date.pipe';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})

export class LogsComponent implements OnInit {
  selectedConversationID: any;
  logModel: Log[] = new Array;
  logDatesConversationID: Log[] = new Array;
  dataValidation: boolean = false;

  fromDateValue: Date;
  toDateValue: Date;
  userIDValue: string;
  notAllowed: boolean = false;
  userList: Array<{ index: string, name: string }> = [];
  keyword: string;
  loading: boolean = false;
  maxDate: Date = new Date(Date.now());
  textTooltipSearch: string = "";
  textTooltipExport: string = "";

  constructor(public colors: ColorsService, private themeService: ThemesService, private urlService: SharedService, private router: Router,
    private logService: BotService, private spinner: NgxSpinnerService, private toast: ToastrService, private translate: TranslateService) {
    this.themeService.createStyle();
    this.themeService.setTheme(localStorage.getItem('theme'));
    var userRoles = JSON.parse(localStorage.getItem('roles'));
    if (userRoles.indexOf('OpacAdmin') == -1 && userRoles.indexOf('GlobalAdmin') == -1)
      this.notAllowed = true;
    this.spinner.hide();
    this.defaultDates();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.getUsers();
    this.setUserNull();
    this.searchOnOff(false);
    this.legendFunction(false);
    var input = <HTMLInputElement>document.getElementsByClassName("input-container").item(0).childNodes.item(0);
    input.style.setProperty("width", "90%");
    this.VisibleTooltipSearch(true);
    this.VisibleTooltipExport(false);
  }

  GetLogs() {
    this.clearModel();
    this.getDates();
  }

  searchOnOff(users: boolean) {
    var searchButtonElement = <HTMLInputElement>document.getElementById("search2");
    users ? searchButtonElement.disabled = false : searchButtonElement.disabled = true;
    users ? this.VisibleTooltipSearch(false) : this.VisibleTooltipSearch(true);
    this.exportarOnOff();
  }

  setUserNull() {
    this.userIDValue = "";
    this.searchOnOff(false);
    this.exportarOnOff();
  }

  defaultDates() {
    var date = new Date(Date.now());
    var aux = new Date(date);
    aux.setDate(aux.getDate() - 30);
    var newDate = new Date(aux);
    this.fromDateValue = newDate;
    this.toDateValue = (new Date(Date.now()));
  }

  clearModel() {
    this.logModel.length = 0;
  }

  getAllLogs() {
    this.clearModel();
    this.spinner.show();
    this.loading = true;
    this.logService.getAllLogs(this.selectedConversationID).subscribe(
      res => {
        res.forEach(conversation => {
          this.logModel.push(conversation);
        });
        this.spinner.hide();
        this.loading = false;
      },
      error => {

      }
    )
  }

  getUsers() {
    this.userList.length = 0;
    this.keyword = 'name';
    this.logService.GetAllUserLogs().subscribe((user) => {
      for (var i in user) {
        var item = user[i];
        this.userList.push({
          'index': i,
          "name": item.userId
        })
      }
    }, error => {

    });

  }

  getDates() {
    this.exportarOnOff();
    this.selectedConversationID = "";
    this.logDatesConversationID.length = 0;
    let aux = new Date(Date.now());
    if (this.fromDateValue > this.toDateValue) {
      this.toDateValue = this.fromDateValue;
    }
    this.logService.getDatesConversation(this.userIDValue['name'], this.fromDateValue?.toDateString(), this.toDateValue?.toDateString())
      .subscribe((date) => {
        if (date.length === 0) {
          this.dataValidation = false;
        } else {
          this.dataValidation = true;
        }
        date.forEach(item => {
          this.logDatesConversationID.push(item);
        })
      }, error => {

      });
  }

  legendFunction(option: boolean) {
    var legend = <HTMLSpanElement>document.getElementById("legendBtnExport");
    if (option) {
      legend.classList.add("show");
      legend.classList.remove("hide");
    } else {
      legend.classList.add("hide");
      legend.classList.remove("show");
    }
  }

  exportarOnOff() {
    var dif = this.dif(this.fromDateValue, this.toDateValue);
    /*
    if ((dif )) {
      this.legendFunction(false);
    } else {
      this.legendFunction(true);
    }
    */
  }



  dif(desde: Date, hasta: Date) {
    var fecha1 = moment(desde);
    var fecha2 = moment(hasta);
    if (fecha2.diff(fecha1, 'days') <= 60) {
      this.VisibleTooltipExport(false);
      this.DisableButtonExport(false);
      return true;
    } else
      this.VisibleTooltipExport(true);
      this.DisableButtonExport(true);
      return false;
  }

  download() {
    this.spinner.show();

    var divContainer = <HTMLDivElement>document.getElementById("divContainer");
    divContainer.style.opacity = "0.2";
    var a =  this.userIDValue ? this.userIDValue['name'] : ""
    if ( a) {
      this.logService.GetAllLogsToExportToCSV(this.userIDValue['name'], this.fromDateValue.toDateString(), this.toDateValue?.toDateString()).subscribe(
        res => {
          this.downloadFile(res, 'Log conversacional.csv');
          this.spinner.hide();
          divContainer.style.opacity = "1";
        },
        error => {
          console.log(error);
        }
      )
    } else {
      this.downloadAll();
    }
  }

  downloadAll() {
    this.spinner.show();
    var divContainer = <HTMLDivElement>document.getElementById("divContainer");
    divContainer.style.opacity = "0.2";
    this.logService.GetAllUserLogsToExportToCSV(this.fromDateValue.toDateString(), this.toDateValue?.toDateString()).subscribe(
      res => {
        this.downloadFile(res, 'Log conversacional.csv');
        this.spinner.hide();
        divContainer.style.opacity = "1";
      },
      error => {
        console.log(error);
      }
    )
  }


  downloadFile(data, filename = 'data') {
    if (data.length != 0) {
      let csvData = this.ConvertToCSV(data, ['userId', 'conversationID', 'sessionId','msgID', 'dateStart', 'originUser', 'message', 'actionMessage', 'actionNameSelected_User', 'originBotResponse', 'messageResultBot', 'intentionName', 'score', 'searchMsg', 'searchScore', 'responseChat']);
      var FileSaver = require('file-saver');
      let blob = new Blob(['\ufeff' + csvData], { type: "text/csv;charset=utf-8" });
      FileSaver.saveAs(blob, filename);
    } else {
      this.translate.stream('botAdmin.logs.userError').subscribe(res => {
        this.toast.error(res);
      });
    }
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No/*/';
    for (let index in headerList) {
      row += headerList[index] + '/*/';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    let lenArray = array.length;
    for (let i = 0; i < lenArray; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];
        if (head === 'score' || head === 'searchScore') {
          array[i][head] = "'" + array[i][head] + "'";
        }
        line += '/*/' + '"' + array[i][head] + '"';
      }
      str += line + '\r\n';
    }
    return str;
  }

  VisibleTooltipSearch(condition: boolean) {
    if (condition) {
      this.translate.stream('botAdmin.logs.buttonGet').subscribe(res => {
        this.textTooltipSearch = res;
      });
    }
    else {
      this.textTooltipSearch = "";
    }

  }

  VisibleTooltipExport(condition: boolean) {
    if (condition) {
      this.translate.stream('botAdmin.logs.buttonExport').subscribe(res => {
        this.textTooltipExport = res;
      });
    }
    else {
      this.textTooltipExport = "";
    }
  }

  DisableButtonExport(condition: boolean) {
    var btnExportCSV = <HTMLButtonElement>document.getElementById("btnExportCSV");
    btnExportCSV.disabled = condition;
  }
}

