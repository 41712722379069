import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from '../services/AppConfigService';
import { OAuthService } from 'angular-oauth2-oidc';
import { PaginationFilterSap } from '../entities/paginationFilterSap';
import { sapProcesses } from '../entities/sapProcesses';
import { sapProcessStep } from '../entities/sapProcessStep';
import { List } from "lodash";
import { sapProcessIntentions } from '../entities/sapProcessIntentions';
import { sapResponse } from '../entities/sapResponse';
import { sapProcessActions } from '../entities/SapProcessActions';
import { Action } from '../entities/action';
import { actionsAndProcess} from '../entities/actionsAndProcess'
import { optionAndProcess } from '../entities/optionAndProcess';

@Injectable()
export class SapService {

    apiServiceBot: string = this.environment.GetEnterpriseApi;
    pathController = '/api/v1/SAP/';


    constructor(private http: HttpClient, private environment: AppConfigService, private OAuth: OAuthService) {} 


    GetAllProcess(): Observable<List<sapProcesses>> {
        let pathMethod = 'GetAllProcess';
        return this.http.get<List<sapProcesses>>(this.apiServiceBot + this.pathController + pathMethod, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                "Accept": "application/json",
                'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
            })
        })
            .pipe(
                catchError(this.handleError)
            );
    }

    GetProcessById(id: any): Observable<any> {
        let pathMethod = 'GetProcessById?id={id}';
        pathMethod = pathMethod.replace('{id}', id);
        return this.http.get<any>(this.apiServiceBot + this.pathController + pathMethod, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                "Accept": "application/json",
                'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
            })
        })
            .pipe(
                catchError(this.handleError)
            );
    }

    GetStepsByProcessId(id: any): Observable<sapResponse> {
        let pathMethod = 'GetStepsByProcessId?id={id}';
        pathMethod = pathMethod.replace('{id}', id);
        return this.http.get<sapResponse>(this.apiServiceBot + this.pathController + pathMethod, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                "Accept": "application/json",
                'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
            })
        })
            .pipe(
                catchError(this.handleError)
            );
    }

    updateStepsAndQuantitybyProcessId( listSteps: sapResponse, id: any): Observable<any> {
        let pathMethod = 'updateStepsAndQuantitybyProcessId?processId={id}';
        pathMethod = pathMethod.replace('{id}', id);
        return this.http.patch<any>(this.apiServiceBot + this.pathController + pathMethod, listSteps, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                "Accept": "application/json",
                'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
            })
        })
            .pipe(
                catchError(this.handleError)
            );
    }



    GetAllPaginationFilterSap(paginationFilter: PaginationFilterSap): Observable<SapService[]> {
        let pathMetodo = 'GetAllPaginationFilterSap';
        return this.http.post<SapService[]>(this.apiServiceBot + this.pathController + pathMetodo, paginationFilter , {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          "Accept": "application/json",
          'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
        })
      })
          .pipe(
            catchError(this.handleError)
          );
      }

      getAllPaginationSapCount(paginationFilter: PaginationFilterSap): Observable<number> {
        let pathMetodo = 'GetCountPaginationSap';
        return this.http.post<number>(this.apiServiceBot + this.pathController + pathMetodo, paginationFilter,  {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          "Accept": "application/json",
          'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
        })
      })
          .pipe(
            catchError(this.handleError)
          );
      }

      GetSAPProcessIntentions(id: any): Observable<string[]> {
        let pathMethod = 'GetSAPProcessIntentions?id={id}';
        pathMethod = pathMethod.replace('{id}', id);
        return this.http.get<string[]>(this.apiServiceBot + this.pathController + pathMethod, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                "Accept": "application/json",
                'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
            })
        })
            .pipe(
                catchError(this.handleError)
            );
    }


    addIntentionsByProcessId(processIntentions: sapProcessIntentions): Observable<any> {
        let pathMetodo = this.apiServiceBot + this.pathController + 'AddIntentionsByProcessId';
        return this.http.post<any>(pathMetodo, processIntentions, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          "Accept": "application/json",
          'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
        })
      })
          .pipe(
            catchError(this.handleError)
          );
      }


    deleteProcessIntention(id: any): Observable<boolean> {
        let pathMethod = 'deleteProcessIntention?id={id}';
        pathMethod = pathMethod.replace('{id}', id);
        return this.http.patch<boolean>(this.apiServiceBot + this.pathController + pathMethod, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                "Accept": "application/json",
                'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
            })
        })
            .pipe(
                catchError(this.handleError)
            );
    }

    updateProcess(process: sapProcesses): Observable<any>{
        let pathMethod = 'updateProcess';
   
        return this.http.put<any>(this.apiServiceBot + this.pathController + pathMethod,process , {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                "Accept": "application/json",
                'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
            })
        })
            .pipe(
                catchError(this.handleError)
            ); 
    }


    addActionsByProcessId(processActions: sapProcessActions): Observable<any> {
        let pathMetodo = this.apiServiceBot + this.pathController + 'AddActionToProcess';
        return this.http.post<any>(pathMetodo, processActions, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          "Accept": "application/json",
          'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
        })
      })
          .pipe(
            catchError(this.handleError)
          );
      }

      GetSAPProcessActions(id: any): Observable<Action[]> {
        let pathMethod = 'getActionsProcessByProcessId?id={id}';
        pathMethod = pathMethod.replace('{id}', id);
        return this.http.get<Action[]>(this.apiServiceBot + this.pathController + pathMethod, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                "Accept": "application/json",
                'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
            })
        })
            .pipe(
                catchError(this.handleError)
            );
    }
      
    getProcessByActionId(id: any): Observable<sapProcesses[]> {
        let pathMetodo = 'getProcessByActionId?id={id}';
        pathMetodo = pathMetodo.replace('{id}', id);
        return this.http.get<sapProcesses[]>(this.apiServiceBot + this.pathController + pathMetodo, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          "Accept": "application/json",
          'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
        })
      })
          .pipe(
            catchError(this.handleError)
          );
      }

      AddProcessToAction(actionsAndProcess: actionsAndProcess): Observable<boolean> {
        let pathMetodo = this.apiServiceBot + this.pathController + 'AddProcessToAction';
        return this.http.post<boolean>(pathMetodo, actionsAndProcess, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          "Accept": "application/json",
          'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
        })
      })
          .pipe(
            catchError(this.handleError)
          );
      }

      AddProcessToOption(optionsAndProcess: optionAndProcess): Observable<boolean> {
        let pathMetodo = this.apiServiceBot + this.pathController + 'AddProcessToOption';
        return this.http.post<boolean>(pathMetodo, optionsAndProcess, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          "Accept": "application/json",
          'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
        })
      })
          .pipe(
            catchError(this.handleError)
          );
      }

      getProcessByOptionId(id: any): Observable<sapProcesses[]> {
        let pathMetodo = 'getProcessByOptionId?id={id}';
        pathMetodo = pathMetodo.replace('{id}', id);
        return this.http.get<sapProcesses[]>(this.apiServiceBot + this.pathController + pathMetodo, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          "Accept": "application/json",
          'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
        })
      })
          .pipe(
            catchError(this.handleError)
          );
      }


    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            //console.error('Ocurrió un error:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            // console.error(
            //   `código ${error.status}, ` +
            //   `mensaje: ${error.error}`);
        }
        // return an observable with a user-facing error message
        if (error.status == 400) {
            return throwError(error.error);
        } else if (error.status == 500) {
            return throwError(error.error);
        } else {
            return throwError(
                'Something bad happened, please try again.');
        }

    };
}