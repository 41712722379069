<spinner></spinner>
<div *ngIf="notAllowed">
  <app-error404></app-error404>
</div>
<div *ngIf="!notAllowed">
<div class="content-heading">
  <div>
    <h3> {{'intention.title' | translate}} </h3>
    <h6 id="subtitle"> {{'intention.subtitle' | translate}} </h6>
  </div>
</div>
<div class="row">
  <div class="col-md-4" style="padding-right: 0%;">
    <div class="input-group mb-3">
      <input #intencion type='text' class="form-control" placeholder="{{placeholder | translate}}" (keyup.enter)="searchIntentions(intencion.value)" />
    </div>
  </div>
  <div class="col-md-auto">
    <div class="input-group mb-3" >
    <button class="mr-1 btn btn-labeled btn-info mb-2" type="button" id="search2"
    (click)="searchIntentions(intencion.value)"  >
        <i class="fa fa-search" id="search"></i>
        </button>
    </div>
  </div>
</div>
<body>
  <div>
      <ngx-json-viewer [json]="json" [expanded]="false"></ngx-json-viewer>
    </div>
</body>
</div>