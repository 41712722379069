<spinner></spinner>
<div *ngIf="notAllowed">
  <app-error404></app-error404>
</div>
<div *ngIf="!notAllowed">

  <div class="content-heading">
    <div>
      <h3> {{'botAdmin.logs.title' | translate}} </h3>
      <h4>{{'botAdmin.logs.subtitle' | translate}}</h4>
    </div>

  </div>
  <div class="row">
    <div class="col-md-5 col-lg-4 col-xl-3 col-12">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" name="labelSize"> {{'botAdmin.generic.searchByFromDate' | translate}} </span>
        </div>
        <input [max]="maxDate" (dateSelect)="exportarOnOff();" (dateInput)="exportarOnOff(); " style="height:39px" matInput
          [matDatepicker]="fromDatePicker" (keyup.enter)="GetLogs()" [(ngModel)]="fromDateValue" class="form-control"
          id="fromDateInput" disabled>
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker disabled="false"></mat-datepicker>

      </div>
    </div>
    <div class="col-md-5 col-lg-4 col-xl-3 col-12">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" name="labelSize"> {{'botAdmin.generic.searchByToDate' | translate}} </span>
        </div>
        <input [max]="maxDate" (dateSelect)="exportarOnOff(); " (dateInput)="exportarOnOff(); " style="height:39px" matInput
          [matDatepicker]="toDatePicker" (keyup.enter)="GetLogs()" [(ngModel)]="toDateValue" class="form-control "
          id="toDateInput" disabled>

        <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
        <mat-datepicker (ngModelChange)="exportarOnOff();  " #toDatePicker disabled="false"></mat-datepicker>
      </div>
    </div>
    <div class="col-md-5 col-lg-4 col-xl-3 col-12">
      <div class="input-group mb-3">
        <div class="input-group-prepend w-100">
          <label class="input-group-text" for="inputGroupSelect01" name="labelSize">
            {{'botAdmin.logs.searchByUserID' | translate}} </label>
          <ng-autocomplete id="userList" [data]="userList" (keyup.enter)="GetLogs()" (inputCleared)="setUserNull()"
            (selected)="searchOnOff(true)" (inputChanged)="searchOnOff(userIDValue ? true : false)"
            [(ngModel)]="userIDValue" [searchKeyword]="keyword" aria-autocomplete="off" [itemTemplate]="itemTemplate"
            [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>
          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.name"></a>
          </ng-template>
          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="col-md-1 col-lg-1 col-xl-1 col-12">
      <div class="input-group mb-3 d-flex justify-content-center" placement="bottom" ngbTooltip="{{textTooltipSearch}}">
        <button class="mr-1 btn btn-labeled btn-info mb-2" type="button" id="search2" (click)="GetLogs()">
          <i class="fa fa-search" id="search"></i>
        </button>
      </div>
    </div>
    <div class="col-md-4 col-lg-3 col-xl-2 col-12">
      <div class="row d-flex justify-content-center" placement="bottom" ngbTooltip="{{textTooltipExport}}">
        <button id="btnExportCSV" class="pt-1 pb-1 mb-2 mr-1 btn btn-labeled btn-info" type="button" (click)="download()">
          {{'botAdmin.logs.exportToCSV' | translate}}
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span id="legendBtnExport" class="popuptext">
        {{'botAdmin.logs.errorLogsLegend'|translate}}
      </span>
    </div>
  </div>
  <hr id="hr1">
  <ng-container>
    <div id="divContainer" class="container-fluid w-100">
      <div class="row mt-3">
        <div class="col-12 col-lg-4" *ngIf="!dataValidation">
          <select id="selectConversations2" class="custom-select" size="25" [(ngModel)]="selectedConversationID"
            name="fecha" (change)="getAllLogs()" style="overflow: auto;">
            <option style="border-bottom: 2px solid black;" disabled>--
              {{'botAdmin.logs.notFoundSelectConversation' | translate}} --</option>
          </select>
        </div>
        <div id="col1" class="col-12 col-lg-3" *ngIf="dataValidation">
          <select class="custom-select" size="25" id="selectConversations" [(ngModel)]="selectedConversationID"
            name="fecha" (change)="getAllLogs()" style="overflow: auto;">
            <option style="border-bottom: 2px solid black;" disabled>--
              {{'botAdmin.logs.selectConversation' | translate}} --</option>
            <option style="border-bottom: 2px solid black; margin-top: 10px; font-size: 1.1rem;"
              *ngFor="let fecha of logDatesConversationID" value="{{fecha.sessionId}}">
              <p>{{fecha.dateStart | customDate}} </p>
            </option>
          </select>
        </div>
        <div class="col-12 col-lg-9 d-flex justify-content-center text-center" *ngIf="loading">
          <spinner></spinner>
        </div>
        <div id="cardConversation" class=" col-12 col-lg-8" style="height: 636px; overflow: auto;" *ngIf="!loading">
          <!-- START card-->
          <div class="card card-default">
            <div class="card-header">
              <h4>{{'botAdmin.logs.conversationID' | translate}}: {{selectedConversationID}}</h4>
            </div>
            <!-- START table-responsive-->
            <div class="table-responsive">
              <table class="table table-striped table-bordered table-hover" id="table-ext-2">
                <tbody>
                  <tr *ngFor="let messages of logModel">
                    <td>
                      <div class="media-body" *ngIf="!dataValidation">
                        {{'botAdmin.generic.notfound' | translate}}
                      </div>
                      <div class="media">
                        <div class="media-body">
                          <h3>{{'botAdmin.logs.messageID' | translate}} {{messages.msgIndex}}</h3>
                          <h6>{{messages.dateStart | customDate}}</h6>
                          <div class="float-left badge badge-info mr-2 d-block">
                            {{'botAdmin.logs.searchByUserID' | translate}} </div>
                          <p>{{'botAdmin.logs.origin' | translate}}: <b>{{messages.originUser}}</b></p>
                          <p>{{'botAdmin.logs.messageID' | translate}}: <b>{{messages.message}}</b></p>
                          <p><b>{{messages.actionMessage}}</b></p>
                          <p>{{'botAdmin.logs.actionName' | translate}}: <b>{{messages.actionNameSelected_User}}</b></p>
                          <hr>
                          <div class="float-left badge badge-success mr-2 d-block">Bot</div>
                          <p>{{'botAdmin.logs.origin' | translate}}: <b>{{messages.originBotResponse}}</b></p>
                          <p>{{'botAdmin.logs.messageID' | translate}} Log: <b>{{messages.messageResultBot}}</b></p>
                          <p>{{'botAdmin.logs.intentionName' | translate}}: <b>{{messages.intentionName}}</b></p>
                          <p>{{'botAdmin.logs.score' | translate}}: <b>{{messages.score}}</b></p>
                          <p>{{'botAdmin.logs.search' | translate}}: <b>{{messages.searchMsg}}</b></p>
                          <p>{{'botAdmin.logs.searchScore' | translate}}: <b>{{messages.searchScore}}</b></p>
                          <p>{{'botAdmin.logs.response' | translate}}: <b>{{messages.responseChat}}</b></p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- END table-responsive-->
            <!-- END card-->
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>