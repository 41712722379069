export class PaginationFilterUser {
    pageIndex: number;
    pageSize : number;
    nameValue : string;
    emailValue : string;

    constructor() {
        this.nameValue = "";
        this.pageIndex = 1;
        this.pageSize = 1;
        this.emailValue = "";
    }
}