<spinner></spinner>
<div *ngIf="notAllowed">
  <app-error404></app-error404>
</div>
<div [hidden]="notAllowed" >

  <div class="content-heading">
      <div>
          <h3> {{'botAdmin.option.title' | translate}} </h3>
          <h6 id="subtitle"> {{'botAdmin.option.subtitle' | translate}} </h6>
      </div>
  </div>
  <div class="container">
    <tree-root #tree [focused]="true" [options]="customTemplateStringOptions" [nodes]="nodes" (moveNode)="onMoveNode($event)" >
      <ng-template #treeNodeTemplate let-node>
        <div class="btn-toolbar" role="toolbar" aria-label="..."  [ngClass]="{ 'nodoHabilitado': node.data.isShowed }">
          <div class="btn-group" role="group" aria-label="..."> 
            <span title="Descripción: {{node.data.description}}">
              <b>{{node.data.name}}</b>
            </span>
          </div>
          <div class="btn-group" role="group" aria-label="...">
            <a  class="btn btn-info btn-xs" style="margin-left:8px; " (mouseup)="redirect(0, node.data.id, node.data.isShowed, 'new', $event)" [class.disabled]="readonly"><em class="fa fa-plus"></em></a>
          </div>
          <div class="btn-group" role="group" aria-label="...">
            <a  class="btn btn-info btn-xs" style="margin-left:3px; "  (mouseup)="redirect(node.data.id,node.data.parentId, node.data.isShowed, 'edit', $event)"><em class="fa fa-paint-brush"></em></a>
          </div>     

        </div>

      </ng-template>
    </tree-root>
  </div>
</div>