import { DataSource } from '@angular/cdk/table';
import { Action } from '../entities/action';
import { catchError, finalize } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { BotService } from '../services/botService';
import { ConversationEntity } from '../entities/conversationEntity';

export class ConversationDataSource implements DataSource<ConversationEntity> {

    private conversationSubject = new BehaviorSubject<ConversationEntity[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private conversationService: BotService) {}

    connect(collectionViewer: CollectionViewer): Observable<ConversationEntity[]> {
        return this.conversationSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.conversationSubject.complete();
        this.loadingSubject.complete();
    }

    loadConversation(pageIndex, pageSize, answerValue, welcomeValue, typeValue) {

        this.loadingSubject.next(true);

        this.conversationService.GetAllPaginationFilterConversation(pageIndex, pageSize, answerValue, welcomeValue, typeValue).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(conversation => this.conversationSubject.next(conversation));
    }    
}