import { IdentityRole } from "./identityRole";
import { IdentityUser } from "./identityUser";

export class UserAndRoles{
    public rolesList: IdentityRole[];
    public user: IdentityUser;
    
    constructor(){
    }
}
  