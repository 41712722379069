export class sapProcesses {

   public SapProcessid: number;
   public name: string;
   public description: string;
   public status: boolean;
   public createdDate: Date;
   public type: string;
   public modifiedDate: Date;
   public endpoint: string;

   constructor() {

} 
}