
export class PaginationFilterConversation {
    pageIndex: number;
    pageSize : number;
    isWelcome : string;
    answer : string;
    typeIntentions: string;

    constructor() {
        this.isWelcome = "all";
        this.pageIndex = 1;
        this.pageSize = 1;
        this.answer = "";
        this.typeIntentions = "all";
    }
}