<tabset [justified]="true">
    <tab>
        <h3 class="text-center text-thin mt-4" [innerHTML]="'offsidebar.setting.SETTINGS' | translate"></h3>
        <div class="p-2">
            <h4 class="text-muted text-thin">Language</h4>
            <select 
                class="form-control" 
                #selectedLang 
                (change)="switchLang(selectedLang.value)">
            <option *ngFor="let language of translate.getLangs()" 
                [value]="language"
                [selected]="language === translate.currentLang">
                {{ language }}
            </option>
            </select>
        </div>
        <div class="p-2">
            <h4 class="text-muted text-thin">Themes</h4>
            <div class="row row-flush mb-2">
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="A" />
                            <span class="icon-check"></span>
                            <span class="split">
                            <span class="color bg-theme-a"></span>
                            <span class="color bg-theme-a"></span>
                            </span>
                            <span class="color bg-theme-a"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="B" />
                            <span class="icon-check"></span>
                            <span class="split">
                            <span class="color bg-theme-b"></span>
                            <span class="color bg-theme-b"></span>
                            </span>
                            <span class="color bg-theme-b"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="C" />
                            <span class="icon-check"></span>
                            <span class="split">
                            <span class="color bg-theme-c"></span>
                            <span class="color bg-theme-c"></span>
                            </span>
                            <span class="color bg-theme-c"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="D" />
                            <span class="icon-check"></span>
                            <span class="split">
                            <span class="color bg-theme-d"></span>
                            <span class="color bg-theme-d"></span>
                            </span>
                            <span class="color bg-theme-d"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row row-flush mb-2">
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="E" />
                            <span class="icon-check"></span>
                            <span class="split">
                            <span class="color bg-theme-e"></span>
                            <span class="color bg-theme-e"></span>
                            </span>
                            <span class="color bg-theme-e"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="F" />
                            <span class="icon-check"></span>
                            <span class="split">
                            <span class="color bg-theme-f"></span>
                            <span class="color bg-theme-f"></span>
                            </span>
                            <span class="color bg-theme-f"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="G" />
                            <span class="icon-check"></span>
                            <span class="split">
                            <span class="color bg-theme-g"></span>
                            <span class="color bg-theme-g"></span>
                            </span>
                            <span class="color bg-theme-g"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="H" />
                            <span class="icon-check"></span>
                            <span class="split">
                            <span class="color bg-theme-h"></span>
                            <span class="color bg-theme-h"></span>
                            </span>
                            <span class="color bg-theme-h"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row row-flush mb-2">
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="I" />
                            <span class="icon-check"></span>
                            <span class="split">
                            <span class="color bg-theme-i"></span>
                            <span class="color bg-theme-i"></span>
                            </span>
                            <span class="color bg-theme-i"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="J" />
                            <span class="icon-check"></span>
                            <span class="split">
                            <span class="color bg-theme-j"></span>
                            <span class="color bg-theme-j"></span>
                            </span>
                            <span class="color bg-theme-j"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="K" />
                            <span class="icon-check"></span>
                            <span class="split">
                            <span class="color bg-theme-k"></span>
                            <span class="color bg-theme-k"></span>
                            </span>
                            <span class="color bg-theme-k"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="L" />
                            <span class="icon-check"></span>
                            <span class="split">
                            <span class="color bg-theme-l"></span>
                            <span class="color bg-theme-l"></span>
                            </span>
                            <span class="color bg-theme-l"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row row-flush mb-2">
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="M" />
                            <span class="icon-check"></span>
                            <span class="split">
                            <span class="color bg-theme-m"></span>
                            <span class="color bg-theme-m"></span>
                            </span>
                            <span class="color bg-theme-m"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="N" />
                            <span class="icon-check"></span>
                            <span class="split">
                            <span class="color bg-theme-n"></span>
                            <span class="color bg-theme-n"></span>
                            </span>
                            <span class="color bg-theme-n"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="O" />
                            <span class="icon-check"></span>
                            <span class="split">
                            <span class="color bg-theme-o"></span>
                            <span class="color bg-theme-o"></span>
                            </span>
                            <span class="color bg-theme-o"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="P" />
                            <span class="icon-check"></span>
                            <span class="split">
                            <span class="color bg-theme-p"></span>
                            <span class="color bg-theme-p"></span>
                            </span>
                            <span class="color bg-theme-p"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row row-flush mb-2">
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="Q" />
                            <span class="icon-check"></span>
                            <span class="split">
                            <span class="color bg-theme-q"></span>
                            <span class="color bg-theme-q"></span>
                            </span>
                            <span class="color bg-theme-q"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="R" />
                            <span class="icon-check"></span>
                            <span class="split">
                            <span class="color bg-theme-r"></span>
                            <span class="color bg-theme-r"></span>
                            </span>
                            <span class="color bg-theme-r"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()" value="S" />
                            <span class="icon-check"></span>
                            <span class="split">
                            <span class="color bg-theme-s"></span>
                            <span class="color bg-theme-s"></span>
                            </span>
                            <span class="color bg-theme-s"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="setting-color">
                    </div>
                </div>
            </div>
        </div>
    </tab>
    
</tabset>
