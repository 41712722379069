import { Component, DebugElement, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, UntypedFormGroup, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { Action } from '../../../entities/action';
import { IntentionsAndAction } from '../../../entities/intentionsAndAction';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BotService } from 'src/app/services/botService';
import { ActionsAndAction } from 'src/app/entities/actionsAndAction';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/sharedService';
import { ThemesService } from 'src/app/core/themes/themes.service';
import { EditorInstance } from 'angular-markdown-editor';
import { identity, rest } from 'lodash';
import { SapService } from 'src/app/services/sapService';
import { sapProcessIntentions } from 'src/app/entities/sapProcessIntentions';
import { sapProcessActions } from 'src/app/entities/SapProcessActions'
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { List } from "lodash";
import { sapProcesses } from 'src/app/entities/sapProcesses';
import { sapProcessStep } from 'src/app/entities/sapProcessStep';
import { sapResponse } from 'src/app/entities/sapResponse';
import { RtlScrollAxisType } from '@angular/cdk/platform';
import { SAPProcessResponseQuantity } from 'src/app/entities/SAPProcessResponseQuantity';
import { debug } from 'console';

@Component({
  selector: 'app-process-abm',
  templateUrl: './process-abm.component.html',
  styleUrls: ['./process-abm.component.scss']
})
export class ProcessAbmComponent implements OnInit {


  // Entity Variables
  idProcess: number;
  actionModel: Action;
  nameToEdit: string;
  languageerror: any;
  processServiceModel: sapProcesses = new sapProcesses();
  //Form Variables
  title: string = "botAdmin.actionsabm.subtitle1";
  responseQuantity: SAPProcessResponseQuantity = new SAPProcessResponseQuantity();
  items: string[] = ["letters", "digits", "alphanumeric", "whitespace"];
  value: string[] = ["letters"];
  // Tab Variables
  activeTab: number = 1;
  disTab: boolean = false;
  showed: boolean = false;
  // Dual List Variables
  format = { all: 'All', none: 'None', add: 'Add', remove: 'Remove' };
  intentions: string[];
  selectedIntentions: string[];
  selectedActions: Action[];
  processCreateFormGroup: UntypedFormGroup;
  actionsUpdateFormGroup: UntypedFormGroup;
  EndpointFormGroup: UntypedFormGroup;
  nameCreate: string = "botAdmin.actionsabm.namecreate1";
  submitted = false;
  habResultType: boolean;
  statusProcess: boolean = false;
  actionsAndAction: ActionsAndAction;
  intentionsAndProcess: sapProcessIntentions;
  ActionsAndProcess: sapProcessActions;
  deleteTrue: string;
  deleteFalse: string;
  notAllowed: boolean = false;
  readonly: boolean = false;
  numberError: boolean = false;
  bsEditorInstance: EditorInstance;
  editorOptions: any;
  modalRef: NgbModalRef;
  actionsAssociate: Action[];
  // *************************
  fatherFormGroup: UntypedFormGroup;
  arrayFormGroup: UntypedFormArray;
  isInputShow: boolean;
  basicForm: UntypedFormGroup;
  responsesFromService: string;
  isAnswer: boolean = false;



  public markDownEditorOptions = {
    showPreviewPanel: true,
    showBorder: false,
    hideIcons: ['Refrence', 'Image', 'FullScreen', 'Link', 'Code'],
    usingFontAwesome5: true,
    resize: true,
    enablePreviewContentClick: false
  }
  constructor(public urlService: SharedService, private router: Router, private formBuilder: UntypedFormBuilder, private modal: NgbModal, private sapService: SapService, private actionService: BotService, private toast: ToastrService, private spinner: NgxSpinnerService,
    private themeService: ThemesService, private translate: TranslateService) {
    this.themeService.createStyle();
    this.themeService.setTheme(localStorage.getItem('theme'));
    var userRoles = JSON.parse(localStorage.getItem('roles'));
    if (userRoles.indexOf('OpacAdmin') == -1 && userRoles.indexOf('GlobalAdmin') == -1 && userRoles.indexOf('ReadOnlyUser') == -1)
      this.notAllowed = true;

    if (userRoles.indexOf('ReadOnlyUser') == 0)
      this.readonly = true;

    this.translate.stream('botAdmin.actionsabm.namecreate2').subscribe(res => {
    });
  }

  enabledTabs() {
    this.disTab = false;
  }

  ngOnInit() {

    this.getListResponseFromService();
    this.editorOptions = {
      iconlibrary: 'fa',
      onShow: (e) => this.bsEditorInstance = e
    };
    if (this.urlService.model.id != "0") {
      this.title = "";
      this.nameCreate = "botAdmin.actionsabm.namecreate2";
      this.enabledTabs();
      this.idProcess = this.urlService.model.id;
      this.GetProcessById();
      this.getIntentionsRelationshipByActionId();
    }
    this.selectedIntentions = [];
    this.selectedActions = [];

    this.getActionssRelationshipByProcessId();
    this.intentionsDualList();
    this.actionsDualList();
    this.getFormCreate();
    this.getFormUpdate();
    this.getFatherFormGroup();


  }
  hidePreview() { console.log("it works"); }
  ngAfterViewInit() {

  }

  GetProcessById() {
    this.sapService.GetProcessById(this.urlService.model.id).subscribe(x => {
      this.processServiceModel = x;
      this.nameToEdit = x.name;
      this.title = "Editar"
      this.statusProcess = x.status;

      this.processCreateFormGroup.patchValue({
        title: x.name
      });
      this.EndpointFormGroup.patchValue({
        endpoint: x.endpoint
      })
    })
  }


  intentionsDualList() {
    this.actionService.GetRecognizer()
    .subscribe((rest: any) => {
      var intentionsObj = rest.assets.intents.filter(x => x.category != 'None');
      this.intentions = [];
      intentionsObj.forEach(element => {
        this.intentions.push(element.category);
      });
    });
  }


  getListResponseFromService() {

    //  this.clear();

    this.sapService.GetStepsByProcessId(this.urlService.model.id).subscribe((rest: sapResponse) => {

      this.responseQuantity = rest.responseQuantity



      if (this.responseQuantity !== null) {
        let form1 = this.formBuilder.group({
          response: [this.responseQuantity.quantity, Validators.min(1)],
          hasmultiple: this.responseQuantity.hasMultiple,
          id: this.responseQuantity.id,
          idProcessStep: this.responseQuantity.idProcess
        });
        this.arrayFormGroup.push(form1)
        this.isInputShow = this.responseQuantity.hasMultiple;
      }

      rest.processSteps.forEach(element => {
        const response = element.processText
        this.getElementListForm();
        var a = rest.parameters
          .filter(x => x.idProcess == element.idProcess)
          .filter(x => x.idProcessStep == element.idProcessStep)
          .filter(x => x.alternative == element.alternative)
        var parametersquantity = 0 as Number;
        if (a.length > 0) { parametersquantity = a[0].parametersQuantity }
        let form = this.formBuilder.group({
          response: [response, Validators.required],
          id: element.idProcess,
          idProcessStep: element.idProcessStep,
          alternative: element.alternative,
          alternativeText: element.alternativeText,
          parameters: parametersquantity,
          paramDescription: element.paramDescription
        });
        this.arrayFormGroup.push(form);
      });
    })

  }

  getElementListForm() {
    this.basicForm = this.formBuilder.group({
      response: [""],

    });
  }

  getArrayElementListForm() {
    this.getElementListForm();
    this.arrayFormGroup = this.formBuilder.array([
      this.basicForm,
    ]);
  }

  getFatherFormGroup() {
    this.fatherFormGroup = this.formBuilder.group({
      arrayFormGroup: this.getArrayElementListForm(),
    });
  }
  newElementListForm() {
    return this.formBuilder.group({
      response: [""],
    });
  }

  clear() {
    this.arrayFormGroup.clear();
  }

  removeItemToList(index: number) {
    this.arrayFormGroup.removeAt(index);
  }

  getFormCreate() {
    this.processCreateFormGroup = this.formBuilder.group({
      title: ['', Validators.required]
    });
    this.EndpointFormGroup = this.formBuilder.group({
      endpoint: ['', [Validators.required]]
    });


  }

  getFormUpdate() {
    this.actionsUpdateFormGroup = this.formBuilder.group({
      actionsType: ['', Validators.required],
      actionsResult: ['', Validators.required],
      details: ['', Validators.required],
    });
  }

  changeTabs(tabN: number) {
    this.activeTab = tabN;
    }

  redirect(e: any) {
    this.urlService.navigateToViewElement('/management/process', null, null, null, null, null, e.button, null);
  }

  updateIntentions() {
    this.spinner.show();
    var process = this.createModelAction();
    this.addIntentionsByProcessId(this.selectedIntentions, process);
  }

  createModelAction(): sapProcesses {
    let process = this.mapToProcess(this.processCreateFormGroup);
    process.name = this.processServiceModel.name;
    process.SapProcessid = this.idProcess;
    process.type = this.actionsUpdateFormGroup.get('actionsType').value;
    process.status = this.statusProcess;

    return process;
  }

  mapToProcess(form: UntypedFormGroup): sapProcesses {
    var process: sapProcesses = {} as sapProcesses;
    process.name = form.get('title').value;
    return process;
  }


  addIntentionsByProcessId(actionIntentions: string[], action: sapProcesses) {
    this.intentionsAndProcess = {} as sapProcessIntentions;
    this.intentionsAndProcess.intentionsList = actionIntentions;
    this.intentionsAndProcess.sapProcess = action;
    this.addIntentions(this.intentionsAndProcess);

  }


  addIntentions(intentionsAndAction: sapProcessIntentions) {
    this.sapService.addIntentionsByProcessId(intentionsAndAction)
      .subscribe((rest: any) => {
        this.translate.stream('backendmessages.i203').subscribe(res => {
          this.toast.success(res);
        });
        this.spinner.hide();
      }, error => {
        this.translate.stream('backendmessages.i403').subscribe(res => {
          this.toast.error(res);
        });
        this.spinner.hide();
      })
  }

  getIntentionsRelationshipByActionId() {
    this.sapService.GetSAPProcessIntentions(this.urlService.model.id)
      .subscribe((rest: string[]) => {
        this.selectedIntentions = rest;
      });
  }

  changeStatus() {
    this.spinner.show();

    if (!this.processServiceModel.status) {
      this.statusProcess = true;
      this.processServiceModel.status = this.statusProcess;

      this.updateProcess(this.processServiceModel);
    }
    else {
      this.statusProcess = false;
      this.processServiceModel.status = this.statusProcess;
      this.updateProcess(this.processServiceModel);
    }
    this.spinner.hide();
  }

  updateProcess(process: sapProcesses) {
    this.spinner.show();
    this.sapService.updateProcess(process).subscribe(x => {
      this.translate.stream('backendmessages.i202').subscribe(res => {
        this.toast.success(res);
        this.title = "Process: " + this.processServiceModel.name;
        this.nameToEdit = this.processServiceModel.name;
      });

      // this.processServiceModel = x.model;
      this.spinner.hide();
    }, error => {
      this.translate.stream('backendmessages.i403').subscribe(res => {
        this.toast.error(res);

      });
      this.spinner.hide();
    });
  }

  updateName() {
    var newName = this.processCreateFormGroup.get("title").value;
    if (newName != "") {
      this.processServiceModel.name = newName
      this.updateProcess(this.processServiceModel)
    }
    else {
      this.translate.stream('botAdmin.generic.required').subscribe(res => {
        this.toast.error(res);
      });
    }
  }

  updateSteps() {

    var listaArmada = this.createStepList();

    if (typeof listaArmada !== "undefined") {
      this.sapService.updateStepsAndQuantitybyProcessId(listaArmada, +this.idProcess).subscribe(x => {
        this.translate.stream('backendmessages.i203').subscribe(res => {
          this.toast.success(res);

        });
        this.numberError = false;
        this.spinner.hide();
      }, error => {
        this.translate.stream('backendmessages.' + error.code).subscribe(res => {
          this.toast.error(res);

        });
        this.spinner.hide();
      });
    }
    else {
      this.translate.stream('backendmessages.i476').subscribe(res => {
        this.toast.error(res);
        this.spinner.hide();
      });
    }

  }

  createStepList() {
    var request: sapResponse = {} as sapResponse;
    var lista: Array<sapProcessStep> = [] as Array<sapProcessStep>;

    try {
      for (let index = 1; index < this.arrayFormGroup.length; index++) {
        var a = this.arrayFormGroup.controls[index].value['hasmultiple'] as boolean
        if (typeof a !== "undefined") {
          var quantity: SAPProcessResponseQuantity = {} as SAPProcessResponseQuantity;
          quantity.hasMultiple = this.arrayFormGroup.controls[index].value['hasmultiple'];
          quantity.id = this.arrayFormGroup.controls[index].value['id'];
          quantity.idProcess = this.arrayFormGroup.controls[index].value['idProcessStep'];
          quantity.quantity = this.arrayFormGroup.controls[index].value['response'];
          if (this.arrayFormGroup.controls[index].value['response'] < 1) {
            this.numberError = true;
          }
        }
        else if (index > 1 || typeof a === "undefined") {
          var aux: sapProcessStep = {} as sapProcessStep;
          aux.idProcess = +this.arrayFormGroup.controls[index].value['id'];
          aux.idProcessStep = this.arrayFormGroup.controls[index].value['idProcessStep'];
          aux.processText = this.arrayFormGroup.controls[index].value['response'];
          aux.alternative = this.arrayFormGroup.controls[index].value['alternative'];
          aux.alternativeText = this.arrayFormGroup.controls[index].value['alternativeText'];

      /// ESTO VALIDA QUE SE UTILICEN TODOS LOS PARAMETROS DISPONIBLES.. SE QUITA POR SOLICITUD DE RRHH
       /*   if (!this.validateParamUsage(aux.processText, this.arrayFormGroup.controls[index].value['parameters'])) {
            return;
          }*/

          if (this.arrayFormGroup.invalid) {

            return;
          } else {
            lista.push(aux);
          }
        }
      }
      request.processSteps = lista;
      request.responseQuantity = quantity;
      return request;
    }
    catch (error) {
      return error;
    }
  }

  validateParamUsage(text: string, parametersNumber: number) {

    for (let i = 1; i <= parametersNumber; i++) {
      var param = "{param";

      if (!text.includes(param + i + '}')) {
        return false;
      }

    }
    return true;
  }

  OnOptionChange() {
    this.isInputShow = !this.isInputShow;
  }

  updateEndpoint() {
    if (!this.EndpointFormGroup.invalid) {
      if (( this.EndpointFormGroup.get("endpoint").value.includes("https://") || this.EndpointFormGroup.get("endpoint").value.includes("http://")) && this.EndpointFormGroup.get("endpoint").value.includes("{param}")) {
        this.processServiceModel.endpoint = this.EndpointFormGroup.get("endpoint").value;
        this.updateProcess(this.processServiceModel)
      }
      else {
        this.translate.stream('backendmessages.i477').subscribe(res => {
          this.toast.error(res);
        });
      }
    }
    else {
      this.translate.stream('backendmessages.i476').subscribe(res => {
        this.toast.error(res);
      });
    }
  }

  openModal(content) {

    this.modalRef = this.modal.open(content, { size: 'lg', backdrop: 'static' });
  }


  updateActions() {
    this.spinner.show();
    var process = this.createModelAction();
       this.addActionsByProcessId(this.selectedActions, process);
  }

  addActionsByProcessId(actionIntentions: Action[], process: sapProcesses) {
    this.ActionsAndProcess = {} as sapProcessActions;
    this.ActionsAndProcess.actionsList = actionIntentions;
    this.ActionsAndProcess.sapProcess = process;
    this.addActions(this.ActionsAndProcess);

  }


  addActions(processAndAction: sapProcessActions) {
    this.sapService.addActionsByProcessId(processAndAction)
      .subscribe((rest: any) => {
        this.translate.stream('backendmessages.i203').subscribe(res => {
          this.toast.success(res);
        });
        this.spinner.hide();
      }, error => {
        this.translate.stream('backendmessages.i403').subscribe(res => {
          this.toast.error(res);
        });
        this.spinner.hide();
      })
  }

  getActionssRelationshipByProcessId() {
    this.sapService.GetSAPProcessActions(this.urlService.model.id)
      .subscribe((rest: Action[]) => {
        this.selectedActions = rest;
      });
  }

  actionsDualList() {
    this.actionService.getAllActions()
      .subscribe((rest: Action[]) => {
        this.actionsAssociate = rest.filter(x => x.isActived);
      });
  }


}

