import { Injectable } from '@angular/core';

const themeA = require('../../shared/styles/themes/theme-a.scss');
const themeB = require('../../shared/styles/themes/theme-b.scss');
const themeC = require('../../shared/styles/themes/theme-c.scss');
const themeD = require('../../shared/styles/themes/theme-d.scss');
const themeE = require('../../shared/styles/themes/theme-e.scss');
const themeF = require('../../shared/styles/themes/theme-f.scss');
const themeG = require('../../shared/styles/themes/theme-g.scss');
const themeH = require('../../shared/styles/themes/theme-h.scss');
const themeI = require('../../shared/styles/themes/theme-i.scss');
const themeJ = require('../../shared/styles/themes/theme-j.scss');
const themeK = require('../../shared/styles/themes/theme-k.scss');
const themeL = require('../../shared/styles/themes/theme-l.scss');
const themeM = require('../../shared/styles/themes/theme-m.scss');
const themeN = require('../../shared/styles/themes/theme-n.scss');
const themeO = require('../../shared/styles/themes/theme-o.scss');
const themeP = require('../../shared/styles/themes/theme-p.scss');
const themeQ = require('../../shared/styles/themes/theme-q.scss');
const themeR = require('../../shared/styles/themes/theme-r.scss');
const themeS = require('../../shared/styles/themes/theme-s.scss');


@Injectable()
export class ThemesService {

    styleTag: any;
    defaultTheme: string = 'S';

    constructor() {
        this.createStyle();
        this.setTheme(this.defaultTheme);
    }

    public createStyle() {
        const head = document.head || document.getElementsByTagName('head')[0];
        this.styleTag = document.createElement('style');
        this.styleTag.type = 'text/css';
        this.styleTag.id = 'appthemes';
        head.appendChild(this.styleTag);
    }

    setTheme(name) {
        localStorage.setItem('theme', name);
        switch (name) {
            case 'A':
                this.injectStylesheet(themeA);
                break;
            case 'B':
                this.injectStylesheet(themeB);
                break;
            case 'C':
                this.injectStylesheet(themeC);
                break;
            case 'D':
                this.injectStylesheet(themeD);
                break;
            case 'E':
                this.injectStylesheet(themeE);
                break;
            case 'F':
                this.injectStylesheet(themeF);
                break;
            case 'G':
                this.injectStylesheet(themeG);
                break;
            case 'H':
                this.injectStylesheet(themeH);
                break;
            case 'I':
                this.injectStylesheet(themeI);
                break;
            case 'J':
                this.injectStylesheet(themeJ);
                break;
            case 'K':
                this.injectStylesheet(themeK);
                break;
            case 'L':
                this.injectStylesheet(themeL);
                break;
            case 'M':
                this.injectStylesheet(themeM);
                break;
            case 'N':
                this.injectStylesheet(themeN);
                break;
            case 'O':
                this.injectStylesheet(themeO);
                break;
            case 'P':
                this.injectStylesheet(themeP);
                break;
            case 'Q':
                this.injectStylesheet(themeQ);
                break;
            case 'R':
                this.injectStylesheet(themeR);
                break;
            case 'S':
                this.injectStylesheet(themeS);
                break;
        }
    }

    injectStylesheet(css) {
        this.styleTag.innerHTML = css.default;
    }

    getDefaultTheme() {
        return this.defaultTheme;
    }

}
