import { DataSource } from '@angular/cdk/table';
import { Action } from '../entities/action';
import { catchError, finalize } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { BotService } from '../services/botService';
import { PaginationFilterActions } from '../entities/paginationFilterActions';

export class ActionDataSource implements DataSource<Action> {

    private actionSubject = new BehaviorSubject<Action[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private actionService: BotService) {}

    connect(collectionViewer: CollectionViewer): Observable<Action[]> {
        return this.actionSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.actionSubject.complete();
        this.loadingSubject.complete();
    }

    loadActions(paginationFilter: PaginationFilterActions) {

        this.loadingSubject.next(true);

        this.actionService.GetAllPaginationFilterActions(paginationFilter).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(entity => {
            this.actionSubject.next(entity)
        });
    }    
}