export class Configuration{
    configurationId: string;
    configurationName: string;
    configurationValue: string;
    descriptionSpanish: string;
    descriptionEnglish: string;

    public Configuration(){
        this.configurationId = "";
        this.configurationName = "";
        this.configurationValue = "";
        this.descriptionSpanish = "";
        this.descriptionEnglish = "";
    }
}