
export class PaginationFilterActions {
    pageIndex: number;
    pageSize : number;
    status : string;
    title : string;
    typeActions: string;

    constructor() {
        this.status = "all";
        this.pageIndex = 1;
        this.pageSize = 1;
        this.title = "";
        this.typeActions = "all";
    }
}