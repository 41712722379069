import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, UntypedFormGroup, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { Action } from '../../../entities/action';
import { IntentionsAndAction } from '../../../entities/intentionsAndAction';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BotService } from 'src/app/services/botService';
import { ActionsAndAction } from 'src/app/entities/actionsAndAction';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/sharedService';
import { ThemesService } from 'src/app/core/themes/themes.service';
import { EditorInstance } from 'angular-markdown-editor';
import { identity } from 'lodash';
import { sapProcesses } from 'src/app/entities/sapProcesses';
import { actionsAndProcess } from 'src/app/entities/actionsAndProcess';
import { SapService } from 'src/app/services/sapService';

@Component({
  selector: 'app-actions-abm',
  templateUrl: './actions-abm.component.html',
  styleUrls: ['./actions-abm.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActionsAbmComponent implements OnInit {

  // Entity Variables
  idAction: number;
  actionModel: Action;
  nameToEdit: "";
  languageerror: any;
  actionServiceModel: Action = new Action();
  //Form Variables
  title: string = "botAdmin.actionsabm.subtitle1";
  types: string[] = ["ANSWER", "LINK", "IMAGE", "VIDEO"];
  items: string[] = ["letters", "digits", "alphanumeric", "whitespace"];
  value: string[] = ["letters"];
  // Tab Variables
  activeTab: number = 1;
  disTab: boolean = true;
  showed: boolean = false;
  // Dual List Variables
  actionsAssociate: Action[];
  actionsAssociateSelected: Action[];
  ProcessAssociate: sapProcesses[];
  selectedProcess: sapProcesses[];
  format = { all: 'All', none: 'None', add: 'Add', remove: 'Remove' };
  intentions: string[];
  selectedIntentions: string[];
  actionsCreateFormGroup: UntypedFormGroup;
  actionsUpdateFormGroup: UntypedFormGroup;
  nameCreate: string = "botAdmin.actionsabm.namecreate1";
  submitted = false;
  habResultType: boolean;
  statusAction: boolean = false;
  actionsAndAction: ActionsAndAction;
  intentionsAndAction: IntentionsAndAction;
  actionsAndProcess: actionsAndProcess;
  deleteTrue: string;
  deleteFalse: string;
  notAllowed: boolean = false;
  readonly: boolean = false;
  bsEditorInstance: EditorInstance;
  editorOptions: any;
  // *************************
  fatherFormGroup: UntypedFormGroup;
  arrayFormGroup: UntypedFormArray;
  basicForm: UntypedFormGroup;
  responsesFromService: string;
  isAnswer : boolean = false;


  public markDownEditorOptions = {
    showPreviewPanel: true,
    showBorder: false,
    hideIcons: ['Refrence', 'Image', 'FullScreen', 'Link', 'Code'],
    usingFontAwesome5: true,
    resize: true,
    enablePreviewContentClick: false
  }
  constructor(public urlService: SharedService, private router: Router, private formBuilder: UntypedFormBuilder, private actionService: BotService,private sapService: SapService , private toast: ToastrService, private spinner: NgxSpinnerService,
    private themeService: ThemesService, private translate: TranslateService) {
    this.themeService.createStyle();
    this.themeService.setTheme(localStorage.getItem('theme'));
    var userRoles = JSON.parse(localStorage.getItem('roles'));
    if (userRoles.indexOf('OpacAdmin') == -1 && userRoles.indexOf('GlobalAdmin') == -1 && userRoles.indexOf('ReadOnlyUser') == -1)
    this.notAllowed = true;

    if ( userRoles.indexOf('ReadOnlyUser') == 0)
    this.readonly = true;

    this.translate.stream('botAdmin.actionsabm.namecreate2').subscribe(res => {
    });
  }

  enabledTabs() {
    this.disTab = false;
  }

  ngOnInit() {
    this.editorOptions = {
      iconlibrary: 'fa',
      onShow: (e) => this.bsEditorInstance = e
    };
    if (this.urlService.model.id != "0") {
      this.title = "";
      this.nameCreate = "botAdmin.actionsabm.namecreate2";
      this.enabledTabs();
      this.idAction = this.urlService.model.id;
      this.GetActionById();
      this.getActionRelationshipByActionId();
      this.getIntentionsRelationshipByActionId();
      this.getProcessRelationshipByActionId()
    }
    this.selectedIntentions = [];
    this.actionsAssociateSelected = [];
    this.selectedProcess = [];

    this.actionsDualList();
    this.intentionsDualList();
    this.processDualList();
    this.habResultType = false;
    this.getFormCreate();
    this.getFormUpdate();
    this.getFatherFormGroup();


  }
  hidePreview() { console.log("it works"); }
  ngAfterViewInit() {

      }

  getIntentionsByActionId() {
    this.actionService.getIntentionsByActionId(this.idAction)
      .subscribe((rest2: string[]) => {
        this.selectedIntentions = rest2;
      });
  }

  getActionRelationshipByActionId() {
    this.actionService.getActionRelationshipByActionId(this.idAction)
      .subscribe((rest2: Action[]) => {
        this.actionsAssociateSelected = rest2;
      });
  }

  getIntentionsRelationshipByActionId() {
    this.actionService.getIntentionsByActionId(this.idAction)
      .subscribe((rest: string[]) => {
        this.selectedIntentions = rest;
      });
  }

  getProcessRelationshipByActionId() {
    this.sapService.getProcessByActionId(this.idAction)
    .subscribe((rest: sapProcesses[]) => {
      this.selectedProcess = rest;
    });
  }

  // ********************************

  getListResonseFromService() {

    this.clear();

    if (this.responsesFromService != null && this.responsesFromService != undefined) {
      const elements = this.responsesFromService.split(";");
      for (let index = 0; index < elements.length; index++) {
        const response = elements[index];
        this.getElementListForm();
        let form = this.formBuilder.group({
          response: response,
        });
        this.arrayFormGroup.push(form);
      }

    }
    // const element = ["response1", "response2"]
  }

  getElementListForm() {
    this.basicForm = this.formBuilder.group({
      response: [""],
    });
  }

  getArrayElementListForm() {
    this.getElementListForm();
    this.arrayFormGroup = this.formBuilder.array([
      this.basicForm,
    ]);
  }

  getFatherFormGroup() {
    this.fatherFormGroup = this.formBuilder.group({
      arrayFormGroup: this.getArrayElementListForm(),
    });
  }
  newElementListForm() {
    return this.formBuilder.group({
      response: [""],


    });
  }

  addItemToList() {
    this.arrayFormGroup.controls.unshift(this.newElementListForm());
    setTimeout(function(){    document.getElementById("Input-0").focus(); }, 300);

  }

  clear() {
    this.arrayFormGroup.clear();
  }

  removeItemToList(index: number) {
    this.arrayFormGroup.removeAt(index);
  }

  // ********************************************
  GetActionById() {
    this.actionService.GetActionById(this.idAction).subscribe(x => {
      this.actionServiceModel = x;
      this.nameToEdit = x.actionTitle;
      this.title = "botAdmin.actionsabm.subtitle2";
      this.statusAction = x.isActived;
      this.responsesFromService = x.actionResponse;
      this.getListResonseFromService();

      this.actionsCreateFormGroup.patchValue({
        title: x.actionTitle
      });
      this.actionsUpdateFormGroup.patchValue({
        details: x.actionContent,
        actionsType: x.actionType == null ? "" : x.actionType,
        actionsResult: x.actionResult
      });
    });
  }

  getFormCreate() {
    this.actionsCreateFormGroup = this.formBuilder.group({
      title: ['', Validators.required]
    });


  }

  get f() { return this.actionsCreateFormGroup.controls; }
  get fr() { return this.actionsUpdateFormGroup.controls; }

  mapToAction(form: UntypedFormGroup): Action {
    var action: Action = {} as Action;
    action.actionTitle = form.get('title').value;
    return action;
  }

  getFormUpdate() {
    this.actionsUpdateFormGroup = this.formBuilder.group({
      actionsType: ['', Validators.required],
      actionsResult: ['', Validators.required],
      details: ['', Validators.required],
    });
  }

  updateValidation() {
    this.habResultType = true;
    if (this.actionsUpdateFormGroup.get("actionsType").value == "LINK" || this.actionsUpdateFormGroup.get("actionsType").value == "VIDEO") {
      const reg = '(http:\/\/|https:\/\/)([-%_#@?&+=+0-9A-Za-z/.]+)';

      this.actionsUpdateFormGroup.get('actionsResult').setValidators([Validators.required, Validators.pattern(reg)]);
      this.actionsUpdateFormGroup.get('actionsResult').updateValueAndValidity();

    } else {
      this.actionsUpdateFormGroup.get('actionsResult').setValidators([Validators.required]);
      this.actionsUpdateFormGroup.get('actionsResult').updateValueAndValidity();
    }
  }

  clearValidation() {
    this.actionsUpdateFormGroup.get('actionsResult').clearValidators();
    this.actionsUpdateFormGroup.get('actionsResult').updateValueAndValidity();
  }

  switchForm() {
    var value = this.actionsUpdateFormGroup.get('actionsType').value;

    switch (value) {
      case "ANSWER":
        this.clearValidation();
        this.habResultType = false;
        this.isAnswer = true ;
        this.actionsUpdateFormGroup.get('actionsResult').reset();
        break;
      case "LINK":
        this.clear();
        this.updateValidation();
        this.isAnswer = false ;
        break;
      case "IMAGE":
        this.clear();
        this.updateValidation();
        this.isAnswer = false ;
        break;
      case "VIDEO":
        this.clear();
        this.updateValidation();
        this.isAnswer = false ;
        break;
      default:
        this.clear();
        this.habResultType = false;
        this.isAnswer = false ;
        this.clearValidation();
        break;
    }
  }

  create() {
    this.spinner.show();
    let action = this.mapToAction(this.actionsCreateFormGroup);
    this.submitted = true;

    if (this.actionsCreateFormGroup.invalid) {
      this.spinner.hide();
      return;
    }
    if (this.idAction === undefined) {
      action.actionContent = "";
      this.saveAction(action);
      /// this.actionServiceModel = action;
    } else {
      this.actionServiceModel.actionTitle = this.actionsCreateFormGroup.get("title").value;
      this.updateAction(this.actionServiceModel);
     /// this.title = "Action: " + this.actionServiceModel.actionTitle;
    }
    this.submitted = false;
  }

  saveAction(action: Action) {
    this.actionService.saveAction(action).subscribe(x => {

      this.translate.stream('backendmessages.' + x.value.code).subscribe(res => {
        if (x.statusCode === 200) {
          this.title = "Action: " + action.actionTitle;
          this.nameCreate = "Update";
          this.toast.success(res);
          this.disTab = false;
          this.urlService.model.id = x.value.id;
          this.urlService.setValue(this.urlService.model);
          this.idAction = x.value.id;
          this.changeTabs(2);
        }
        else {
          this.toast.error(res);


        }
        this.spinner.hide();
      });


      this.actionServiceModel = x.value.model;
    }, error => {
      this.translate.stream('backendmessages.' + error.value.code).subscribe(res => {
        this.toast.error(res);
      });
      this.spinner.hide();
    })
  }

  update() {

    this.submitted = true;
    this.spinner.show();

    var actionResult = this.actionsUpdateFormGroup?.get("actionsResult")?.value;

    if (actionResult?.substr(actionResult.length - 4) !== ".mp4" && this.actionsUpdateFormGroup.get("actionsType").value == "VIDEO") {
      this.translate.stream('botAdmin.generic.videoRequired').subscribe(res => {
        this.toast.error(res);
      });
      this.spinner.hide();
      return;
    }
    if (this.actionsUpdateFormGroup.get('details').value == null || this.actionsUpdateFormGroup.get('details').value == "") {
      {
        this.translate.stream('botAdmin.generic.required').subscribe(res => {
          this.toast.error(res);
        });
      }
      this.spinner.hide();
      return;
    }

    if (this.actionsUpdateFormGroup.invalid) {
      this.spinner.hide();
      return;
    }
    var action = this.createModelAction();

    var resp = "";

    for (let index = 0; index < this.arrayFormGroup.length; index++) {
      const element = this.arrayFormGroup.controls[index].get("response").value;
      if (index == this.arrayFormGroup.length - 1) {
        resp += element;
      }
      else {
        resp += element + ";";

      }
    }

    action.actionResponse = resp;
    this.updateAction(action);
    this.enabledTabs();
    this.submitted = false;
  }

  changeStatus() {
    this.spinner.show();

    if (!this.actionServiceModel.isActived) {
      if (this.actionServiceModel?.actionContent && this.actionServiceModel?.actionType) {

        this.statusAction = true;
        this.actionServiceModel.isActived = this.statusAction;

        this.updateAction(this.actionServiceModel);
      }
      else {

        this.translate.stream('backendmessages.i475').subscribe(res => {
          this.toast.error(res);
        });
      }
    }
    else {
      this.statusAction = false;
      this.actionServiceModel.isActived = this.statusAction;
      this.updateAction(this.actionServiceModel);
    }



    this.spinner.hide();

  }

  updateAction(action: Action) {
    this.actionService.UpdateAction(action).subscribe(x => {
      this.translate.stream('backendmessages.' + x.code).subscribe(res => {
        this.toast.success(res);
        this.title = "Action: " + this.actionServiceModel.actionTitle;
        this.nameToEdit = "";
      });
      this.actionServiceModel = x.model;
      this.spinner.hide();
    }, error => {
      this.translate.stream('backendmessages.' + error.code).subscribe(res => {
        this.toast.error(res);

      });
      this.spinner.hide();
    });
  }



  createModelAction(): Action {
    let action = this.mapToAction(this.actionsCreateFormGroup);
    action.actionTitle = this.actionServiceModel.actionTitle;
    action.actionId = this.idAction;
    action.actionType = this.actionsUpdateFormGroup.get('actionsType').value;
    action.actionResult = this.actionsUpdateFormGroup.get('actionsResult').value;
    action.actionContent = this.actionsUpdateFormGroup.get('details').value;
    action.isActived = this.statusAction;
    return action;
  }

  updateDualActions() {
    this.spinner.show();
    ///alert(JSON.stringify(this.actionsAssociateSelected))
    var action = this.createModelAction();
   // this.actionsAssociateSelected.filter(x => x !== null)
    this.addActionsToAction(this.actionsAssociateSelected, action);
  }

  updateDualProcess() {
    this.spinner.show();

    var action = this.createModelAction();
    this.selectedProcess
    this.addProcessByActionId(this.selectedProcess, action);
  }

  addProcessByActionId(actionprocess: sapProcesses[], action: Action) {
    this.actionsAndProcess = {} as actionsAndProcess;

    this.actionsAndProcess.processList = actionprocess;
    this.actionsAndProcess.action = action;
    this.addProcess(this.actionsAndProcess);
  }

  updateIntentions() {
    this.spinner.show();
    var action = this.createModelAction();
    this.addIntentionsByActionId(this.selectedIntentions, action);
  }

  addIntentionsByActionId(actionIntentions: string[], action: Action) {
    this.intentionsAndAction = {} as IntentionsAndAction;
    this.intentionsAndAction.intentionsList = actionIntentions;
    this.intentionsAndAction.action = action;
    this.addIntentions(this.intentionsAndAction);
  }


  addProcess(ActionAndProcess: actionsAndProcess) {
    this.sapService.AddProcessToAction(ActionAndProcess)
      .subscribe((rest: any) => {
        this.translate.stream('backendmessages.i202' ).subscribe(res => {
          this.toast.success(res);
        });
        this.spinner.hide();
      }, error => {
        this.translate.stream('backendmessages.i403' ).subscribe(res => {
          this.toast.error(res);
        });
        this.spinner.hide();
      })
  }

  addIntentions(intentionsAndAction: IntentionsAndAction) {
    this.actionService.addIntentionsByActionId(intentionsAndAction)
      .subscribe((rest: any) => {
        this.translate.stream('backendmessages.' + rest.code).subscribe(res => {
          this.toast.success(res);
        });
        this.spinner.hide();
      }, error => {
        this.translate.stream('backendmessages.' + error.code).subscribe(res => {
          this.toast.error(res);
        });
        this.spinner.hide();
      })
  }

  addActionsToAction(actionActions: Action[], action: Action) {
    this.actionsAndAction = {} as ActionsAndAction;
    this.actionsAndAction.actionsList = actionActions;
    this.actionsAndAction.action = action;
    this.addActions(this.actionsAndAction);
  }

  addActions(actionsAndAction: ActionsAndAction) {
    this.actionService.addActionsToAction(actionsAndAction)
      .subscribe((rest: any) => {
        this.spinner.hide();
        this.translate.stream('backendmessages.' + rest.code).subscribe(res => {
          this.toast.success(res);
        });
      }, error => {
        this.spinner.hide();
        this.translate.stream('backendmessages.' + error.code).subscribe(res => {
          this.toast.error(res);
        });
        this.spinner.hide();
      })
  }

  changeTabs(tabN: number) {
    this.activeTab = tabN;
    }


  actionsDualList() {
    this.actionService.getAllActions()
      .subscribe((rest: Action[]) => {
        if (this.idAction != undefined) {
          this.actionsAssociate = rest.filter(x => x.actionId != this.idAction && x.isActived);
        } else {
          this.actionsAssociate = rest.filter(x => x.isActived);
        }
      });
  }

  intentionsDualList() {
    this.actionService.GetRecognizer()
      .subscribe((rest: any) => {
        var intentionsObj = rest.assets.intents.filter(x => x.category != 'None');
        this.intentions = [];
        intentionsObj.forEach(element => {
          this.intentions.push(element.category);
        });
      });
  }


  processDualList() {

    this.sapService.GetAllProcess()
      .subscribe((rest: sapProcesses[]) => {

          this.ProcessAssociate = rest.filter(x => x.status);

      });
  }

  redirect(e: any) {
    this.urlService.navigateToViewElement('/management/actions', null, null, null, null, null, e.button, null);
  }

  checkActions() {
    this.spinner.show();
    this.verifyAction();
  }

  verifyAction() {
    this.actionService.VerifyDeleteActionById(this.idAction).subscribe(x => {
      this.showed = true;
      this.translate.stream('backendmessages.' + x.code).subscribe(res => {
        this.deleteTrue = res + x.message;
      });
      this.spinner.hide();
    }, error => {
      this.showed = false;
      this.translate.stream('backendmessages.' + error.code).subscribe(res => {
        this.deleteFalse = res + error.message;
      });
      this.spinner.hide();
    })
  }

  deleteActions() {
    this.spinner.show();
    this.deleteActionById();
  }

  deleteActionById() {
    this.actionService.deleteActionById(this.idAction).subscribe(x => {
      this.translate.stream('backendmessages.' + x.code).subscribe(res => {
        this.toast.success(res);
      });
      this.spinner.hide();
      this.redirect(0);
    }, error => {
      this.spinner.hide();
      this.translate.stream('backendmessages.' + error.code).subscribe(res => {
        this.toast.error(res);
      });
    });
  }

}

