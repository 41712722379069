export class Action {
    public actionId: number;
    public actionType: string;
    public actionTitle: string;
    public actionContent: string;
    public actionResult: string;
    public isActived: boolean;
    public status: string;
    public shortContent: string;
    public actionTitleId: string;
    public modifiedDate: Date;
    public actionResponse: string;

   constructor() {

    } 
}