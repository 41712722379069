import { Component, HostBinding, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { SettingsService } from './core/settings/settings.service';
import { authConfig } from './auth/auth-config';
import { AppConfigService } from './services/AppConfigService';
import { TranslateService } from '@ngx-translate/core';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    @HostBinding('class.layout-fixed') get isFixed() { return this.settings.getLayoutSetting('isFixed'); };
    @HostBinding('class.aside-collapsed') get isCollapsed() { return this.settings.getLayoutSetting('isCollapsed'); };
    @HostBinding('class.layout-boxed') get isBoxed() { return this.settings.getLayoutSetting('isBoxed'); };
    @HostBinding('class.layout-fs') get useFullLayout() { return this.settings.getLayoutSetting('useFullLayout'); };
    @HostBinding('class.hidden-footer') get hiddenFooter() { return this.settings.getLayoutSetting('hiddenFooter'); };
    @HostBinding('class.layout-h') get horizontal() { return this.settings.getLayoutSetting('horizontal'); };
    @HostBinding('class.aside-float') get isFloat() { return this.settings.getLayoutSetting('isFloat'); };
    @HostBinding('class.offsidebar-open') get offsidebarOpen() { return this.settings.getLayoutSetting('offsidebarOpen'); };
    @HostBinding('class.aside-toggled') get asideToggled() { return this.settings.getLayoutSetting('asideToggled'); };
    @HostBinding('class.aside-collapsed-text') get isCollapsedText() { return this.settings.getLayoutSetting('isCollapsedText'); };

    constructor(public settings: SettingsService, private oauthService: OAuthService, 
        private environment: AppConfigService, public translate: TranslateService) { 
        this.configureAuth();
        translate.addLangs(['English', 'Español']);
        translate.setDefaultLang('English');
    }

    private configureAuth() {
            authConfig.issuer = this.environment.GetIdentityApi;
            authConfig.postLogoutRedirectUri = this.environment.GetBackOffice + "/home/";
            authConfig.redirectUri = this.environment.GetBackOffice + "/callback/";
            authConfig.clientId = this.environment.GetClientId;
            authConfig.scope = this.environment.GetScope;

            this.oauthService.configure(authConfig);
            this.oauthService.tokenValidationHandler = new JwksValidationHandler();
            this.oauthService.setupAutomaticSilentRefresh();
            this.oauthService.loadDiscoveryDocumentAndTryLogin();
            this.oauthService.events
            .pipe(filter(e => e.type === 'session_terminated'))
            .subscribe(e => {
              // tslint:disable-next-line:no-console
              console.debug('Your session has been terminated!');
            });
    }

    ngOnInit() {
        document.addEventListener('click', e => {
            const target = e.target as HTMLElement;
            if (target.tagName === 'A') e.preventDefault();
        });
    }
}
