<spinner></spinner>
<div *ngIf="notAllowed">
  <app-error404></app-error404>
</div>
<div *ngIf="!notAllowed">
  <div class="content-heading">
    <div>
      <h3> {{'exceptedWord.title' | translate}} </h3>
      <h6 id="subtitle"> {{title | translate}} {{nametoedit}} </h6>
    </div>
    <div class="ml-auto">
      <a class="btn btn-info col-sm-12 col-md-12 col-lg-12" (mouseup)="redirect($event)"> {{'botAdmin.generic.back' |
        translate}} </a>
    </div>
  </div>
  <!-- Nav Tabs-->
  <ul class="nav nav-tabs" id="entityTab" role="tablist">
    <li class="nav-item">
      <a [ngClass]="{'nav-link':true, 'active': (activeTab == 1)}" id="basicinfo-tab" data-toggle="tab"
        href="#basicinfo" role="tab" aria-controls="basicinfo" aria-selected="true" (click)="changeTabs(1)">
        {{'botAdmin.conversationabm.tab1' | translate}} </a>
    </li>
  </ul>
  <!-- Tabs div -->
  <div class="tab-content" id="myTabContent">

    <!-- Basic Info Tab -->
    <div class="tab-pane fade show active" id="basicinfo" role="tabpanel" aria-labelledby="basicinfo-tab"
      *ngIf="activeTab == 1">
      <form [formGroup]="exceptedWordCreateFormGroup">
        <div class="row">
         
          <div class="col-11">
            <div class="row">
              <div id="colInputWord" class="form-group col-md-4">
                <label> {{'exceptedWordABM.searchText' | translate}} </label>

                <input #myDiv [hidden]="true" type="file" id="idArchive" (change)="LoadFileTxt($event.target.files)"
                  class="form-control">

                <input *ngIf="visibilityInput" type="text" formControlName="description" maxlength="60"
                  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
                  [class.disabled]='readonly'>

                <div *ngIf="visibilityArchive" class="row">
                  <div class="col">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <a class="btn btn-labeled btn-info mb-2" [routerLink]="" (click)="ActivarInputFile()">
                          <span class="btn-label"><i class="fas fa-upload"></i>
                          </span>{{'exceptedWordABM.archive' | translate}}
                        </a>
                      </div>
                      <input type="text" [disabled]="statusInput" class="form-control" disabled="disabled"
                        formControlName="fileName">
                    </div>
                  </div>
                </div>

                <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                  <div *ngIf="f.description.errors.required"> {{'botAdmin.generic.required' | translate}} </div>
                </div>
              </div>
            </div>

            <div *ngIf="urlService.model.action !== 'create'" class="row">
              <div class="form-group col-md-4">
                <label> {{'exceptedWordABM.service' | translate}} </label>
                <select formControlName="typeService" class="custom-select" class="form-control">
                  <option value="SEARCH"> SEARCH </option>
                  <option value="SPELLCHECK"> SPELLCHECK </option>
                </select>
                <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                  <div *ngIf="f.type.errors.required"> {{'botAdmin.generic.required' | translate}} </div>
                </div>
              </div>
            </div>

            <div *ngIf="urlService.model.action == 'create'" class="row">
              <div class="form-group col-md-4">
                <label> {{'exceptedWordABM.service' | translate}} </label>
                <select formControlName="typeService" class="custom-select" class="form-control">
                  <option value="all" selected> {{'exceptedWordABM.serviceType.all' | translate}} </option>
                  <option value="SEARCH"> SEARCH </option>
                  <option value="SPELLCHECK"> SPELLCHECK </option>
                </select>
                <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                  <div *ngIf="f.type.errors.required"> {{'botAdmin.generic.required' | translate}} </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-1">
                <button type="submit" class="btn btn-info" (click)="create()" [class.disabled]="readonly">{{nameCreate |
                  translate}}</button>
              </div>
            </div>

            <div class="container">
              <ng-template #abmWordModal let-c="close" let-d="dismiss">
                <div class="modal-header">
                  <h4 class="modal-title">{{'exceptedWordABM.modalAbm.title' | translate}}</h4>
                  <button type="button" class="close" aria-label="Close" (click)="d('')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="modal-body">
                  <p>{{msgAbmWordModal}}</p>
                  <div class="row">
                    <div class="col">
                      <img class="img-fluid" src="{{urlImageAbmWordModal}}">

                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>

          <div class="col-1">
            <div *ngIf="visibleAbmWordModal" class="float-right"> <a class="btn btn-info"
                (click)="openModal(abmWordModal)"><i class="fas fa-info-circle"></i></a>
            </div>
          </div>

        </div>

      </form>
    </div>

  </div>
</div>