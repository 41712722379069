<spinner></spinner>
<div *ngIf="notAllowed">
  <app-error404></app-error404>
</div>
<div *ngIf="!notAllowed">
  <div class="content-heading">
      <div>
          <h3> {{'confguration.user.user' | translate}} </h3>
          <h6 id="subtitle" > {{'confguration.user.allusers' | translate}} </h6>
      </div>
      <div class="ml-auto">
        <a class="mr-1 btn btn-labeled btn-info mb-2 col-sm-12 col-md-12 col-lg-12" 
        (mouseup)="redirect('', $event)">
            <span class="btn-label"><i class="fa fa-plus"></i>
            </span> {{'botAdmin.generic.new' | translate}} </a>
      </div>
  </div>
  <div class="row">
      <div class="col-md-auto">
          <div class="input-group mb-3">
              <div class="input-group-prepend">
              <span class="input-group-text" name="labelSize" > {{'confguration.user.namefilter' | translate}} </span>
              </div>
              <input type="text" (keyup.enter)="GetUsers()" class="form-control" [(ngModel)]="nameValue" >
          </div>
      </div>
      <div class="col-md-auto">
        <div class="input-group mb-3">
            <div class="input-group-prepend">
            <span class="input-group-text" name="labelSize" > {{'confguration.user.mailfilter' | translate}} </span>
            </div>
            <input type="text" (keyup.enter)="GetUsers()" class="form-control" [(ngModel)]="emailValue" >
        </div>
      </div>
      <div class="col-md-auto">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01" name="labelSize" > {{'botAdmin.generic.result' | translate}} </label>
          </div>
          <select class="custom-select"  [(ngModel)]="pageSize" (click)="changePageSize()" id="inputGroupSelect01">
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>
        </div>
      </div>
      <div class="col-md-auto">
        <div class="input-group mb-3">
        <button class="mr-1 btn btn-labeled btn-info mb-2 col-sm-12 col-md-12 col-lg-12" type="button" 
          id="search2" (click)="GetUsers()">
            <i class="fa fa-search" id="search"></i>
            </button>
        </div>
      </div>
  </div>
  <hr id="hr1">
  <div id="tableContainer">
      <div class="row">
          <div class="table-responsive">
            <div class="card-body">

              <div>

                <mat-table #table [dataSource]="dataSource">

                  <ng-container matColumnDef="userName">
                    <mat-header-cell *matHeaderCellDef> {{'confguration.user.namefilter' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.userName}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef> {{'confguration.user.mailfilter' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="isWelcome"> {{row.email}} </mat-cell>
                  </ng-container>
        
                  <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef> {{'confguration.user.actionsfilter' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="actions">
                      <a class="btn btn-info"  (mouseup)="redirect(row.id, $event)"> {{'botAdmin.generic.enter' | translate}} </a> </mat-cell>
                  </ng-container>
        
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"> </mat-row>

                </mat-table>
                <div *ngIf="noData | async" class="no-records">
                  {{'botAdmin.generic.notfound' | translate}} 
                </div>
                <mat-paginator [length]="usersLenght" [pageSize]="pageSize" [hidePageSize]="true">  </mat-paginator>

              </div>


            </div>
          </div>
      </div>
  </div>
</div>