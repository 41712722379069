<div *ngIf="notAllowed">
  <app-error404></app-error404>
</div>
<div *ngIf="!notAllowed">
<div class="content-heading mb-1" style=" padding: 45px 0 0 0; flex-direction: row-reverse;">
  <button class="mr-1 btn btn-info mb-2" style="position: fixed; z-index: 2;" type="button"
  (click)="reloadChat()">
    {{'chat.restart' | translate}}
  </button>
  
</div>

<div class="container ml-0">
  <div>
  </div>
  <spinner></spinner>
  <div id="bot" class="webchat-container" #botWindow></div>
</div>
</div>