
import { Component, EventEmitter, IterableDiffers, Input, Output } from '@angular/core';
import { DualListComponent } from 'angular-dual-listbox';
import { Console } from 'console';

@Component({
	selector: 'app-custom-listbox',
	templateUrl: './custom-dual.component.html',
	styleUrls: ['./custom-dual.component.scss']
})
export class CustomDualComponent extends DualListComponent {

	@Input() sourceName = '';
	@Input() targetName = '';

	@Output() selectChange = new EventEmitter();

	constructor(differs: IterableDiffers) {
		super(differs);
	}

	moveAll() {
		this.selectAll(this.available);
		this.moveItem(this.available, this.confirmed);
	}

	removeAll() {
		this.selectAll(this.confirmed);
		this.moveItem(this.confirmed, this.available);
	}

	moveUp(confirmed) {

		var item = []
		//Busca los indices de los que se pickearon, de donde estan y hacia donde van (en este caso el -1 es para que suba)
		for (let index = 0; index < confirmed.pick.length; index++) {

			var fromindex = confirmed.list.findIndex(x => x === confirmed.pick[index]);
			var toIndex = fromindex - 1
			if (fromindex != 0) {
				item.push({ 'from': fromindex, 'to': toIndex })

			}
			else { break; }
		}
		//Ordena de menor a mayor los from para que no se superpongan a la hora de empezar a subirlos
		item = item.sort((a, b) => {
			return a.from - b.from;

		})
		//Mueve la lista que se ve y la posicion de la lista que se le pasa al back
		for (let index = 0; index < item.length; index++) {

			this.arraymove(this.destination, item[index].from, item[index].to)
			this.arraymove(confirmed.list, item[index].from, item[index].to)
		}

	}

	moveDown(confirmed) {

		var item = []
		//Busca los indices de los que se pickearon, de donde estan y hacia donde van (en este caso el +1 es para que baje)
		for (let index = 0; index < confirmed.pick.length; index++) {

			var fromindex = confirmed.list.findIndex(x => x === confirmed.pick[index]);
			var toIndex = fromindex + 1;

			if (fromindex != confirmed.list.length) {
				item.push({ 'from': fromindex, 'to': toIndex })
			}
			else { break; }
		}
		//Ordena de mayor a menor los from para que no se superpongan a la hora de empezar a bajarlos
		item = item.sort((a, b) => {
			return b.from - a.from;
		})

		//Mueve la lista que se ve y la posicion de la lista que se le pasa al back
		for (let index = 0; index < item.length; index++) {

			this.arraymove(this.destination, item[index].from, item[index].to)
			this.arraymove(confirmed.list, item[index].from, item[index].to)
		}

	}

	areContinuous(confirmed) {

		var listIndex = [];
		for (let i = 0; i < confirmed.pick.length; i++) {
			listIndex.push(confirmed.list.findIndex(x => x === confirmed.pick[i]));
		}
		listIndex = listIndex.sort((a, b) => {
			return a - b;
		});
		var lastIndex
		for (let i = 0; i < listIndex.length; i++) {
			var actualIndex = listIndex[i]
			if (actualIndex !== lastIndex + 1 && i != 0) {
				return true;
			}
			lastIndex = actualIndex;
		}
		return false
	}

	arraymove(arr, fromIndex, toIndex) {
		var element = arr[fromIndex];
		arr.splice(fromIndex, 1);
		arr.splice(toIndex, 0, element);
	}

	// Override function in DualListComponent to add custom selectChange event.
	selectItem(list: Array<any>, item: any) {
		const pk = list.filter((e: any) => {
			return Object.is(e, item);
		});
		if (pk.length > 0) {
			// Already in list, so deselect.
			for (let i = 0, len = pk.length; i < len; i += 1) {
				const idx = list.indexOf(pk[i]);
				if (idx !== -1) {
					list.splice(idx, 1);
					this.selectChange.emit({ key: item._id, selected: false });
				}
			}
		} else {
			list.push(item);
			this.selectChange.emit({ key: item._id, selected: true });
		}
	}

}