import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../core/settings/settings.service';
import { AppConfigService } from 'src/app/services/AppConfigService';
import  pkgVersion from '../../../../package.json';

@Component({
    selector: '[app-footer]',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    version: string = "";
    constructor() { }

    ngOnInit() {
      this.version = pkgVersion.version;
    }

}
