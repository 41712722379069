import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { BotService } from '../../../services/botService';
import { ActionDataSource } from '../../../dataSources/actionDatasource';
import { Observable } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { startWith, delay, tap, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from 'src/app/sharedService';
import { ThemesService } from 'src/app/core/themes/themes.service';
import { PaginationFilterActions } from 'src/app/entities/paginationFilterActions';
import { property } from 'lodash';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActionsComponent implements OnInit {
  //Table variables
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: ActionDataSource;
  displayedColumns: string[] = ['actionTitle', 'actionType', 'isActived', 'actions'];
  noData: Observable<boolean>;
  collectionviewer: CollectionViewer;

  // Paginator variables
  titleValue: string;
  statusValue: string = "all";
  typeValue: string = "all";
  pageSize: number = 15;
  actionsLenght: number = 0;
  totalSize: number;
  sizeOptions = [15, 25, 50];
  notAllowed: boolean = false;
  readonly: boolean = false;

  constructor(private themeService: ThemesService, private urlService: SharedService, private router: Router, private actionsService: BotService, private spinner: NgxSpinnerService) {
    this.themeService.createStyle();
    this.themeService.setTheme(localStorage.getItem('theme'));
    var userRoles = JSON.parse(localStorage.getItem('roles'));
    if (userRoles.indexOf('OpacAdmin') == -1 && userRoles.indexOf('GlobalAdmin') == -1 && userRoles.indexOf('ReadOnlyUser') == -1)
      this.notAllowed = true;

    if (userRoles.indexOf('ReadOnlyUser') == 0) {
      this.readonly = true;

    }
    this.spinner.hide();
  }

  ngOnInit() {
    this.GetActions();
  
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        startWith(null),
        delay(0),
        tap(() => this.loadPage())
      ).subscribe();

  }

  redirect(id: any, e: any) {
    this.urlService.navigateToViewElement('/management/actions/crud', null, id, null, null, null, e.button, null);
  }

  GetActions() {
    var paginationFilter = new PaginationFilterActions();
    paginationFilter.title = this.titleValue;
    paginationFilter.pageIndex = 0;
    paginationFilter.pageSize = this.pageSize;
    paginationFilter.status = this.statusValue;
    paginationFilter.typeActions = this.typeValue;
    this.dataSource = new ActionDataSource(this.actionsService);
    this.dataSource.loadActions(paginationFilter);
    this.noData = this.dataSource.connect(this.collectionviewer).pipe(map(data => data.length === 0));
    this.actionsService.getAllPaginationActionsCount(paginationFilter).subscribe(rest => {
      this.actionsLenght = rest;
    });
  }

  loadPage() {
    var paginationFilter = new PaginationFilterActions();
    paginationFilter.title = this.titleValue;
    paginationFilter.pageIndex = this.paginator.pageIndex;
    paginationFilter.pageSize = this.pageSize;
    paginationFilter.status = this.statusValue;
    paginationFilter.typeActions = this.typeValue;
    this.dataSource.loadActions(paginationFilter);
  }

  changePageSize() {
    this.paginator.pageSize = this.pageSize;
    this.paginator.pageIndex = 0;
    this.loadPage();
  }


}