<spinner></spinner>
<div *ngIf="notAllowed">
  <app-error404></app-error404>
</div>
<div *ngIf="!notAllowed">
  <div class="content-heading">
    <div>
      <h3> {{'botAdmin.option.title' | translate}} </h3>
      <h6 id="subtitle"> {{title | translate}} {{nametoedit}} </h6>
    </div>
    <div class="ml-auto">
      <a class="btn btn-info col-sm-12 col-md-12 col-lg-12" (mouseup)="redirect($event)"> {{'botAdmin.generic.back' |
        translate}} </a>
    </div>
  </div>
  <!-- Nav Tabs-->
  <ul class="nav nav-tabs" id="entityTab" role="tablist">
    <li class="nav-item" *ngIf="!isParentBasic">
      <a [ngClass]="{'nav-link':true, 'active': (activeTab == 1)}" id="basicinfo-tab" data-toggle="tab"
        href="#basicinfo" role="tab" aria-controls="basicinfo" aria-selected="true" (click)="changeTabs(1)">
        {{'botAdmin.optionabm.tab1' | translate}} </a>
    </li>
    <li class="nav-item" *ngIf="!isParentConfig">
      <a [ngClass]="{'nav-link':true, 'active': (activeTab == 2), 'disabled': (disTab)}" id="settings-tab"
        data-toggle="tab" href="#settings" role="tab" aria-controls="settings" aria-selected="false"
        (click)="changeTabs(2)"> {{'botAdmin.optionabm.tab2' | translate}} </a>
    </li>
    <li class="nav-item" *ngIf="!isParentConfig">
      <a [ngClass]="{'nav-link':true, 'active': (activeTab == 3), 'disabled': (disTab)}" id="settings-tab"
        data-toggle="tab" href="#process" role="tab" aria-controls="settings" aria-selected="false"
        (click)="changeTabs(3)"> {{'botAdmin.processABM.title' | translate}} </a>
    </li>
    <li class="nav-item" *ngIf="!isParentAdvanced">
      <a [ngClass]="{'nav-link':true, 'active': (activeTab == 4), 'disabled': (disTab)}" id="advanced-tab"
        data-toggle="tab" href="#advanced" role="tab" aria-controls="advanced" aria-selected="false"
        (click)="changeTabs(4)"> {{'botAdmin.optionabm.tab4' | translate}} </a>
    </li>
  </ul>
  <!-- Tabs div -->
  <div class="tab-content" id="myTabContent">
    <!-- Basic Info Tab -->
    <div class="tab-pane fade show active" id="basicinfo" role="tabpanel" aria-labelledby="basicinfo-tab"
      *ngIf="activeTab == 1">
      <form [formGroup]="optionCreateFormGroup">
        <div class="row">
          <div class="form-group col-md-4">
            <label for="exampleFormControlInput1"> {{'botAdmin.optionabm.form1' | translate}} </label>
            <input type="text" class="form-control" maxlength="100" formControlName="name"
              [ngClass]="{ 'is-invalid': submitted && f.name.errors }" [class.disabled]="readonly">
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors.required"> {{'botAdmin.generic.required' | translate}} </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4">
            <label for="exampleFormControlTextarea1"> {{'botAdmin.optionabm.form2' | translate}} </label>
            <textarea class="form-control" rows="5" formControlName="details"
              [ngClass]="{ 'is-invalid': submitted && f.details.errors }" [class.disabled]="readonly"></textarea>
            <div *ngIf="submitted && f.details.errors" class="invalid-feedback">
              <div *ngIf="f.details.errors.required"> {{'botAdmin.generic.required' | translate}} </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-1">
            <button type="submit" class="btn btn-info" (click)="create()" [class.disabled]="readonly">{{nameCreate |
              translate}}</button>
          </div>
        </div>
      </form>
    </div>
    <!-- Config Tab -->
    <div class="tab-pane fade show active" id="settings" role="tabpanel" aria-labelledby="settings-tab"
      *ngIf="activeTab == 2">
      <div class="row">
        <div class="col-md-8">
          <div class="header-modal">{{'botAdmin.optionabm.assoact' | translate}}</div>
          <div class="p-2">
            <app-custom-listbox [filter]="true" [source]="actionsAssociate" key="actionId" display="actionTitle"
              [(destination)]="actionsAssociateSelected" [format]="format" height="300px" [class.disabled]="readonly">
            </app-custom-listbox>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-1">
          <button type="submit" class="btn btn-info" (click)="update()"
            [class.disabled]="readonly">{{'botAdmin.generic.update' | translate}}</button>
        </div>
      </div>
    </div>
    <!-- Process tab-->
    <div class="tab-pane fade show active" id="settings" role="tabpanel" aria-labelledby="settings-tab"
      *ngIf="activeTab == 3">
      <div class="row">
        <div class="col-md-8">
          <div class="header-modal">{{'botAdmin.processABM.title' | translate}}</div>
          <div class="p-2">
            <app-custom-listbox [filter]="true" [source]="processAssociate" key="sapProcessid" display="name"
              [(destination)]="processSelected" [format]="format" height="300px" [class.disabled]="readonly">
            </app-custom-listbox>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-1">
          <button type="submit" class="btn btn-info" (click)="updateProcess()"
            [class.disabled]="readonly">{{'botAdmin.generic.update' | translate}}</button>
        </div>
      </div>
    </div>
    <!-- Advanced Tab -->
    <div class="tab-pane fade show active" id="advanced" role="tabpanel" aria-labelledby="advanced-tab"
      *ngIf="activeTab == 4">
      <div class="row justify-content-md-start">
        <div class="form-group col-md-6">
          <div class="card">
            <h4 class="card-header">{{'botAdmin.generic.danger' | translate}}</h4>
            <hr id="hr2">
            <div class="card-body">
              <h4>{{'botAdmin.optionabm.deleteop' | translate}}</h4>
              <p class="card-text">{{'botAdmin.optionabm.deletep' | translate}}</p>
              <button type="submit" class="btn btn-info" (click)="checkOption()"
                [class.disabled]="readonly">{{'botAdmin.optionabm.deleteop' | translate}}</button>
              <p class="card-text" id="delete1" *ngIf="!showed">{{deleteFalse}}</p>
              <p class="card-text" id="delete2" *ngIf="showed">{{deleteTrue}}</p>
              <button type="submit" *ngIf="showed" class="btn btn-info" (click)="deleteOption()"
                [class.disabled]="readonly">{{'botAdmin.generic.confirmdelete' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
