<spinner></spinner>
<div *ngIf="notAllowed">
  <app-error404></app-error404>
</div>
<div *ngIf="!notAllowed">
  <div class="content-heading">
    <div>
      <h3> {{'botAdmin.conversation.title' | translate}} </h3>
      <h6 id="subtitle"> {{title | translate}} {{nametoedit}} </h6>
    </div>
    <div class="ml-auto">
      <a class="btn btn-info col-sm-12 col-md-12 col-lg-12"  (mouseup)="redirect($event)"> {{'botAdmin.generic.back' | translate}} </a>
    </div>
  </div>
  <!-- Nav Tabs-->
  <ul class="nav nav-tabs" id="entityTab" role="tablist">
    <li class="nav-item">
      <a [ngClass]="{'nav-link':true, 'active': (activeTab == 1)}" id="basicinfo-tab" data-toggle="tab" href="#basicinfo"
        role="tab" aria-controls="basicinfo" aria-selected="true" (click)="changeTabs(1)"> {{'botAdmin.conversationabm.tab1' | translate}} </a>
    </li>
    <li class="nav-item">
      <a [ngClass]="{'nav-link':true, 'active': (activeTab == 2), 'disabled': (disTab)}" id="config-tab"
        data-toggle="tab" href="#config" role="tab" aria-controls="config" aria-selected="false"
        (click)="changeTabs(2)"> {{'botAdmin.conversationabm.tab2' | translate}} </a>
    </li>
    <li class="nav-item">
      <a [ngClass]="{'nav-link':true, 'active': (activeTab == 3), 'disabled': (disTab)}" id="advanced-tab"
        data-toggle="tab" href="#advanced" role="tab" aria-controls="advanced" aria-selected="false"
        (click)="changeTabs(3)"> {{'botAdmin.conversationabm.tab3' | translate}} </a>
    </li>
  </ul>
  <!-- Tabs div -->
  <div class="tab-content" id="myTabContent">
    <!-- Basic Info Tab -->
    <div class="tab-pane fade show active" id="basicinfo" role="tabpanel" aria-labelledby="basicinfo-tab"
      *ngIf="activeTab == 1">
      <form [formGroup]="conversationCreateFormGroup">
        <div class="row">
          <div class="form-group col-md-4">
            <label for="exampleFormControlTextarea1"> {{'botAdmin.conversationabm.form1' | translate}} </label>
            <textarea class="form-control" formControlName="answer"
              [ngClass]="{ 'is-invalid': submitted && f.answer.errors }" maxlength="255" rows="5" [class.disabled]= "readonly"></textarea>
            <div *ngIf="submitted && f.answer.errors" class="invalid-feedback">
              <div *ngIf="f.answer.errors.required"> {{'botAdmin.generic.required' | translate}} </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-1">
            <button type="submit" class="btn btn-info" (click)="create()" [class.disabled]= "readonly">{{nameCreate | translate}}</button>
          </div>
        </div>
      </form>
    </div>
    <!-- Config Tab -->
    <div class="tab-pane fade show active" id="config" role="tabpanel" aria-labelledby="config-tab"
      *ngIf="activeTab == 2">
      <div class="row">
        <label class="col-md-4"> {{'botAdmin.conversationabm.form2' | translate}} {{statusWelcome}}</label>
      </div>
      <div class="row">
        <div class="form-group col-md-4">
          <button type="submit" class="btn btn-success" [disabled]="statusWelcome" (click)="changeWelcome()"
            id="enable" [class.disabled]= "readonly"> {{'botAdmin.generic.enable' | translate}} </button>
          <button type="submit" class="btn btn-danger" [disabled]="!statusWelcome" id="disable"
            (click)="changeWelcome()" [class.disabled]= "readonly"> {{'botAdmin.generic.disable' | translate}} </button>
        </div>
      </div>
      <form [formGroup]="conversationUpdateFormGroup">

      <div *ngIf="!statusWelcome" class="row">
        <label class="col-md-4"> {{'botAdmin.conversation.intentions' | translate}} </label>
      </div>
      <div *ngIf="!statusWelcome" class="row">
        <div class="form-group col-md-4">

            <ng-select [items]="intentions" [ngClass]="{ 'is-invalid': submitted && fe.intention.errors }"
              formControlName="intention" class="custom" loadingText="cargando..." [clearable]="false"
              [loading]="loadingCombo" notFoundText="No records found." bindLabel="category" bindValue="category"
              style="width:50%" [class.disabled]= "readonly">
            </ng-select>
            <div *ngIf="submitted && fe.intention.errors" class="invalid-feedback">
              <div *ngIf="fe.intention.errors.required"> {{'botAdmin.generic.required' | translate}} </div>
            </div>

        </div>
      </div>
    </form>

      <div class="row">
        <div class="col-md-1">
          <button type="submit" class="btn btn-info" (click)="update()" [class.disabled]= "readonly"> {{'botAdmin.generic.update' | translate}} </button>
        </div>
      </div>
    </div>
    <!-- Advanced Tab -->
    <div class="tab-pane fade show active" id="advanced" role="tabpanel" aria-labelledby="advanced-tab"
      *ngIf="activeTab == 3">
      <div class="row justify-content-md-start">
        <div class="form-group col-md-6">
          <div class="card">
            <h4 class="card-header"> {{'botAdmin.generic.danger' | translate}} </h4>
            <hr id="hr2">
            <div class="card-body">
              <h4> {{'botAdmin.conversationabm.deleteconv' | translate}} </h4>
              <p class="card-text"> {{'botAdmin.conversationabm.deletep' | translate}} </p>
              <button type="submit" class="btn btn-info" (click)="checkConversation()" [class.disabled]= "readonly"> {{'botAdmin.conversationabm.deleteconv' | translate}} </button>
              <p class="card-text" id="delete1" *ngIf="!showed">{{deleteFalse}}</p>
              <p class="card-text" id="delete2" *ngIf="showed">{{deleteTrue}}</p>
              <button type="submit" *ngIf="showed" class="btn btn-info" (click)="deleteConversation()" [class.disabled]= "readonly"> {{'botAdmin.generic.confirmdelete' | translate}} </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
